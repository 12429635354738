import React, { useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";

// Components
import HeaderLangingPage from "../components/HeaderLangingPage";
import FooterLandingPage from "../components/FooterLandingPage";

import iDeal from "../images/iDeal.png";
// // Stripe
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import HomePage from "./HomePage";

// Extras
import { API_SERVICE } from "../config/URI";
import { firestore } from "../Firebase/index";
import { Box } from "@material-ui/core";

const stripePromise = loadStripe(
  "pk_test_51IdwfeH8KzFo5uc9gvu6EUUnzatrPyNeh6fVsTmr1eyW7RELgRGiDJid8LQmS9f2c47FE58dKBPoa6VlDCLkogxd00RKRpOPvb"
);

const ProductList = ({
  p,
  settotalprice,
  changeQty,
  product,
  deleteProduct,
}) => {
  settotalprice(p.toFixed(2));
  return <></>;
};

const Checkout = () => {
  const [products, setproducts] = React.useState([]);
  const [userId, setuserId] = React.useState("");
  const [note, setnote] = React.useState("");
  const [loading, setloading] = React.useState(true);
  const [totalprice, settotalprice] = React.useState(0);

  React.useEffect(() => {
    if (localStorage.getItem("userId") === null) {
      localStorage.setItem("userId", uuidv4() + Date.now());
    } else {
      var userId = localStorage.getItem("userId");
      setuserId(userId);
    }
    axios
      .get(`${API_SERVICE}/api/v1/main/getcartallitems/${userId}`)
      .then((response) => {
        setproducts(response.data);
        setloading(false);
      })
      .catch((err) => console.log(err));
  }, []);

  const refreshList = () => {
    axios
      .get(`${API_SERVICE}/api/v1/main/getcartallitems/${userId}`)
      .then((response) => {
        setproducts(response.data);
        setloading(false);
      })
      .catch((err) => console.log(err));
  };

  const deleteProduct = (id) => {
    var docRef = firestore.collection("cart").doc(userId);
    docRef
      .get()
      .then(function (doc) {
        var items = doc.data().items;
        axios
          .get(`${API_SERVICE}/api/v1/main/deleteitemsmaincart/${id}`)
          .then((response) => {
            setloading(true);
            settotalprice(0);
            refreshList();
            items = items - 1;
            docRef.set(
              {
                items,
              },
              { merge: true }
            );
          })
          .catch((err) => console.log(err));
      })
      .catch(function (error) {
        console.log("Error getting document:", error);
      });
  };

  const changeQty = (documentId, qty) => {
    axios
      .get(
        `${API_SERVICE}/api/v1/main/cartitemchangequantitycart/${documentId}/${qty}`
      )
      .then((response) => {
        if (response.status === 200) {
          refreshList();
          setloading(true);
        }
      })
      .catch((err) => console.log(err));
  };

  const showProductList = () => {
    var p = 0;
    return products.map((product) => {
      var subTotal = Number(product.qty) * Number(product.price);
      p = Number(p) + Number(subTotal);
      return (
        <ProductList
          settotalprice={settotalprice}
          p={p}
          changeQty={changeQty}
          deleteProduct={deleteProduct}
          product={product}
          key={product.Sku}
        />
      );
    });
  };

  const clearShoppingCartItem = () => {
    var docRef = firestore.collection("cart").doc(userId);
    docRef
      .get()
      .then(function (doc) {
        var items = doc.data().items;
        axios
          .get(`${API_SERVICE}/api/v1/main/clearallcartitems/${userId}`)
          .then((response) => {
            setloading(true);
            settotalprice(0);
            refreshList();
            items = 0;
            docRef.set(
              {
                items,
              },
              { merge: true }
            );
          })
          .catch((err) => console.log(err));
      })
      .catch(function (error) {
        console.log("Error getting document:", error);
      });
  };

  return (
    <>
      <HeaderLangingPage />
      <br />

      <div id="tt-pageContent">
        <div class="container-indent">
          <div class="container">
            <h1 class="tt-title-subpages noborder">CHECKOUT</h1>
            {products && products.length ? (
              <>
                <div class="tt-shopcart-table-02">
                  <div
                    class="tt-shopcart-btn"
                    style={{ marginTop: "0", marginBottom: "32px" }}
                  >
                    <div class="col-left">
                      <a class="btn-link" href="/products?p=bags&t=all">
                        <i class="icon-e-19"></i>CONTINUE SHOPPING
                      </a>
                    </div>
                    <div class="col-right">
                      <a
                        onClick={clearShoppingCartItem}
                        class="btn-link"
                        href="#"
                      >
                        <i class="icon-h-02"></i>CLEAR SHOPPING CART
                      </a>
                    </div>
                  </div>

                  <table>
                    <tbody>{loading ? <></> : <>{showProductList()}</>}</tbody>
                  </table>
                </div>

                <Box>
                  <Elements stripe={stripePromise}>
                    <HomePage
                      type={"vg"}
                      amount={0}
                      products={products}
                      totalprice={totalprice}
                    />
                  </Elements>
                </Box>
              </>
            ) : (
              <>
                <center>
                  <a class="btn-link" href="/products?p=bags&t=all">
                    <i class="icon-e-19"></i>CONTINUE SHOPPING
                  </a>
                  <br />
                  <img
                    className="responsive"
                    src="https://www.no-fea.com/front/images/empty-cart.png"
                    alt="Empty Cart"
                  />
                </center>
              </>
            )}
          </div>
        </div>
      </div>
      <FooterLandingPage />
    </>
  );
};

export default Checkout;
