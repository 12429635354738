import React from 'react';

import HeaderLangingPage from '../components/HeaderLangingPage';
import FooterLandingPage from '../components/FooterLandingPage';

const Return = () => {
    return (
        <>
            <HeaderLangingPage />         
            <br />

            <div id="tt-pageContent">
                <div class="container-indent">
                    <div class="container">

                    <h2>
                    Return Policy – House of plug
                    </h2>
                    <small>
                    Last updated February 24, 2021.
                    </small>

                    <p>
                        This section is helpful to all those customers who for some reasons are not satisfied with the purchase and want to return the item(s), unworn and not used, and therefore want to request a refund or an exchange. 
                        <br />
                        House of plug guarantees to accept all products returned within fourteen (14) working days after receipt. Each delivered product has attached an authenticity tag which includes the Return Instructions with all the steps to follow. The product will be accepted only if provided with the authenticity tag. The product must also be returned in the exact same conditions in which it left our warehouse.
                        <br />
                        Products must be returned unused, undamaged, unwashed or otherwise altered, with all their original components, included authenticity tags, intact and complete warranty seals and original packaging (including wrapping, boxes and any other material such as dust-bags, hangers and suit-covers). Right of withdrawal can only be applied to whole purchased products; therefore, it cannot be performed on one or more components of purchased products.
                        <br />
                        <br />
                        <h2>
                        Return Conditions
                        </h2>

                        • Returns must be made within fourteen (14) days from the delivery date. Return shipping fees, as well as any custom fees, are paid for by the customer.
                        <br />
                        • Products must be returned in their original packaging with all the original tags and accessories, including the authenticity tag and the security seal that should not be cut or broken
                        <br />
                        • Products must be returned in the same condition they were originally dispatched: not worn, used, altered or damaged in any way. Shoe insoles must not be altered in any way, with no signs of use.
                        <br />
                        • Damaged returns will not be accepted and will be returned to the customer at their own expense.
                        <br />
                        • Items with make-up stains will not be accepted as a return. Be careful with make-up!
                        <br />
                        • The shipment is under full responsibility of the customer until the certificate of receipt arrives to our warehouse. We strongly recommend that you use a trackable method to mail your return.
                        <br />
                        <br />
                        <h2>
                        Return Procedure
                        </h2>
                        • To make a return, customers must completely fill out the <a href="/returnform"> Return form</a>.
                        <br />
                        • Within 3 working days, the customer will receive a confirmation email with all the instructions on how to proceed to make the return. Customers must strictly follow these instructions.
                        <br />
                        • Houseofplug.com reserves the right to inspect the returned items to verify their integrity. If the items do not meet the above-mentioned return conditions, the items will be returned to the customer at their expense.
                        <br />
                        • Houseofplug.com reserves the right to reject unauthorized returns.
                        <br />
                        <br />
                        <h2>
                        Color/Size Exchange
                        </h2>
                        • We accept both size and color changes of the same model, if available.
                        <br />
                        • The return shipment of the item to change is charged to the customer while the shipment of the new item is free of charge up to one change per item.
                        <br />
                        <br />
                        <h2>
                        Defective Items
                        </h2>
                        • In case of defective or incorrect items following a mistake attributed to houseofplug.com, customers are asked to follow the same return procedure.
                        <br />
                        • In these cases, houseofplug.com will refund the purchase price and shipping costs.
                        <br />
                        <br />
                       
                        <h2>
                        Refunds
                        </h2>
                        • Refunds will cover only the prices of the returned items (i.e. not including shipping costs and custom fees)
                        <br />
                        • Refunds will be made within 10 working days of house of plug’s receipt and inspection of the returned items. Refunds to credit cards usually take up to 5 working days to appear on the statement (processing times may vary depending on the issuing bank). PayPal refunds can take to 2-3 working days.
                        <br />
                        • The refund will be made on the same credit card or on the same account used by the customer for payment. This counts for PayPal or Ideal payments as well.
                        <br />
                        • Outside the EU, customs duties and sales taxes are non-refundable. You may be able to recover these costs by contacting your local customs bureau directly.
                        <br />
                        <br />

                        <h2>
                        Return notes
                        </h2>
                        • The shipping costs paid by the customer’s order will not be refunded, nor any import taxes or duties paid by the customer to import the item into their country. This is only for orders outside Europe. Import taxes and duties are already included if the order has been placed within Europe.
                        <br />
                        • Refunds are made each Friday of the week, so if we receive the return on Monday, you will have to wait until Friday to receive the refund.
                        <br />
                        • Please pack your item securely. If you don’t have the original packaging, use a sturdy box with packing materials (plastic bubbles or paper). STICK THE LABEL ON THE BOX. Be sure not to damage the product packaging (i.e. shoes box or dust bag), as RETURN WILL NOT BE ACCEPTED NOR REFUNDED.
                        <br />
                        • Refunds are made each Friday of the week, so if we receive the return on Monday, you (the customer) will have to wait until Friday to receive the refund.
                        <br />
                        <br />
                        <h2>
                        Right of withdrawal
                        </h2>
                        The customer has the right to withdrawal the order for any reason, as regulated in Decree 206/2005 (consumer code). This right may be exercised by customers "consumers" only (persons who purchase the goods for purposes not related to their professional, entrepreneurial or commercial activity).
                    </p>
                    </div>
                </div>
            </div>

            <FooterLandingPage />
        </>
    )
}

export default Return
