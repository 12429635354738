import React from "react";
import clsx from "clsx";
import { useAuth } from "../contexts/AuthContext";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import Badge from "@material-ui/core/Badge";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import NotificationsIcon from "@material-ui/icons/Notifications";
import { mainListItems } from "./listitems";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import axios from "axios";
import CircularProgress from "@material-ui/core/CircularProgress";
import { API_SERVICE } from "../config/URI";
import Paper from "@material-ui/core/Paper";
import InputBase from "@material-ui/core/InputBase";
import SearchIcon from "@material-ui/icons/Search";
import Pagination from "@material-ui/lab/Pagination";
import {Box} from "@material-ui/core"
const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: "none",
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  inputroot: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    width: 600,
    marginTop: "10px",
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 240,
  },
}));

const ProductList = ({
  addRecommended,
  addFeatured,
  addWeek,
  product,
  addOtherStore,
}) => {
  var pa = product.price;
  return (
    <Grid item xs={3}>
      <Card>
        <CardActionArea>
          <CardContent>
            <center>
              <img
                // style={{ width: "28vh", height: "34vh" }}
                style={{ width: "100%", height: "100%" }}
                src={product.displayImage}
              />
              <p>{product?.product?.Sku}</p>
              <h5 style={{ fontSize: '14px' }}>{product.title}</h5>
              <h6>
                {product.gender} | Price € {pa} Inc. VAT
              </h6>
            </center>
          </CardContent>
        </CardActionArea>
        <div style={{ padding: "10px" }}>
          <Button
            href={`/adminmainstoreedit?id=${product._id}`}
            variant="outlined"
            style={{ color: "#ffffff", backgroundColor: "#000000" }}
            fullWidth
          >
            Edit Product
          </Button>
          <Button
            onClick={() => addFeatured(product)}
            style={{ marginTop: "4px" }}
            variant="outlined"
            fullWidth
          >
            Add to Featured Product
          </Button>
          <Button
            onClick={() => addWeek(product)}
            style={{ marginTop: "4px" }}
            variant="outlined"
            fullWidth
          >
            Add to Product of Week
          </Button>

          {product.recommended ? (
            <Button
              onClick={() => addRecommended(product)}
              style={{ marginTop: "4px" }}
              variant="outlined"
              fullWidth
            >
              Remove from Recommended
            </Button>
          ) : (
            <Button
              onClick={() => addRecommended(product)}
              style={{ marginTop: "4px" }}
              variant="outlined"
              fullWidth
            >
              Add to Recommended
            </Button>
          )}

          <Button
            onClick={() => addOtherStore(product, "New In")}
            style={{ marginTop: "4px" }}
            variant="outlined"
            fullWidth
          >
            Add to New In Product
          </Button>
          <Button
            onClick={() => addOtherStore(product, "Sale")}
            style={{ marginTop: "4px" }}
            variant="outlined"
            fullWidth
          >
            Add to Sale Product
          </Button>
        </div>
      </Card>
    </Grid>
  );
};

export default function MainStore() {
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);
  const [search, setsearch] = React.useState("ACCESSORIES");

  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  const [products, setproducts] = React.useState([]);
  const [loading, setloading] = React.useState(true);
  const [productFilter,setProductFilter]=React.useState("All");
  const [page,setPage]=React.useState(0);
  const [totalProductsCount,setTotalProductsCount]=React.useState(0)
  React.useEffect(() => {
    const get=()=>{
      axios
      .get(`${API_SERVICE}/api/v1/main/getallproductmainstorecount`)
      .then((response) => {
        setTotalProductsCount(response.data);
      
      })
      .catch((err) => console.log(err));
      axios
      .get(`${API_SERVICE}/api/v1/main/getallproductmainstore/8/0`)
      .then((response) => {
        setproducts(response.data);
        setloading(false);
      })
      .catch((err) => console.log(err));
    }
    get();
  }, []);
  const pageChange = (event, v) => {
    setPage(v);
    if(productFilter==="All"){
      axios
      .get(`${API_SERVICE}/api/v1/main/getallproductmainstore/8/${8*v}`)
      .then((response) => {
        setproducts(response.data);
        setloading(false);
      })
      .catch((err) => console.log(err));
    }else{
      axios
      .get(
        `${API_SERVICE}/api/v1/main/getallproductsmainstorefilters/${productFilter}/8/${8*v}`
      )
      .then((response) => {
        setproducts(response.data);
        setloading(false);
      })
      .catch((err) => console.log(err));
    }

  }
 

  const applyFilters = (filter) => {
    setloading(true);
    setPage(0);
    setProductFilter(filter);
    if (filter === "All") {
    
      axios
      .get(`${API_SERVICE}/api/v1/main/getallproductmainstorecount`)
      .then((response) => {
        setTotalProductsCount(response.data);
      
      })
      .catch((err) => console.log(err));
      axios
      .get(`${API_SERVICE}/api/v1/main/getallproductmainstore/8/0`)
      .then((response) => {
        setproducts(response.data);
        setloading(false);
      })
      .catch((err) => console.log(err));
    } else {
      axios
      .get(
        `${API_SERVICE}/api/v1/main/getallproductsmainstorefilterscount/${filter}`
      )
      .then((response) => {
        setTotalProductsCount(response.data);
      })
      .catch((err) => console.log(err));
  }
      axios
        .get(
          `${API_SERVICE}/api/v1/main/getallproductsmainstorefilters/${filter}/8/0`
        )
        .then((response) => {
          setproducts(response.data);
          setloading(false);
        })
        .catch((err) => console.log(err));
    }
  

  const addFeatured = (product) => {
    var pa = product.price;
    var perc = (21 / 100) * Number(pa);
    var finalP = (Number(pa) + Number(perc)).toFixed(0);

    var uploadData = {
      sku: product.product.Sku,
      productId: product._id,
      title: product.title,
      price: finalP,
      displayImage: product.displayImage,
    };
    axios
      .post(
        `${API_SERVICE}/api/v1/main/addfeaturedproductmainstore`,
        uploadData
      )
      .then((response) => {
        if (response.status === 200) {
          alert("Product Added to Featued Store");
        }
      })
      .catch((err) => console.log(err));
  };

  const addWeek = (product) => {
    var pa = product.price;
    var perc = (21 / 100) * Number(pa);
    var finalP = (Number(pa) + Number(perc)).toFixed(0);

    var uploadData = {
      sku: product.product.Sku,
      productId: product._id,
      title: product.title,
      price: finalP,
      displayImage: product.displayImage,
    };
    axios
      .post(`${API_SERVICE}/api/v1/main/addweekproductmainstore`, uploadData)
      .then((response) => {
        if (response.status === 200) {
          alert("Product Added to Product of Week");
        }
      })
      .catch((err) => console.log(err));
  };

  const addRecommended = (product) => {
    let prod={...product};
    var pa = product.price;
    var perc = (21 / 100) * Number(pa);
    var finalP = (Number(pa) + Number(perc)).toFixed(0);
    prod.price=finalP;
    let p=[...products];
   
    axios
      .post(
        `${API_SERVICE}/api/v1/main/addrecommendedproductmainstore`,
        {product:prod}
      )
      .then((response) => {
        
        p=p.filter((e)=>e._id!==prod._id);
        setproducts(p);
        setTotalProductsCount(totalProductsCount-1);
      
            alert("Product added to Recommended");
        
        
      })
      .catch((err) => console.log(err));
  };

  const addOtherStore = (product, productfor) => {
    var pa = product.price;
    var perc = (21 / 100) * Number(pa);
    var finalP = (Number(pa) + Number(perc)).toFixed(0);

    var uploadData = {
      title: product.title,
      price: finalP,
      displayImage: product.displayImage,
      category: product.category,
      madein: product.madein,
      gender: product.gender,
      description: product.description,
      product: product.product,
      productfor,
    };

    axios
      .post(`${API_SERVICE}/api/v1/main/addproductotherstore`, uploadData)
      .then((response) => {
        if (productfor === "Sale") {
          window.location.href = `/admineditsale?id=${response.data}`;
        } else {
          alert(`Product Added to ${productfor}`);
        }
        // if (response.status === 200) {
        //   console.log(productfor, response);
        // }
      })
      .catch((err) => console.log(err));
  };

  const showProductList = () => {
    return products.map((product) => {
      return (
        <ProductList
          addWeek={addWeek}
          addRecommended={addRecommended}
          addOtherStore={addOtherStore}
          addFeatured={addFeatured}
          product={product}
          key={product._id}
        />
      );
    });
  };

  const searchMainStore = () => {
    setloading(true);
    axios
      .get(`${API_SERVICE}/api/v1/main/searchmainproductstore/${search}`)
      .then((response) => {
        setproducts(response.data);
        setloading(false);
      })
      .catch((err) => console.log(err));
  };
  const { logout } = useAuth();
  const handleSignOut = () => {
    logout();
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="absolute"
        className={clsx(classes.appBar, open && classes.appBarShift)}
      >
        <Toolbar className={classes.toolbar}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            className={clsx(
              classes.menuButton,
              open && classes.menuButtonHidden
            )}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            component="h1"
            variant="h6"
            color="inherit"
            noWrap
            className={classes.title}
          >
            Dashboard
          </Typography>{" "}
          <Button variant="contained" color="secondary" onClick={handleSignOut}>
            Signout
          </Button>
          <IconButton color="inherit"></IconButton>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        classes={{
          paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
        }}
        open={open}
      >
        <div className={classes.toolbarIcon}>
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <Divider />
        <List>{mainListItems}</List>
      </Drawer>
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          <Button onClick={() => applyFilters("All")} color="primary">
            SHOW All
          </Button>
          <Button onClick={() => applyFilters("M")} color="primary">
            Males
          </Button>
          <Button onClick={() => applyFilters("F")} color="primary">
            Female
          </Button>

          {loading === false ? (
            <center>
              <Paper component="form" className={classes.inputroot}>
                <InputBase
                  className={classes.input}
                  onChange={(e) => setsearch(e.target.value)}
                  placeholder="Search by Title, SKU, Price, Brand"
                  inputProps={{ "aria-label": "search products" }}
                />
                <IconButton
                  onClick={searchMainStore}
                  type="button"
                  className={classes.iconButton}
                  aria-label="search"
                >
                  <SearchIcon />
                </IconButton>
              </Paper>
              <br />
            </center>
          ) : null}
          <Grid container spacing={3}>
            {loading === true ? (
              <center style={{ marginTop: "10%", marginLeft: "50%" }}>
                <CircularProgress />
              </center>
            ) : (
              <>{showProductList()}</>
            )}
          </Grid>
          <Box sx={{mt:5,mb:5,ml:50}}>
                 <Pagination
                  color="primary"
                  shape="rounded"
                          count={Math.floor(totalProductsCount/8)}
                          page={page}
                          onChange={pageChange}
                        />
                 </Box>
        </Container>
      </main>
    </div>
  );
}
