import React from 'react';

import HeaderLangingPage from '../components/HeaderLangingPage';
import FooterLandingPage from '../components/FooterLandingPage';

const Privacy = () => {
    return (
        <>
            <HeaderLangingPage />         
            <br />

            <div id="tt-pageContent">
                <div class="container-indent">
                    <div class="container">
                    <h2>
                    Payment Methods
                    </h2>
                    <p>
                    House of plug accepts the following payment methods: <br /><br />
                    Paypal <br />
                    Credit card <br />
                    iDeal<br /><br />

                    During payment process please select “PayPal” among payment methods and you will be directly transferred to PayPal website. If you are already registered as a PayPal client, you just need to Log In using your data and confirm the payment.
                    <br />

                    If you don’t have a PayPal Account, you can register and proceed with the payment. Otherwise, you can also pay with your credit card through PayPal security network, without registration.

                    <br />
                    Once you have confirmed the order you will receive an automatic e-mail with your order details. In case of refunds, money will be returned into your PayPal account.
                    <br />
                    <br />
                    By using iDEAL consumers are able to pay for their online purchases in a user-friendly, cost-efficient and secure fashion. This payment method is strictly for customers within The Netherlands. For more information about our iDeal payments please visit <a href="www.ideal.nl">www.ideal.nl</a> 
                    <br />
                    Please contact info@houseofplug.com incase you are having trouble with one of our payment methods. We will try our best to navigate you step-by-step through the payment method of choice.

                    </p>


                    <br />

                    <h2>
                    Payment Methods
                    </h2>


                    House of plug uses Secure Socket Layer (SSL) technology to encrypt and protect the data you send us over the internet. If SSL is enabled, you will see a padlock at the top of your browser and you can click on this to find out more information about the SSL digital certificate registration.
                    <br />


                    You will also notice that when you look at the URL at the top of the browser you will see that it begins with ‘https’ instead of ‘http’. This means that you are in secure mode.
                    <br />
                    House of plug is registered with Google Firebase as an authentic site. This ensures that your information is kept private between your web browser and our web server.
                    <br />
                    If your card issuer is based in the EU, you may be asked to verify your payment due to a new requirement under the second Payment Services Directive (PSD2). You’ll be directed to a dedicated page to enter further information or asked to authorize the payment using your mobile phone. Once completed, you’ll return to House of plug.

                    </div>
                </div>
            </div>

            <FooterLandingPage />
        </>
    )
}

export default Privacy
