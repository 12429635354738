import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import axios from 'axios';
import queryString from 'query-string';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import { v4 as uuidv4 } from 'uuid';
import CloseIcon from '@material-ui/icons/Close';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
// Components
import HeaderLangingPage from '../components/HeaderLangingPage';
import FooterLandingPage from '../components/FooterLandingPage';

// Extras
import { API_SERVICE } from '../config/URI';
import { firestore } from "../Firebase/index";

const Accordion = withStyles({
    root: {
      border: '1px solid rgba(0, 0, 0, .125)',
      boxShadow: 'none',
      '&:not(:last-child)': {
        borderBottom: 0,
      },
      '&:before': {
        display: 'none',
      },
      '&$expanded': {
        margin: 'auto',
      },
    },
    expanded: {},
})(MuiAccordion);
const AccordionSummary = withStyles({
    root: {
      backgroundColor: 'rgba(0, 0, 0, .03)',
      borderBottom: '1px solid rgba(0, 0, 0, .125)',
      marginBottom: -1,
      minHeight: 56,
      '&$expanded': {
        minHeight: 56,
      },
    },
    content: {
      '&$expanded': {
        margin: '12px 0',
      },
    },
    expanded: {},
})(MuiAccordionSummary);
  
const AccordionDetails = withStyles((theme) => ({
    root: {
      padding: theme.spacing(2),
    },
}))(MuiAccordionDetails);

const View = ({ location }) => {
    const [products, setProducts] = React.useState({});
    const [loading, setloading] = React.useState(true);
    const [open, setOpen] = React.useState(false);
    const [qty, setqty] = React.useState(1);
    const [userId, setuserId] = React.useState('');
    const [expanded, setExpanded] = React.useState('panel1');
    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    const [description, setdescription] = React.useState([]);


    const handleClick = () => {
        setOpen(true);
    };
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
        return;
        }
        setOpen(false);
    };

    React.useEffect(() => {
        const { id } = queryString.parse(location.search);
        axios.get(`${API_SERVICE}/api/v1/main/getproductitemdetails/${id}`)
            .then(response => {
                setProducts(response.data[0]);
                var names = response.data[0].description;
                var nameArr = names.split(',');
                setdescription(nameArr);
                setloading(false);
            })
        if (localStorage.getItem("userId") === null) {
            localStorage.setItem("userId", uuidv4()+Date.now());
        } else {
            var userId = localStorage.getItem("userId");
            setuserId(userId);
        }
    }, []);

    const addProduct = (product) => {
        console.log(product);
        var docRef = firestore.collection("cart").doc(userId);
        docRef.get().then(function(doc) {
            if (doc.exists) {
                var items = doc.data().items;
                if (items === 10000) {
                    console.log("Maximum Number of Items in Cart Exceed");
                } else {
                    var uploadData = {
                        userId,
                        title: product.title,
                        qty,
                        image: product.displayImage,
                        price: Number(product.price)%10?Number(product.price) + (10 - Number(product.price) % 10):Number(product.price),
                        product
                    }
                    axios.post(`${API_SERVICE}/api/v1/main/addproducttocart`, uploadData)
                        .then((response) => {
                            if (response.status === 200) {
                                items = items + 1;
                                docRef.set({
                                    items 
                                }, { merge: true });
                                handleClick();
                            }
                        }).catch(err => console.log(err));
                }
            } else {
                docRef.set({
                    items: 1 
                }, { merge: true });
                var uploadData = {
                    userId,
                    title: product.title,
                    qty,
                    image: product.displayImage,
                    price: Number(product.price)%10?Number(product.price) + (10 - Number(product.price) % 10):Number(product.price),
                    product
                }
                axios.post(`${API_SERVICE}/api/v1/main/addproducttocart`, uploadData)
                    .then((response) => {
                        if (response.status === 200) {
                            handleClick();
                        }
                    }).catch(err => console.log(err));
            }
        }).catch(function(error) {
            console.log("Error getting document:", error);
        });
    }

    return (
        <>
            <Snackbar
                anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
                }}
                open={open}
                autoHideDuration={4000}
                onClose={handleClose}
                message="Item Added to Cart"
                action={
                <React.Fragment>
                    <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
                        <CloseIcon fontSize="small" />
                    </IconButton>
                </React.Fragment>
                }
            />
            <HeaderLangingPage />         
            <br />
            <div id="tt-pageContent">
                <div class="container-indent">
                    {
                        loading ? (
                            <>
                            </>
                        ) : (
                            <div class="tt-mobile-product-layout visible-xs">
                                <Carousel style={{ width: '50vh' }} >
                                    {
                                        loading === false ? (
                                            products.product.Images.map((row) => (
                                                <div>
                                                    <img src={row} />
                                                </div>
                                            ))
                                        ) : null
                                    }
                                </Carousel>
                            </div>
                        )
                    }

                    <div class="container container-fluid-mobile">
                        <div class="row">
                            <div class="col-6 hidden-xs">
                                <Carousel style={{ width: '50vh' }} >
                                    {
                                        loading === false ? (
                                            products.product.Images.map((row) => (
                                                <div>
                                                    <img style={{ border: '2px solid black' }} src={row} />
                                                </div>
                                            ))
                                        ) : null
                                    }
                                </Carousel>
                            </div>
                            <div class="col-6">
                                {
                                    loading ? (
                                        <>
                                        </>
                                    ) : (
                                        <div class="tt-product-single-info mt-5">
                                            {/* <div class="tt-add-info">
                                                <ul>
                                                    <li><span>Size:</span> {products.product.Variants[0].Size} </li>
                                                </ul>
                                            </div> */}
                                            <h4>{products.product.Brand}</h4>
                                            <h4>€ {Number(products.price)%10?Number(products.price) + (10 - Number(products.price) % 10):Number(products.price)} <span style={{ fontSize: '14px' }} >(VAT Inc.)</span>  </h4>
                                            <div hidden class="tt-wrapper">
                                                <div class="tt-countdown_box_02">
                                                    <div class="tt-countdown_inner">
                                                        <div class="tt-countdown"
                                                            data-date="2020-11-01"
                                                            data-year="Yrs"
                                                            data-month="Mths"
                                                            data-week="Wk"
                                                            data-day="Day"
                                                            data-hour="Hrs"
                                                            data-minute="Min"
                                                            data-second="Sec"></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="tt-wrapper">
                                                {/* <div class="tt-wrapper">
                                                    <div class="tt-title-options">QTY</div>
                                                    <form class="form-default">
                                                        <div class="form-group">
                                                            <select value={qty} onChange={(e) => setqty(e.target.value)} class="form-control">
                                                                <option value="1">1</option>
                                                                <option value="2">2</option>
                                                                <option value="3">3</option>
                                                            </select>
                                                        </div>
                                                    </form>
                                                </div> */}
                                                
                                            </div>
                                            <Accordion square expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                                                <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                                                <Typography>EDITORS NOTE</Typography>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                <div class="tt-wrapper">
                                                    <ul>
                                                        {description.map((d) => (
                                                            <li><h6>{d}</h6></li>
                                                        ))}
                                                    </ul>
                                                </div>
                                                </AccordionDetails>
                                            </Accordion>
                                            <Accordion square expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                                                <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
                                                <Typography>DELIVERY & RETURN</Typography>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                <Typography>
                                                    <p>
                                                        <h4>Return Policy – House of plug</h4>
                                                        This section is helpful to all those customers who for some reasons are not satisfied with the purchase and want to return the item(s), unworn and not used, and therefore want to request a refund or an exchange. 
                                                        House of plug guarantees to accept all products returned within fourteen (14) working days after receipt. Each delivered product has attached an authenticity tag which includes the Return Instructions with all the steps to follow. 
                                                        <a className="text-primary ml-2" href="/return">View More</a>
                                                    </p>
                                                    <p>
                                                        <h4>Delivery</h4>
                                                        House of plug ships worldwide using DHL Express Service. We offer FREE shipping for all orders within EUROPE. The shipping costs varies depending on the shipping country and the total amount of the order. You can see the shipping cost directly in the cart before confirming the order, just after filling shipping details.
                                                        <a className="text-primary ml-2" href="/delivery">View More</a>
                                                    </p>
                                                </Typography>
                                                </AccordionDetails>
                                            </Accordion>
                                            <div class="tt-row-custom-01 mt-4 mb-4">
                                                <div class="col-item w-100">
                                                    <a href="#!" onClick={() => addProduct(products)} class="btn btn-lg"><i class="icon-f-39"></i>ADD TO CART</a>
                                                </div>
                                            </div>
                                            {/* <div class="tt-wrapper">
                                                <ul class="tt-list-btn">
                                                    <li><a class="btn-link" href="#"><i class="icon-n-072"></i>ADD TO WISH LIST</a></li>
                                                    <li><a class="btn-link" href="#"><i class="icon-n-08"></i>ADD TO COMPARE</a></li>
                                                </ul>
                                            </div> */}
                                            <div class="tt-wrapper">
                                                <div class="tt-add-info">
                                                    <ul>
                                                        <li><span>SKU:</span> {products.product.Sku}</li>
                                                        <li><span>Size:</span> {products.product.Variants[0].Size}</li>
                                                        <li><span>Vendor:</span> {products.product.Brand}</li>
                                                        <li><span>Product Type:</span> {products.product.Category}</li>
                                                        <li><span>Tag:</span><a href="#">{products.gender}</a><a href="#"></a></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }

                            </div>
                        </div>
                    </div>
                </div>
                {/* <div class="container-indent wrapper-social-icon">
                    <div class="container">
                        <ul class="tt-social-icon justify-content-center">
                            <li><a class="icon-g-64" href="https://www.facebook.com/houseofplug.official/" style={{color:"#000"}}></a></li>
                            <li><a class="icon-g-67" href="https://www.instagram.com/houseofplug.official/" style={{color:"#000"}}></a></li>
                        </ul>
                    </div>
                </div> */}
                <div class="container-indent">
                    <div class="container container-fluid-custom-mobile-padding">
                        <div class="tt-block-title text-left">
                            <h3 class="tt-title-small"></h3>
                        </div>
                        <div class="tt-carousel-products row arrow-location-right- tt-alignment-img tt-layout-product-item slick-animated-show-js">
                            
                        </div>
                    </div>
                </div>
            </div>
            <FooterLandingPage />
        </>
    )
}

export default View
