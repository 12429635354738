import React, { useState, useEffect } from "react";
import axios from "axios";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { v4 as uuidv4 } from "uuid";

// URI
import { API_SERVICE } from "../config/URI";
// stripe
import {
  useStripe,
  useElements,
  IdealBankElement,
} from "@stripe/react-stripe-js";
// Util imports
import { makeStyles } from "@material-ui/core/styles";
// Custom Components
import IdealBankSection from "./IdealBankSection";
import { firestore } from "../Firebase/index";

const useStyles = makeStyles({
  root: {
    maxWidth: 500,
    // height: 300,
    marginTop: "4px",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    alignContent: "flex-start",
    border: "1px solid #e9e7e7",
    padding: "20px",
  },
  div: {
    display: "flex",
    flexDirection: "row",
    alignContent: "flex-start",
    justifyContent: "space-between",
  },
  button: {
    margin: "2em auto 1em",
  },
});

function HomePageIdeal({ type, amount, products, totalprice }) {
  const classes = useStyles();
  // State
  const [paysubmit, setPaySubmit] = useState(false);

  const [companyAddress, setCompanyAddress] = React.useState("");
  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [phoneno, setphoneno] = React.useState("");
  const [address, setaddress] = React.useState("");
  const [zipcode, setzipcode] = React.useState("");
  const [userId, setuserId] = React.useState("");
  const [CLIENT_SECRET, setCLIENT_SECRET] = React.useState("");
  const [totalItems, setTotalItems] = React.useState(0);


  const stripe = useStripe();
  const elements = useElements();

  React.useEffect(() => {
    if (totalprice === 0) {
      console.log("This is zero" ,totalprice);
    } else {
      if (localStorage.getItem("userId") === null) {
        localStorage.setItem("userId", uuidv4() + Date.now());
      } else {
        var userId = localStorage.getItem("userId");
        setuserId(userId);
  
        var tempamount = totalprice - discount;
        console.log("Price" ,tempamount);
        axios
          .post(`${API_SERVICE}/api/v1/main/secret`, {
            amount: totalprice - discount
          })
          .then((response) => {
            setCLIENT_SECRET(response.data.client_secret);
          })
          .catch((err) => console.log(err));
      }
    }
  }, [totalprice]);

  React.useEffect(() => {
    if (localStorage.getItem("userId") === null) {
    } else {
      var userId = localStorage.getItem("userId");
      firestore
        .collection("cart")
        .doc(userId)
        .onSnapshot(function (doc) {
          if (typeof doc.data() == "undefined") {
            setTotalItems(0);
          } else {
            setTotalItems(doc.data().items);
          }
        });
    }
  }, []);

  const ProductItem = (props) => {
    return (
      <>
        <div
          style={{ display: "flex", marginBottom: "10px", textAlign: "left" }}
        >
          <div class="tt-product-img">
            <img
              style={{ maxWidth: "100px", padding: "10px" }}
              src={props.product.image}
              data-src={props.product.image}
              alt=""
            />
          </div>
          <div>
            <h6>{props.product.title}</h6>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <p>Quantity : {props.product.qty}</p>
              <h5>
                Price: €
                {Number(props.product.price) * Number(props.product.qty)}
              </h5>
            </div>
          </div>
        </div>
        <hr style={{ marginTop: "0", marginBottom: "20px" }} />
      </>
    );
  };
  const handleSubmit = async (event) => {
    if (firstName === "" || lastName === "" || email === "" || address === "" || zipcode === "" ) {
      alert("Please fill all the fields");
    } else {
      // We don't want to let default form submission happen here,
      // which would refresh the page.
      event.preventDefault();

      if (!stripe || !elements) {
        // Stripe.js has not yet loaded.
        // Make sure to disable form submission until Stripe.js has loaded.
        return;
      }

      const idealBank = elements.getElement(IdealBankElement);

      // For brevity, this example is using uncontrolled components for
      // the accountholder's name. In a real world app you will
      // probably want to use controlled components.
      // https://reactjs.org/docs/uncontrolled-components.html
      // https://reactjs.org/docs/forms.html#controlled-components

      const accountholderName = `${firstName} ${lastName}`;

      const { error } = await stripe.confirmIdealPayment(`${CLIENT_SECRET}`, {
        payment_method: {
          ideal: idealBank,
          billing_details: {
            name: accountholderName,
          },
        },
        return_url: "https://www.houseofplug.com/thankyou",
      });

      if (error) {
        // Show error to your customer.
        console.log(error.message);
      } else {
        // The payment has been processed!
          var note = localStorage.getItem("note");

          var uploadData = {
            transactionId: 'Ideal',
            email,
            fullName: `${firstName} ${lastName}`,
            phoneno,
            address,
            zipcode,
            userId,
            amount: totalprice - discount,
            note,
            totalItems: totalItems,
            companyAddress,
          };
          axios
            .post(`${API_SERVICE}/api/v1/main/paymentsuccessfull`, uploadData)
            .then((response) => {
              if (response.status === 200) {
                var docRef = firestore.collection("cart").doc(userId);
                docRef
                  .set(
                    {
                      items: 0,
                    },
                    { merge: true }
                  )
                  .then(() => {
                    var docRef = firestore
                      .collection("discount")
                      .doc(userId)
                      .delete();
                  })
                  .then(() => {
                    window.location.href = `/thankyou`;
                  });
              }
            })
            .catch((err) => console.log(err));
      }

      // Otherwise the customer will be redirected away from your
      // page to complete the payment with their bank.
    }
  };

  const [discount, setDiscount] = React.useState(0);
  useEffect(() => {
    const userId = localStorage.getItem("userId");
    var docRef = firestore.collection("discount").doc(userId);
    docRef
      .get()
      .then(function (doc) {
        var discount = doc.data().discount;
        if (discount > 0) {
          setDiscount(15);
        }
      })
      .catch(function (error) {
        console.log("Error getting document:", error);
      });
  }, []);

  return (
    <>
      {amount > 0 && <h1 class="tt-title-subpages noborder">€ {amount}</h1>}
      <div className="row">
        <div className="col-md">
          <div id="tt-pageContent">
            <div class="container-indent">
              <div class="container container-fluid-custom-mobile-padding">
                <div class="tt-shopping-layout">
                  <h2 class="tt-title">YOUR ADDRESS</h2>
                  <div class="tt-wrapper">
                    <div
                      class="form-default"
                      style={{ border: "1px solid #e9e7e7", padding: "20px" }}
                    >
                      <form>
                        <div class="form-group">
                          <label for="shopInputFirstName" class="control-label">
                            FIRST NAME *
                          </label>
                          <input
                            onChange={(e) => setFirstName(e.target.value)}
                            type="text"
                            class="form-control"
                            id="shopInputFirstName"
                          />
                        </div>
                        <div class="form-group">
                          <label for="shopInputLastName" class="control-label">
                            LAST NAME *
                          </label>
                          <input
                            onChange={(e) => setLastName(e.target.value)}
                            type="text"
                            class="form-control"
                            id="shopInputLastName"
                          />
                        </div>
                        <div class="form-group">
                          <label for="shopCompanyName" class="control-label">
                            COUNTRY *
                          </label>
                          <select class="form-control">
                            <option value="Afghanistan">Afghanistan</option>
                            <option value="Åland Islands">Åland Islands</option>
                            <option value="Albania">Albania</option>
                            <option value="Algeria">Algeria</option>
                            <option value="American Samoa">
                              American Samoa
                            </option>
                            <option value="Andorra">Andorra</option>
                            <option value="Angola">Angola</option>
                            <option value="Anguilla">Anguilla</option>
                            <option value="Antarctica">Antarctica</option>
                            <option value="Antigua and Barbuda">
                              Antigua and Barbuda
                            </option>
                            <option value="Argentina">Argentina</option>
                            <option value="Armenia">Armenia</option>
                            <option value="Aruba">Aruba</option>
                            <option value="Australia">Australia</option>
                            <option value="Austria">Austria</option>
                            <option value="Azerbaijan">Azerbaijan</option>
                            <option value="Bahamas">Bahamas</option>
                            <option value="Bahrain">Bahrain</option>
                            <option value="Bangladesh">Bangladesh</option>
                            <option value="Barbados">Barbados</option>
                            <option value="Belarus">Belarus</option>
                            <option value="Belgium">Belgium</option>
                            <option value="Belize">Belize</option>
                            <option value="Benin">Benin</option>
                            <option value="Bermuda">Bermuda</option>
                            <option value="Bhutan">Bhutan</option>
                            <option value="Bolivia">Bolivia</option>
                            <option value="Bosnia and Herzegovina">
                              Bosnia and Herzegovina
                            </option>
                            <option value="Botswana">Botswana</option>
                            <option value="Bouvet Island">Bouvet Island</option>
                            <option value="Brazil">Brazil</option>
                            <option value="British Indian Ocean Territory">
                              British Indian Ocean Territory
                            </option>
                            <option value="Brunei Darussalam">
                              Brunei Darussalam
                            </option>
                            <option value="Bulgaria">Bulgaria</option>
                            <option value="Burkina Faso">Burkina Faso</option>
                            <option value="Burundi">Burundi</option>
                            <option value="Cambodia">Cambodia</option>
                            <option value="Cameroon">Cameroon</option>
                            <option value="Canada">Canada</option>
                            <option value="Cape Verde">Cape Verde</option>
                            <option value="Cayman Islands">
                              Cayman Islands
                            </option>
                            <option value="Central African Republic">
                              Central African Republic
                            </option>
                            <option value="Chad">Chad</option>
                            <option value="Chile">Chile</option>
                            <option value="China">China</option>
                            <option value="Christmas Island">
                              Christmas Island
                            </option>
                            <option value="Cocos (Keeling) Islands">
                              Cocos (Keeling) Islands
                            </option>
                            <option value="Colombia">Colombia</option>
                            <option value="Comoros">Comoros</option>
                            <option value="Congo">Congo</option>
                            <option value="Congo, The Democratic Republic of The">
                              Congo, The Democratic Republic of The
                            </option>
                            <option value="Cook Islands">Cook Islands</option>
                            <option value="Costa Rica">Costa Rica</option>
                            <option value="Cote D'ivoire">Cote D'ivoire</option>
                            <option value="Croatia">Croatia</option>
                            <option value="Cuba">Cuba</option>
                            <option value="Cyprus">Cyprus</option>
                            <option value="Czech Republic">
                              Czech Republic
                            </option>
                            <option value="Denmark">Denmark</option>
                            <option value="Djibouti">Djibouti</option>
                            <option value="Dominica">Dominica</option>
                            <option value="Dominican Republic">
                              Dominican Republic
                            </option>
                            <option value="Ecuador">Ecuador</option>
                            <option value="Egypt">Egypt</option>
                            <option value="El Salvador">El Salvador</option>
                            <option value="Equatorial Guinea">
                              Equatorial Guinea
                            </option>
                            <option value="Eritrea">Eritrea</option>
                            <option value="Estonia">Estonia</option>
                            <option value="Ethiopia">Ethiopia</option>
                            <option value="Falkland Islands (Malvinas)">
                              Falkland Islands (Malvinas)
                            </option>
                            <option value="Faroe Islands">Faroe Islands</option>
                            <option value="Fiji">Fiji</option>
                            <option value="Finland">Finland</option>
                            <option value="France">France</option>
                            <option value="French Guiana">French Guiana</option>
                            <option value="French Polynesia">
                              French Polynesia
                            </option>
                            <option value="French Southern Territories">
                              French Southern Territories
                            </option>
                            <option value="Gabon">Gabon</option>
                            <option value="Gambia">Gambia</option>
                            <option value="Georgia">Georgia</option>
                            <option value="Germany">Germany</option>
                            <option value="Ghana">Ghana</option>
                            <option value="Gibraltar">Gibraltar</option>
                            <option value="Greece">Greece</option>
                            <option value="Greenland">Greenland</option>
                            <option value="Grenada">Grenada</option>
                            <option value="Guadeloupe">Guadeloupe</option>
                            <option value="Guam">Guam</option>
                            <option value="Guatemala">Guatemala</option>
                            <option value="Guernsey">Guernsey</option>
                            <option value="Guinea">Guinea</option>
                            <option value="Guinea-bissau">Guinea-bissau</option>
                            <option value="Guyana">Guyana</option>
                            <option value="Haiti">Haiti</option>
                            <option value="Heard Island and Mcdonald Islands">
                              Heard Island and Mcdonald Islands
                            </option>
                            <option value="Holy See (Vatican City State)">
                              Holy See (Vatican City State)
                            </option>
                            <option value="Honduras">Honduras</option>
                            <option value="Hong Kong">Hong Kong</option>
                            <option value="Hungary">Hungary</option>
                            <option value="Iceland">Iceland</option>
                            <option value="India">India</option>
                            <option value="Indonesia">Indonesia</option>
                            <option value="Iran, Islamic Republic of">
                              Iran, Islamic Republic of
                            </option>
                            <option value="Iraq">Iraq</option>
                            <option value="Ireland">Ireland</option>
                            <option value="Isle of Man">Isle of Man</option>
                            <option value="Israel">Israel</option>
                            <option value="Italy">Italy</option>
                            <option value="Jamaica">Jamaica</option>
                            <option value="Japan">Japan</option>
                            <option value="Jersey">Jersey</option>
                            <option value="Jordan">Jordan</option>
                            <option value="Kazakhstan">Kazakhstan</option>
                            <option value="Kenya">Kenya</option>
                            <option value="Kiribati">Kiribati</option>
                            <option value="Korea, Democratic People's Republic of">
                              Korea, Democratic People's Republic of
                            </option>
                            <option value="Korea, Republic of">
                              Korea, Republic of
                            </option>
                            <option value="Kuwait">Kuwait</option>
                            <option value="Kyrgyzstan">Kyrgyzstan</option>
                            <option value="Lao People's Democratic Republic">
                              Lao People's Democratic Republic
                            </option>
                            <option value="Latvia">Latvia</option>
                            <option value="Lebanon">Lebanon</option>
                            <option value="Lesotho">Lesotho</option>
                            <option value="Liberia">Liberia</option>
                            <option value="Libyan Arab Jamahiriya">
                              Libyan Arab Jamahiriya
                            </option>
                            <option value="Liechtenstein">Liechtenstein</option>
                            <option value="Lithuania">Lithuania</option>
                            <option value="Luxembourg">Luxembourg</option>
                            <option value="Macao">Macao</option>
                            <option value="Macedonia, The Former Yugoslav Republic of">
                              Macedonia, The Former Yugoslav Republic of
                            </option>
                            <option value="Madagascar">Madagascar</option>
                            <option value="Malawi">Malawi</option>
                            <option value="Malaysia">Malaysia</option>
                            <option value="Maldives">Maldives</option>
                            <option value="Mali">Mali</option>
                            <option value="Malta">Malta</option>
                            <option value="Marshall Islands">
                              Marshall Islands
                            </option>
                            <option value="Martinique">Martinique</option>
                            <option value="Mauritania">Mauritania</option>
                            <option value="Mauritius">Mauritius</option>
                            <option value="Mayotte">Mayotte</option>
                            <option value="Mexico">Mexico</option>
                            <option value="Micronesia, Federated States of">
                              Micronesia, Federated States of
                            </option>
                            <option value="Moldova, Republic of">
                              Moldova, Republic of
                            </option>
                            <option value="Monaco">Monaco</option>
                            <option value="Mongolia">Mongolia</option>
                            <option value="Montenegro">Montenegro</option>
                            <option value="Montserrat">Montserrat</option>
                            <option value="Morocco">Morocco</option>
                            <option value="Mozambique">Mozambique</option>
                            <option value="Myanmar">Myanmar</option>
                            <option value="Namibia">Namibia</option>
                            <option value="Nauru">Nauru</option>
                            <option value="Nepal">Nepal</option>
                            <option selected value="Netherlands">
                              Netherlands
                            </option>
                            <option value="Netherlands Antilles">
                              Netherlands Antilles
                            </option>
                            <option value="New Caledonia">New Caledonia</option>
                            <option value="New Zealand">New Zealand</option>
                            <option value="Nicaragua">Nicaragua</option>
                            <option value="Niger">Niger</option>
                            <option value="Nigeria">Nigeria</option>
                            <option value="Niue">Niue</option>
                            <option value="Norfolk Island">
                              Norfolk Island
                            </option>
                            <option value="Northern Mariana Islands">
                              Northern Mariana Islands
                            </option>
                            <option value="Norway">Norway</option>
                            <option value="Oman">Oman</option>
                            <option value="Pakistan">Pakistan</option>
                            <option value="Palau">Palau</option>
                            <option value="Palestinian Territory, Occupied">
                              Palestinian Territory, Occupied
                            </option>
                            <option value="Panama">Panama</option>
                            <option value="Papua New Guinea">
                              Papua New Guinea
                            </option>
                            <option value="Paraguay">Paraguay</option>
                            <option value="Peru">Peru</option>
                            <option value="Philippines">Philippines</option>
                            <option value="Pitcairn">Pitcairn</option>
                            <option value="Poland">Poland</option>
                            <option value="Portugal">Portugal</option>
                            <option value="Puerto Rico">Puerto Rico</option>
                            <option value="Qatar">Qatar</option>
                            <option value="Reunion">Reunion</option>
                            <option value="Romania">Romania</option>
                            <option value="Russian Federation">
                              Russian Federation
                            </option>
                            <option value="Rwanda">Rwanda</option>
                            <option value="Saint Helena">Saint Helena</option>
                            <option value="Saint Kitts and Nevis">
                              Saint Kitts and Nevis
                            </option>
                            <option value="Saint Lucia">Saint Lucia</option>
                            <option value="Saint Pierre and Miquelon">
                              Saint Pierre and Miquelon
                            </option>
                            <option value="Saint Vincent and The Grenadines">
                              Saint Vincent and The Grenadines
                            </option>
                            <option value="Samoa">Samoa</option>
                            <option value="San Marino">San Marino</option>
                            <option value="Sao Tome and Principe">
                              Sao Tome and Principe
                            </option>
                            <option value="Saudi Arabia">Saudi Arabia</option>
                            <option value="Senegal">Senegal</option>
                            <option value="Serbia">Serbia</option>
                            <option value="Seychelles">Seychelles</option>
                            <option value="Sierra Leone">Sierra Leone</option>
                            <option value="Singapore">Singapore</option>
                            <option value="Slovakia">Slovakia</option>
                            <option value="Slovenia">Slovenia</option>
                            <option value="Solomon Islands">
                              Solomon Islands
                            </option>
                            <option value="Somalia">Somalia</option>
                            <option value="South Africa">South Africa</option>
                            <option value="South Georgia and The South Sandwich Islands">
                              South Georgia and The South Sandwich Islands
                            </option>
                            <option value="Spain">Spain</option>
                            <option value="Sri Lanka">Sri Lanka</option>
                            <option value="Sudan">Sudan</option>
                            <option value="Suriname">Suriname</option>
                            <option value="Svalbard and Jan Mayen">
                              Svalbard and Jan Mayen
                            </option>
                            <option value="Swaziland">Swaziland</option>
                            <option value="Sweden">Sweden</option>
                            <option value="Switzerland">Switzerland</option>
                            <option value="Syrian Arab Republic">
                              Syrian Arab Republic
                            </option>
                            <option value="Taiwan, Province of China">
                              Taiwan, Province of China
                            </option>
                            <option value="Tajikistan">Tajikistan</option>
                            <option value="Tanzania, United Republic of">
                              Tanzania, United Republic of
                            </option>
                            <option value="Thailand">Thailand</option>
                            <option value="Timor-leste">Timor-leste</option>
                            <option value="Togo">Togo</option>
                            <option value="Tokelau">Tokelau</option>
                            <option value="Tonga">Tonga</option>
                            <option value="Trinidad and Tobago">
                              Trinidad and Tobago
                            </option>
                            <option value="Tunisia">Tunisia</option>
                            <option value="Turkey">Turkey</option>
                            <option value="Turkmenistan">Turkmenistan</option>
                            <option value="Turks and Caicos Islands">
                              Turks and Caicos Islands
                            </option>
                            <option value="Tuvalu">Tuvalu</option>
                            <option value="Uganda">Uganda</option>
                            <option value="Ukraine">Ukraine</option>
                            <option value="United Arab Emirates">
                              United Arab Emirates
                            </option>
                            <option value="United Kingdom">
                              United Kingdom
                            </option>
                            <option value="United States">United States</option>
                            <option value="United States Minor Outlying Islands">
                              United States Minor Outlying Islands
                            </option>
                            <option value="Uruguay">Uruguay</option>
                            <option value="Uzbekistan">Uzbekistan</option>
                            <option value="Vanuatu">Vanuatu</option>
                            <option value="Venezuela">Venezuela</option>
                            <option value="Viet Nam">Viet Nam</option>
                            <option value="Virgin Islands, British">
                              Virgin Islands, British
                            </option>
                            <option value="Virgin Islands, U.S.">
                              Virgin Islands, U.S.
                            </option>
                            <option value="Wallis and Futuna">
                              Wallis and Futuna
                            </option>
                            <option value="Western Sahara">
                              Western Sahara
                            </option>
                            <option value="Yemen">Yemen</option>
                            <option value="Zambia">Zambia</option>
                            <option value="Zimbabwe">Zimbabwe</option>
                          </select>
                        </div>
                        <div class="form-group">
                          <label for="shopAddress" class="control-label">
                            ADDRESS *
                          </label>
                          <input
                            type="text"
                            onChange={(e) => setaddress(e.target.value)}
                            class="form-control"
                            id="shopAddress"
                          />
                        </div>
                        <div class="form-group">
                          <label for="shopTown" class="control-label">
                            TOWN / CITY *
                          </label>
                          <input
                            type="text"
                            class="form-control"
                            id="shopTown"
                          />
                        </div>
                        <div class="form-group">
                          <label for="shopState" class="control-label">
                            STATE / COUNTY *
                          </label>
                          <input
                            type="text"
                            class="form-control"
                            id="shopState"
                          />
                        </div>
                        <div class="form-group">
                          <label for="shopPostCode" class="control-label">
                            POSTCODE / ZIP *
                          </label>
                          <input
                            type="text"
                            onChange={(e) => setzipcode(e.target.value)}
                            class="form-control"
                            id="shopPostCode"
                          />
                        </div>
                        <div class="form-group">
                          <label for="shopPhone" class="control-label">
                            PHONE
                          </label>
                          <input
                            type="text"
                            onChange={(e) => setphoneno(e.target.value)}
                            class="form-control"
                            id="shopPhone"
                          />
                        </div>
                        <div class="form-group">
                          <label for="shopEmail" class="control-label">
                            EMAIL ADDRESS *
                          </label>
                          <input
                            type="text"
                            onChange={(e) => setEmail(e.target.value)}
                            class="form-control"
                            id="shopEmail"
                          />
                        </div>
                        <div class="form-group">
                          <label for="companyAddress" class="control-label">
                            COMPANY ADDRESS
                          </label>
                          <input
                            type="text"
                            onChange={(e) => setCompanyAddress(e.target.value)}
                            class="form-control"
                            id="companyAddress"
                          />
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md">
          <center>
            <div className={classes.root}>
              <CardContent className={classes.content}>
                <IdealBankSection />
                <div className={classes.div}>
                  <button
                    disabled={paysubmit}
                    onClick={handleSubmit}
                    style={{ fontSize: "2vh" }}
                    className="btn btn-lg btn-block mt-5 btn-primary"
                  >
                    {paysubmit ? <>Please Be Paisence ...</> : <>Pay</>}
                  </button>
                </div>
              </CardContent>
            </div>
            {products ? (
              products.length ? (
                <>
                  <h2 className="text-center font-weight-bold">Items</h2>
                  {products.map((product) => {
                    return <ProductItem product={product} />;
                  })}
                  <div class="tt-shopcart-col" style={{ marginTop: "0" }}>
                    <div class="row" style={{ justifyContent: "center" }}>
                      <div
                        class="tt-shopcart-box tt-boredr-large"
                        style={{ border: "none", minWidth: "300px" }}
                      >
                        <table class="tt-shopcart-table01">
                          <tbody>
                            <tr>
                              <th>SUBTOTAL</th>
                              <td>€{totalprice}</td>
                            </tr>
                          </tbody>
                          <tfoot>
                            <tr>
                              <th>VAT Included</th>
                              <td>€{Math.round(0.21 * totalprice)}</td>
                            </tr>

                            {discount !== 0 && (
                              <tr>
                                <th>Discount</th>
                                <td>€{discount}</td>
                              </tr>
                            )}

                            <tr>
                              <th>GRAND TOTAL</th>
                              <td>
                                €{totalprice - discount}
                              </td>
                            </tr>
                          </tfoot>
                        </table>
                        <br />
                      </div>
                    </div>
                  </div>
                </>
              ) : null
            ) : null}
          </center>
        </div>
      </div>
    </>
  );
}

export default HomePageIdeal;
