import React from "react";

import HeaderLangingPage from "../components/HeaderLangingPage";
import FooterLandingPage from "../components/FooterLandingPage";

const Shipping = () => {
  return (
    <>
      <HeaderLangingPage />
      <br />

      <div id="tt-pageContent">
        <div class="container-indent">
          <div class="container">
            <h2>Shipping methods</h2>
            <h5 style={{fontSize:"100%"}} >Shipping cost depends on the country of delivery.</h5>
           <ul>
           <li style={{fontSize:"100%",textAlign:"justify"}}>
           
           Within Europe: <strong style={{fontWeight:"700"}} >FREE</strong> delivery on all orders within
           Europe.
         </li>
         <li style={{fontSize:"100%",textAlign:"justify"}} >
           Outside Europe: <strong style={{fontWeight:"700"}} >FREE</strong> shipping on all orders over
           € 400, -
         </li>
         <li style={{fontSize:"100%",textAlign:"justify"}}>
           Outside Europe: We will charge € 35, - (euro) for shipping, if the
           order is below € 400, - 400, -
         </li>
           </ul>

            <br />
            <br />
            <br />
            {/*order Processing */}
            <h2>Order processing</h2>
            <p style={{textAlign:"justify"}}>
              Orders are processed from Monday to Friday from 9:00 AM till 6:00
              PM (CET) within 48 hours of the receipt of the order.
            </p>
            <p style={{textAlign:"justify"}}>
              Orders received on Friday till Sunday are processed on the
              following Monday.
            </p>
            <p style={{textAlign:"justify"}}>
              Orders are dispatched upon the verification of the authenticity of
              the payment received.
            </p>
            <p style={{textAlign:"justify"}}>
              Please note that all customs fees are paid by the customer, and
              are not included in the prices shown on the website.
            </p>
            <p>
              Please keep in mind that delays may occur during periods of
              seasonal sales and promotional sales.
            </p>
            {/*Delivery Time */}
            <br />
            <br />
            <br />
            <h2>Delivery Time</h2>
            <p>
              Houseofplug.com uses DHL Express and FedEx for its deliveries all
              over the world.
            </p>
            <br />
            <table style={{width:"100%"}}>
              <tr>
                <td> <span
                style={{
                  color: "black",
                  fontWeight: "700",
                  fontSize: "1.1em",
                  marginLeft: "2%",
                }}
              >
                &#8226;
              </span>{" "}</td>
                <td style={{fontSize:"100%"}} >Deliveries within Italy:</td>
                <td>1 - 2 </td>
                <td style={{fontSize:"100%"}} >working days </td>
              </tr>
              <tr>
                <td> <span
                style={{
                  color: "black",
                  fontWeight: "700",
                  fontSize: "1.1em",
                  marginLeft: "2%",
                }}
              >
                &#8226;
              </span>{" "}</td>
                <td style={{fontSize:"100%"}}>Deliveries within the European Union:</td>
                <td>2 - 3 </td>
                <td style={{fontSize:"100%"}} >working days </td>
              </tr>
              <tr>
                <td> <span
                style={{
                  color: "black",
                  fontWeight: "700",
                  fontSize: "1.1em",
                  marginLeft: "2%",
                }}
              >
                &#8226;
              </span>{" "}</td>
                <td style={{fontSize:"100%"}} >Russia:</td>
                <td>5 - 15 </td>
                <td style={{fontSize:"100%"}} >working days </td>
              </tr>
              <tr>
                <td> <span
                style={{
                  color: "black",
                  fontWeight: "700",
                  fontSize: "1.1em",
                  marginLeft: "2%",
                }}
              >
                &#8226;
              </span>{" "}</td>
                <td style={{fontSize:"100%"}} >Rest of the World:</td>
                <td>4 - 5 </td>
                <td style={{fontSize:"100%"}} >working days </td>
              </tr>
            </table>
            <p style={{textAlign:"justify"}}>
            Houseofplug.com is not responsible for delays in deliveries due to customs inspections and other unforeseen circumstances.
            </p>
            <p style={{textAlign:"justify"}}>
            Houseofplug.com also ships to <strong style={{fontWeight:"700"}}>Russia</strong> through a specialized service. Shipments can take from 5 to 15 working days depending on the city.
            </p>
           
           {/*Order Delivery */}
           <br/>
           <br/>
           <br/>
           <h2>Order Delivery</h2>
        
           
            <p style={{textAlign:"justify"}}>
            House of plug will not be held responsible for the loss of the package in case the customer instructs the courier to leave the package without signing for it in person.
             </p>
           <p style={{textAlign:"justify"}}>
           Upon delivery of the package, customers are asked to thoroughly inspect it before signing to confirm the delivery of the order. Goods become property of the customer once the package is signed for.
           </p>
          
            <p style={{textAlign:"justify"}}>
            If upon delivery, the package appears to have been tampered with, we suggest customers to either accept the delivery under reserve or to reject it.
            </p>
           
        <p style={{textAlign:"justify"}}>
        In case the customer refuses to accept the package, all return shipping costs and custom fees charged to houseofplug.com will be deducted from any refund due to the customer.
       
            
           <p style={{textAlign:"justify"}}>
           In case it is not possible to deliver the package to the address provided by the customer in the commercial contract, the package may be returned to houseofplug.com and all return shipping costs and customs fees will be deducted from any refund due to the customer.
           </p>
           
             
            </p>
          </div>
        </div>
      </div>

      <FooterLandingPage />
    </>
  );
};

export default Shipping;
