import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import Badge from "@material-ui/core/Badge";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import NotificationsIcon from "@material-ui/icons/Notifications";
import { mainListItems } from "./listitems";
import queryString from "query-string";
import axios from "axios";
import { API_SERVICE } from "../config/URI";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Snackbar from "@material-ui/core/Snackbar";
import CloseIcon from "@material-ui/icons/Close";

const drawerWidth = 240;
var productImages = [];

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: "none",
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  container: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 240,
  },
}));

export default function MainStore({ location }) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);

  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  const [products, setproducts] = React.useState([]);
  const [title, settitle] = React.useState("");
  const [price, setprice] = React.useState("");
  const [category, setcategory] = React.useState("");
  const [gender, setgender] = React.useState("");
  const [madein, setmadein] = React.useState("");
  const [description, setdescription] = React.useState("");
  const [productId, setproductId] = React.useState("");
  const [actualprice, setactualprice] = React.useState("");
  const [brandname, setbrandname] = React.useState("");
  const [title2, settitle2] = React.useState("");
  const [loading, setloading] = React.useState(true);

  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const handleClickSnackbar = () => {
    setOpenSnackbar(true);
  };
  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  React.useEffect(() => {
    const { id } = queryString.parse(location.search);
    setproductId(id);
    axios
      .get(`${API_SERVICE}/api/v1/main/getitemdetailsmainstore/${id}`)
      .then((response) => {
        setproducts(response.data);
        settitle(response.data.title);
        settitle2(response.data.title2);
        setcategory(response.data.category);
        setmadein(response.data.madein);
        setgender(response.data.gender);
        setdescription(response.data.description);
        setbrandname(response.data.product.Brand);
        setactualprice(response.data.product.ListPrice);
        setprice(Number(response.data.price));
        setloading(false);
      })
      .catch((err) => console.log(err));
  }, []);

  const refreshProducts = () => {
    axios
      .get(`${API_SERVICE}/api/v1/main/getitemdetailsmainstore/${productId}`)
      .then((response) => {
        setproducts(response.data);
        settitle(response.data.title);
        settitle2(response.data.title2);
        setprice(response.data.price);
        setcategory(response.data.category);
        setmadein(response.data.madein);
        setgender(response.data.gender);
        setdescription(response.data.description);
        setbrandname(response.data.product.Brand);
        setloading(false);
        window.location.reload();
      })
      .catch((err) => console.log(err));
  };

  const updateProductMainStore = () => {
    var uploadData = {
      title,
      title2,
      price,
      category,
      madein,
      gender,
      description,
      productId,
      brandname,
      productImages,
    };
    axios
      .post(`${API_SERVICE}/api/v1/main/updateproductmainstore`, uploadData)
      .then((response) => {
        if (response.status === 200) {
          refreshProducts();
          handleClickSnackbar();
        }
      })
      .catch((err) => console.log(err));
  };

  const deleteProductMainStore = () => {
    axios
      .get(`${API_SERVICE}/api/v1/main/deleteproductmainstore/${productId}`)
      .then((response) => {
        window.location.href = "/adminmainstore";
      })
      .catch((err) => console.log(err));
  };

  const setProductImages = (i) => {
    if (productImages.includes(i)) {
      alert(
        "You have already selected this image please choose a diffrent one"
      );
    } else {
      productImages.push(i);
    }
  };

  return (
    <div className={classes.root}>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        open={openSnackbar}
        autoHideDuration={4000}
        onClose={handleCloseSnackbar}
        message="Item Successfully Updated in Main Store"
        action={
          <React.Fragment>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={handleCloseSnackbar}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />
      <CssBaseline />
      <AppBar
        position="absolute"
        className={clsx(classes.appBar, open && classes.appBarShift)}
      >
        <Toolbar className={classes.toolbar}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            className={clsx(
              classes.menuButton,
              open && classes.menuButtonHidden
            )}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            component="h1"
            variant="h6"
            color="inherit"
            noWrap
            className={classes.title}
          >
            Dashboard
          </Typography>
          <IconButton color="inherit"></IconButton>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        classes={{
          paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
        }}
        open={open}
      >
        <div className={classes.toolbarIcon}>
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <Divider />
        <List>{mainListItems}</List>
      </Drawer>
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          {loading ? (
            <></>
          ) : (
            <center>
              {products.product.Images.map((i, index) => (
                <>
                  {index + 1}
                  <img
                    style={{
                      width: "20vh",
                      height: "28vh",
                      marginRight: "20px",
                    }}
                    src={i}
                  />
                </>
              ))}
            </center>
          )}

          <Grid className="mt-2" container spacing={3}>
            <Grid item xs={12}>
              <h4>Image Selection</h4>
            </Grid>

            {loading ? (
              <></>
            ) : (
              <>
                {products.product.Images.map((i, index1) => (
                  <>
                    <Grid item xs={3}>
                      <select
                        onChange={(e) => setProductImages(e.target.value)}
                        className="myselect"
                      >
                        <option selected>Select Position {index1 + 1}</option>
                        {products.product.Images.map((i, index) => (
                          <option value={i}>Image {index + 1}</option>
                        ))}
                      </select>
                    </Grid>
                  </>
                ))}
              </>
            )}

            <Grid item xs={12}>
              <br />
            </Grid>

            <Grid item xs={6}>
              <TextField
                value={title}
                onChange={(e) => settitle(e.target.value)}
                id="outlined-basic"
                fullWidth
                label="Title"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                value={price}
                onChange={(e) => setprice(e.target.value)}
                id="outlined-basic"
                fullWidth
                label="Price"
                variant="outlined"
              />
              <p>21% VAT Inc. in Actual Price € {actualprice}</p>
            </Grid>
            <Grid item xs={12}>
              <TextField
                value={brandname}
                onChange={(e) => setbrandname(e.target.value)}
                id="outlined-basic"
                fullWidth
                label="Brand"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                value={title2}
                onChange={(e) => settitle2(e.target.value)}
                id="outlined-basic"
                fullWidth
                label="Title 2"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                value={category}
                onChange={(e) => setcategory(e.target.value)}
                id="outlined-basic"
                fullWidth
                label="Category"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                value={gender}
                onChange={(e) => setgender(e.target.value)}
                id="outlined-basic"
                fullWidth
                label="Gender"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                value={madein}
                onChange={(e) => setmadein(e.target.value)}
                id="outlined-basic"
                fullWidth
                label="Made In"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="outlined-multiline-static"
                label="Description"
                multiline
                rows={8}
                value={description}
                variant="outlined"
                fullWidth
                onChange={(e) => setdescription(e.target.value)}
              />
            </Grid>
          </Grid>

          <Button
            onClick={updateProductMainStore}
            className="mt-2"
            fullWidth
            size="large"
            variant="contained"
            color="primary"
          >
            Update
          </Button>
          <Button
            onClick={deleteProductMainStore}
            className="mt-2"
            fullWidth
            size="large"
            variant="outlined"
            color="secondary"
          >
            Delete Product
          </Button>
        </Container>
      </main>
    </div>
  );
}
