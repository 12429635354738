import React from 'react';
import axios from 'axios';
import {Link} from 'react-scroll';
// Components
import HeaderLangingPage from '../components/HeaderLangingPage';
import FooterLandingPage from '../components/FooterLandingPage';

import CircularProgress from '@material-ui/core/CircularProgress';
// Extras
import { API_SERVICE } from '../config/URI';


var tempChar = "";

const AlphabetList = ({ a }) => {
    return (
        <>
            <Link to={a} spy={true} smooth={true} className="alphabetsdesigners" href="#!">
                {a}
            </Link>
        </>
    )
}

const DesignersList = ({ a, tempChar }) => {

    return (
        <>
            <br />
            <span id={a.charAt(0)} className="brandname">
                <a href={`/products?p=bags&t=all&b=${a}`} className="text-dark">
                    {a}
                </a>
            </span>
            <br />
            <hr />
            {/* {
                a.charAt(0) === tempChar ? (
                    <>
                        {a.charAt(0)}
                    </>
                ) : null
            } */}
        </>
    )
}


const About = () => {

    const [designers, setdesigners] = React.useState([]);
    const [designersname, setdesignersname] = React.useState([]);
    const [designersalphabet, setdesignersalphabet] = React.useState([]);
    const [loading, setloading] = React.useState(true);
  
    const getDesigners = () => {
        var tempArr = [];
        var tempArr2 = [];
        axios.get(`${API_SERVICE}/api/v1/main/getdesigners`)
            .then(response => {
                console.log(response);
                setdesigners(response.data);
                (response.data).map((b) => {
                    tempArr.push(b.product.Brand);
                })

                tempArr = getBrands(tempArr);

                setdesignersname(tempArr.sort());


                tempArr.map((b) => {
                    var str = b.charAt(0);
                    tempArr2.push(str);
                })

                tempArr2 = getBrands(tempArr2);
                setdesignersalphabet(tempArr2);
               
                setloading(false);
            })
            .catch(err => console.log(err))
    }
    React.useEffect(() => {
        getDesigners();
    }, []);

    const getBrands = (allData) => {
        var t = [];
        allData.map((d) => {
            if (t.indexOf(d) === -1) {
                t.push(d);
            }
        })
        return t.sort();
    }



    const showDesignersAlphabet = () => {
        return designersalphabet.map(a => {
            return <AlphabetList a={a} key={Math.random()} />
        })
    }

    

    const showDesignersDesigners = () => {
        var alphabet = "";
        var alphabetC = 0;
        return designersname.map(a => {
            tempChar = designersalphabet[alphabetC];
            if (a.charAt(0) !== tempChar) {
                alphabetC = alphabetC + 1;
            }
            return <DesignersList a={a} alphabetC={alphabetC} tempChar={tempChar} alphabet={alphabet} key={Math.random()} />
        })
    }

    return (
        <>
            <HeaderLangingPage />         
            <br />

            <div id="tt-pageContent">
                <div className="container-indent">
                    <h2 className="text-center mt-2">
                    DESIGNERS
                    </h2>
             
                {loading?  <center>
                  <CircularProgress/>
                 
                  </center>:null}
                    <div className="container container-fluid-custom-mobile-padding">
                        {
                           
                                <>
                                    {showDesignersAlphabet()}
                                </>
                            
                        }
                        <br />
                        <br />
                        <div className="col-md mt-5">
                            <div className="tt-post-single">
                                <div className="tt-post-content">
                                    {
                                        loading ? (
                                            <>
                                            </>
                                        ) : (
                                            <>
                                                {showDesignersDesigners()}
                                            </>
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                        

                        

                    </div>
                </div>
            </div>

            <FooterLandingPage />
        </>
    )
}

export default About
