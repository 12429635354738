import React from "react";
import { MobileView, isMobile, isBrowser } from "react-device-detect";
import "./FooterLandingPage.css"
const FooterLandingPage = () => {
  return (
    <>
      <footer id="tt-footer">
        <div class="tt-footer-default tt-color-scheme-02">
          <div class="container">
            <div class="row">
              <div class="col-12 col-md-9">
                <div class="tt-newsletter-layout-01">
                  <div class="tt-newsletter">
                    <div class="tt-mobile-collapse">
                      <h4
                        style={{
                          fontFamily: "'Open Sans', sans-serif",
                          fontSize: "17px",
                        }}
                        class="tt-collapse-title"
                      >
                        BE IN TOUCH WITH US:
                      </h4>
                      <div style={{ marginTop: '12px' }} class="tt-collapse-content">
                        <div className="form-group">
                          <input
                            type="text"
                            name="email"
                            class="form-control"
                            placeholder="Enter your e-mail"
                          />
                          <button type="submit" className={isMobile ? "btn w-100 mt-2" : "btn"}>
                            JOIN US
                          </button>
                        </div>
                          
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div hidden={isMobile} class="col-md-auto">
                <center>
                
                  <ul class="tt-social-icon">
                      <li>
                      <h4
                        style={{
                          fontFamily: "'Open Sans', sans-serif",
                          fontSize: "17px",
                        }}
                        class="tt-collapse-title"
                      >
                        FOLLOW US:
                      </h4>
                      </li>
                    <li>
                      <center>
                        <a
                          class="icon-g-64"
                          target="_blank"
                          href="https://www.facebook.com/houseofplug.official/" rel="noreferrer"
                        ></a>
                      </center>
                    </li>
                    <li>
                      <center>
                        <a
                          class="icon-g-67"
                          target="_blank"
                          href="https://www.instagram.com/houseofplugofficial/" rel="noreferrer"
                        ></a>
                      </center>
                    </li>
                  </ul>
                </center>
              </div>
            </div>
          </div>
        </div>
        <div hidden={isBrowser} class="tt-footer-default tt-color-scheme-02">
          <div class="container">
            <div class="row">
              <div class="col-12 col-md-9">
                <div class="tt-newsletter-layout-01">
                  <div class="tt-newsletter">
                    <div class="tt-mobile-collapse">
                      <h4
                        style={{
                          fontFamily: "'Open Sans', sans-serif",
                          fontSize: "17px",
                          textTransform: "uppercase",
                        }}
                        class="tt-collapse-title"
                      >
                        Payment methods
                      </h4>
                      <div class="tt-collapse-content">
                        <div className="row">
                          <div className="col-md">
                            <img
                              style={{ width: "100px" }}
                              src="https://upload.wikimedia.org/wikipedia/commons/thumb/b/ba/Stripe_Logo%2C_revised_2016.svg/2560px-Stripe_Logo%2C_revised_2016.svg.png"
                            />
                          </div>
                          <div className="col-md">
                            <img
                              style={{ width: "70px", marginLeft: "20px" }}
                              src="https://image.flaticon.com/icons/png/512/217/217429.png"
                            />
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-md">
                          <img
                            style={{ width: "100px", marginLeft: "20px" }}
                            src="https://cdn.iconscout.com/icon/free/png-512/paypal-54-675727.png"
                          />
                          </div>
                          <div className="col-md">
                          <img
                            style={{ width: "100px" }}
                            src="https://cdn4.iconfinder.com/data/icons/flat-brand-logo-2/512/visa-512.png"
                          />
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-md">
                          <img
                            style={{ width: "100px", marginLeft: "20px" }}
                            src="https://laughingsquidhosting.files.wordpress.com/2019/03/discover-logo.jpg"
                          />
                          </div>
                          <div className="col-md">
                          <img
                            style={{ width: "64px", marginLeft: "20px" }}
                            src="https://upload.wikimedia.org/wikipedia/commons/thumb/f/fa/American_Express_logo_%282018%29.svg/1200px-American_Express_logo_%282018%29.svg.png"
                          />
                          </div>
                        </div>
                       
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="tt-footer-col tt-color-scheme-01">
          <div class="container">
            <div class="row">
              <div class="col-md-6 col-lg-2 col-xl-3">
                <div class="tt-mobile-collapse">
                  <h4
                    style={{
                      fontFamily: "'Open Sans', sans-serif",
                      fontSize: "17px",
                    }}
                    class="tt-collapse-title"
                  >
                    HOUSE OF PLUG
                  </h4>
                  <div className="tt-collapse-content footer-section">
                    <ul class="tt-list">
                      <li>
                        <a href="/about">About Us</a>
                      </li>
                      <li>
                        <a href="/newin?p=bags&t=all">New In</a>
                      </li>
                      <li>
                        <a href="/faq">FAQs</a>
                      </li>
                      <li>
                        <a href="/contact">Contact us</a>
                      </li>
                      <li>
                        <a href="/sale?p=bags&t=all">Sale</a>
                      </li>
                    </ul>
                    <ul class="tt-list">
                      <li>
                        <a href="/paymentdocs">Payments</a>
                      </li>
                      <li>
                        <a href="/return">Returns</a>
                      </li>
                      <li>
                        <a href="/privacy">Privacy Policy</a>
                      </li>
                      <li>
                        <a href="/cookie">Cookie Policy</a>
                      </li>
                      <li>
                        <a href="/shipping">Shipping</a>
                      </li>
                      <li>
                        <a href="/terms">Terms & Conditions</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="col-md-6 col-lg-4 col-xl-3">
                <div class="tt-mobile-collapse">
                  <h4
                    style={{
                      fontFamily: "'Open Sans', sans-serif",
                      fontSize: "17px",
                    }}
                    class="tt-collapse-title"
                  >
                    ABOUT
                  </h4>
                  <div class="tt-collapse-content">
                    <p style={{ fontSize: "14px" }}>
                    House of Plug is a high-end online fashion platform based in the Dutch “flower capital” - Haarlem. Our mission is to provide the most unique pieces within a secure shopping platform, and we’ve just started our journey in 2022.                      <a href="/about" className="text-primary">
                        Read more...
                      </a>
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-md-6 col-lg-4 col-xl-3">
                <div class="tt-mobile-collapse">
                  <h4
                    style={{
                      fontFamily: "'Open Sans', sans-serif",
                      fontSize: "17px",
                    }}
                    class="tt-collapse-title"
                  >
                    CONTACTS
                  </h4>
                  <div class="tt-collapse-content">
                    <p style={{ fontSize: "14px" }}>
                      {/* WhatsApp: +31 6 585626288 <br /> */}
                      E-mail:{" "}
                      <a
                        style={{ color: "#4d82fe" }}
                        href="mailto:info@houseofplug.com"
                      >
                        info@houseofplug.com
                      </a><br />
                      Hours: 7 Days a week from 10 am to 6 pm (CET) 
                      
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-md-6 col-lg-4 col-xl-3">
                <div class="tt-mobile-collapse">
                  <h6
                    style={{
                      fontFamily: "'Open Sans', sans-serif",
                      fontSize: "17px",
                    }}
                    class="tt-collapse-title"
                  >
                    COMPANY INFORMATION
                  </h6>
                  <div class="tt-collapse-content">
                    <p style={{ fontSize: "14px" }}>
                      KVK: 82135401 <br />
                      VAT: NL003652353B62
                      <br />
                      <a style={{ color: "#777777" }} href="/disclaimer">
                        Disclaimer
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="tt-footer-custom">
          <div class="container">
            <div class="tt-row">
              <center hidden={isBrowser}>
                {/* HERE */}
              </center>
              <div hidden={isMobile} class="tt-col-left">
                <div class="tt-col-item tt-logo-col">
                  <a class="tt-logo tt-logo-alignment" href="/">
                    <img
                      style={{ width: "60px" }}
                      src="https://res.cloudinary.com/dx9dnqzaj/image/upload/v1620729982/ecommerse2/Bottom_footer_logo_-_transparent_backround_jzfate.png"
                      data-src="https://res.cloudinary.com/dx9dnqzaj/image/upload/v1620729982/ecommerse2/Bottom_footer_logo_-_transparent_backround_jzfate.png"
                      alt=""
                    />
                  </a>
                </div>
                <div class="tt-col-item">
                  <div style={{ fontSize: "14px" }} class="tt-box-copyright">
                    &copy; House of Plug 2022. All Rights Reserved
                  </div>
                </div>
              </div>
              <div class="tt-col-right">
                <div hidden={isMobile} class="tt-col-item mr-5">
                  <ul class="tt-payment-list">
                    <li>
                      <a href="https://www.dhl.com/">
                        <span style={{ fontSize: "14px" }} className="mr-2">
                          Shipment by:
                        </span>
                        <img
                          style={{ width: "30px" }}
                          src="https://res.cloudinary.com/dx9dnqzaj/image/upload/v1622446711/ecommerse2/dhl_logo_met_gele_achtergrond_xb6uol.png"
                        />
                      </a>
                    </li>
                  </ul>
                </div>

                <div hidden={isMobile} class="tt-col-item">
                  <ul class="tt-payment-list">
                    <li>
                      <a href="#!">
                        <span class="icon-Stripe">
                          <span class="path1"></span>
                          <span class="path2"></span>
                          <span class="path3"></span>
                          <span class="path4"></span>
                          <span class="path5"></span>
                          <span class="path6"></span>
                          <span class="path7"></span>
                          <span class="path8"></span>
                          <span class="path9"></span>
                          <span class="path10"></span>
                          <span class="path11"></span>
                          <span class="path12"></span>
                        </span>
                      </a>
                    </li>

                    <li>
                      <a href="#!">
                        <img
                          style={{ width: "30px" }}
                          src="https://res.cloudinary.com/dx9dnqzaj/image/upload/v1622012634/ecommerse2/ideal-logo_fpz97w.png"
                        />
                      </a>
                    </li>

                    <li>
                      <a href="#!">
                        {" "}
                        <span class="icon-paypal-2">
                          <span class="path1"></span>
                          <span class="path2"></span>
                          <span class="path3"></span>
                          <span class="path4"></span>
                          <span class="path5"></span>
                          <span class="path6"></span>
                        </span>
                      </a>
                    </li>
                    <li>
                      <a href="#!">
                        <span class="icon-visa">
                          <span class="path1"></span>
                          <span class="path2"></span>
                          <span class="path3"></span>
                          <span class="path4"></span>
                          <span class="path5"></span>
                        </span>
                      </a>
                    </li>
                    <li>
                      <a href="#!">
                        <span class="icon-mastercard">
                          <span class="path1"></span>
                          <span class="path2"></span>
                          <span class="path3"></span>
                          <span class="path4"></span>
                          <span class="path5"></span>
                          <span class="path6"></span>
                          <span class="path7"></span>
                          <span class="path8"></span>
                          <span class="path9"></span>
                          <span class="path10"></span>
                          <span class="path11"></span>
                          <span class="path12"></span>
                          <span class="path13"></span>
                        </span>
                      </a>
                    </li>
                    <li>
                      <a href="#!">
                        <span class="icon-discover">
                          <span class="path1"></span>
                          <span class="path2"></span>
                          <span class="path3"></span>
                          <span class="path4"></span>
                          <span class="path5"></span>
                          <span class="path6"></span>
                          <span class="path7"></span>
                          <span class="path8"></span>
                          <span class="path9"></span>
                          <span class="path10"></span>
                          <span class="path11"></span>
                          <span class="path12"></span>
                          <span class="path13"></span>
                          <span class="path14"></span>
                          <span class="path15"></span>
                          <span class="path16"></span>
                        </span>
                      </a>
                    </li>
                    <li>
                      <a href="#!">
                        <span class="icon-american-express">
                          <span class="path1"></span>
                          <span class="path2"></span>
                          <span class="path3"></span>
                          <span class="path4"></span>
                          <span class="path5"></span>
                          <span class="path6"></span>
                          <span class="path7"></span>
                          <span class="path8"></span>
                          <span class="path9"></span>
                          <span class="path10"></span>
                          <span class="path11"></span>
                        </span>
                      </a>
                    </li>
                  </ul>
                </div>

                <div hidden={isBrowser} class="tt-col-left mt-5">
                  <div class="tt-col-item tt-logo-col">
                    <a class="tt-logo tt-logo-alignment" href="/">
                      <img
                        style={{ width: "100px" }}
                        src="https://cdn.discordapp.com/attachments/833698346148691980/861585765824856084/House_of_plug_transparent_1500x1500kopie.png"
                        alt=""
                      />
                    </a>
                  </div>
                  <ul class="tt-social-icon">
                    <li>
                      <center>
                        <a
                          class="icon-g-64"
                          target="_blank"
                          href="https://www.facebook.com/houseofplug.official/" rel="noreferrer"
                        ></a>
                      </center>
                    </li>
                    <li>
                      <center>
                        <a
                          class="icon-g-67"
                          target="_blank"
                          href="https://www.instagram.com/houseofplugofficial/" rel="noreferrer"
                        ></a>
                      </center>
                    </li>
                  </ul>
                  &copy; House of Plug 2021. All Rights Reserved
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default FooterLandingPage;
