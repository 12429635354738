import React, { useState } from "react";
import { firestore, auth } from "../Firebase/index";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from "react-device-detect";
const HeaderLangingPage = ({ video }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  auth.onAuthStateChanged((user) => {
    if (user) {
      setIsLoggedIn(true);
      // console.log("User Present");
    } else {
      setIsLoggedIn(false);
      // console.log("No User");
    }
  });
  const logOut = () => {
    auth
      .signOut()
      .then(() => {
        setIsLoggedIn(false);
      })
      .catch((err) => {
        alert(err.message);
      });
  };
  const [totalItems, setTotalItems] = React.useState(0);

  const [videoset, setvideoset] = React.useState(0);

  React.useEffect(() => {
    if (video) {
      setvideoset(1);
    }
  }, []);

  React.useEffect(() => {
    if (localStorage.getItem("userId") === null) {
    } else {
      var userId = localStorage.getItem("userId");
      firestore
        .collection("cart")
        .doc(userId)
        .onSnapshot(function (doc) {
          if (typeof doc.data() == "undefined") {
            setTotalItems(0);
          } else {
            setTotalItems(doc.data().items);
          }
        });
    }
  }, []);

  return (
    <>
      <header id="tt-header">
        {/* <div style={{
                         maxWidth:"1200px",
                         padding: "5px 10px",
                         margin : "0 auto",
                     }}>   
                        <div>
                        <div id="google_translate_element"></div>
                        </div>
                    </div> */}
        <nav class="panel-menu mobile-main-menu" style={{ display: "none" }}>
          <div class="mmpanels">
            <div class="mmpanel mmopened mmcurrent" id="mm0">
              <ul>
                <li class="mm-close-parent">
                  {/* <a href="#close" data-target="#close" class="mm-close">Close</a> */}
                </li>
                <li id="entrypoint-objects-top"></li>
                <li>
                  <a href="/">HOME</a>
                </li>
                <li>
                  <a href="/newin?p=bags&t=all">NEW IN</a>
                </li>
                <li>
                  <a href="/designers">DESIGNERS</a>
                </li>
                <li>
                  <a href="#mm1" data-target="#mm1" class="mm-next-level">
                    BAGS
                  </a>
                </li>
                <li>
                  <a href="#mm2" data-target="#mm2" class="mm-next-level">
                    ACCESSORIES
                  </a>
                </li>
                <li>
                  <a href="/sale?p=bags&t=all">SALE</a>
                </li>
                <li>
                  <a href="/about">ABOUT US</a>
                </li>

                <li id="entrypoint-objects"></li>
              </ul>
            </div>
            <div class="mmpanel mmhidden" id="mm1">
              <ul>
                <li>
                  {/* <a href="#" data-target="#" class="mm-prev-level">Back</a> */}
                </li>
                <li>
                  <a href="/products?p=bags&t=all" class="mm-original-link">
                    ALL BAGS
                  </a>
                </li>

                <li>
                  <a
                    class="mm-original-link"
                    href="/products?p=bags&t=BACKPACK"
                  >
                    BACKPACK
                  </a>
                </li>
                <li>
                  <a
                    class="mm-original-link"
                    href="/products?p=bags&t=BEAUTY CASE"
                  >
                    BEAUTY CASE
                  </a>
                </li>
                <li>
                  <a
                    class="mm-original-link"
                    href="/products?p=bags&t=BELT BAG"
                  >
                    BELT BAG
                  </a>
                </li>
                <li>
                  <a
                    class="mm-original-link"
                    href="/products?p=bags&t=BRIEFCASE"
                  >
                    BRIEFCASE
                  </a>
                </li>
                <li>
                  <a class="mm-original-link" href="/products?p=bags&t=CLUTCH">
                    CLUTCH
                  </a>
                </li>
                <li>
                  <a class="mm-original-link" href="/products?p=bags&t=HANDBAG">
                    HANDBAG
                  </a>
                </li>
                <li>
                  <a
                    class="mm-original-link"
                    href="/products?p=bags&t=MESSENGER BAG"
                  >
                    MESSENGER BAG
                  </a>
                </li>
                <li>
                  <a class="mm-original-link" href="/products?p=bags&t=POUCH">
                    POUCH
                  </a>
                </li>
                <li>
                  <a
                    class="mm-original-link"
                    href="/products?p=bags&t=SHOULDER BAG"
                  >
                    SHOULDER BAG
                  </a>
                </li>
                <li>
                  <a class="mm-original-link" href="/products?p=bags&t=TOTE">
                    TOTE
                  </a>
                </li>
                <li>
                  <a
                    class="mm-original-link"
                    href="/products?p=bags&t=TRAVEL BAG"
                  >
                    TRAVEL BAG
                  </a>
                </li>
                <li>
                  <a class="mm-original-link" href="/products?p=bags&t=TROLLEY">
                    TROLLEY
                  </a>
                </li>
              </ul>
            </div>

            <div class="mmpanel mmhidden" id="mm2">
              <ul>
                <li>
                  {/* <a href="#" data-target="#" class="mm-prev-level">Back</a> */}
                </li>
                <li>
                  <a
                    href="/products?p=accessories&t=all"
                    class="mm-original-link"
                  >
                    ALL ACCESSORIES
                  </a>
                </li>

                <li>
                  <a
                    class="mm-original-link"
                    href="/products?p=accessories&t=EARRINGS"
                  >
                    EARRINGS
                  </a>
                </li>
                <li>
                  <a
                    class="mm-original-link"
                    href="/products?p=accessories&t=FOULARD"
                  >
                    FOULARD
                  </a>
                </li>
                <li>
                  <a
                    class="mm-original-link"
                    href="/products?p=accessories&t=FRAGRANCE"
                  >
                    FRAGRANCE
                  </a>
                </li>
                <li>
                  <a
                    class="mm-original-link"
                    href="/products?p=accessories&t=TIE"
                  >
                    TIE
                  </a>
                </li>
                <li>
                  <a
                    class="mm-original-link"
                    href="/products?p=accessories&t=TIE CLIP"
                  >
                    TIE CLIP
                  </a>
                </li>
                <li>
                  <a
                    class="mm-original-link"
                    href="/products?p=accessories&t=UMBRELLA"
                  >
                    UMBRELLA
                  </a>
                </li>
                <li>
                  <a
                    class="mm-original-link"
                    href="/products?p=accessories&t=WALLET"
                  >
                    WALLET
                  </a>
                </li>
                <li>
                  <a
                    class="mm-original-link"
                    href="/products?p=accessories&t=WATCH"
                  >
                    WATCH
                  </a>
                </li>
                <li>
                  <a
                    class="mm-original-link"
                    href="/products?p=accessories&t=GLASSES"
                  >
                    GLASSES
                  </a>
                </li>
                <li>
                  <a
                    class="mm-original-link"
                    href="/products?p=accessories&t=GLOVES"
                  >
                    GLOVES
                  </a>
                </li>
                <li>
                  <a
                    class="mm-original-link"
                    href="/products?p=accessories&t=HAIR CLIP"
                  >
                    HAIR CLIP
                  </a>
                </li>
                <li>
                  <a
                    class="mm-original-link"
                    href="/products?p=accessories&t=DOCUMENT HOLDER"
                  >
                    DOCUMENT HOLDER
                  </a>
                </li>

                <li>
                  <a
                    class="mm-original-link"
                    href="/products?p=accessories&t=HAT"
                  >
                    HAT
                  </a>
                </li>
                <li>
                  <a
                    class="mm-original-link"
                    href="/products?p=accessories&t=HEADBAND"
                  >
                    HEADBAND
                  </a>
                </li>
                <li>
                  <a
                    class="mm-original-link"
                    href="/products?p=accessories&t=HIGH TECH"
                  >
                    HIGH TECH
                  </a>
                </li>
                <li>
                  <a
                    class="mm-original-link"
                    href="/products?p=accessories&t=KEY CHAIN"
                  >
                    KEY CHAIN
                  </a>
                </li>
                <li>
                  <a
                    class="mm-original-link"
                    href="/products?p=accessories&t=MASK"
                  >
                    MASK
                  </a>
                </li>
                <li>
                  <a
                    class="mm-original-link"
                    href="/products?p=accessories&t=NECKLACE"
                  >
                    NECKLACE
                  </a>
                </li>
                <li>
                  <a
                    class="mm-original-link"
                    href="/products?p=accessories&t=PIN"
                  >
                    PIN
                  </a>
                </li>
                <li>
                  <a
                    class="mm-original-link"
                    href="/products?p=accessories&t=RING"
                  >
                    RING
                  </a>
                </li>
                <li>
                  <a
                    class="mm-original-link"
                    href="/products?p=accessories&t=SCARF"
                  >
                    SCARF
                  </a>
                </li>
                <li>
                  <a
                    class="mm-original-link"
                    href="/products?p=accessories&t=SHAWL"
                  >
                    SHAWL
                  </a>
                </li>
                <li>
                  <a
                    class="mm-original-link"
                    href="/products?p=accessories&t=SHOE LACE"
                  >
                    SHOE LACE
                  </a>
                </li>
                <li>
                  <a
                    class="mm-original-link"
                    href="/products?p=accessories&t=SHOULDER STRAP"
                  >
                    SHOULDER STRAP
                  </a>
                </li>

                <li>
                  <a
                    class="mm-original-link"
                    href="/products?p=accessories&t=BELT"
                  >
                    BELT
                  </a>
                </li>
                <li>
                  <a
                    class="mm-original-link"
                    href="/products?p=accessories&t=BOW TIE"
                  >
                    BOW TIE
                  </a>
                </li>
                <li>
                  <a
                    class="mm-original-link"
                    href="/products?p=accessories&t=BRACELET"
                  >
                    BRACELET
                  </a>
                </li>
                <li>
                  <a
                    class="mm-original-link"
                    href="/products?p=accessories&t=BRACES"
                  >
                    BRACES
                  </a>
                </li>
                <li>
                  <a
                    class="mm-original-link"
                    href="/products?p=accessories&t=CANDLE"
                  >
                    CANDLE
                  </a>
                </li>
                <li>
                  <a
                    class="mm-original-link"
                    href="/products?p=accessories&t=CARD HOLDER"
                  >
                    CARD HOLDER
                  </a>
                </li>
                <li>
                  <a
                    class="mm-original-link"
                    href="/products?p=accessories&t=CASE"
                  >
                    CASE
                  </a>
                </li>
                <li>
                  <a
                    class="mm-original-link"
                    href="/products?p=accessories&t=COVER"
                  >
                    COVER
                  </a>
                </li>
                <li>
                  <a
                    class="mm-original-link"
                    href="/products?p=accessories&t=CUFF LINKS"
                  >
                    CUFF LINKS
                  </a>
                </li>
                <li>
                  <a
                    class="mm-original-link"
                    href="/products?p=accessories&t=SOCKS"
                  >
                    SOCKS
                  </a>
                </li>
                <li>
                  <a
                    class="mm-original-link"
                    href="/products?p=accessories&t=STICKER"
                  >
                    STICKER
                  </a>
                </li>
                <li>
                  <a
                    class="mm-original-link"
                    href="/products?p=accessories&t=SUNGLASSES"
                  >
                    SUNGLASSES
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </nav>

        <div class="tt-mobile-header">
          <div class="container-fluid">
            <div class="tt-header-row">
              <div class="tt-mobile-parent-menu">
                <div
                  class="tt-menu-toggle"
                  id="js-menu-toggle"
                  style={{ display: "inline-block" }}
                >
                  <i class="icon-03"></i>
                </div>
              </div>
              <div class="tt-mobile-parent-search tt-parent-box">
                <div class="tt-search tt-dropdown-obj">
                  <button
                    hidden
                    class="tt-dropdown-toggle"
                    data-tooltip="Search"
                    data-tposition="bottom"
                  >
                    <i class="icon-f-85"></i>
                  </button>
                  <MobileView>
                    <h4 className="mt-2">
                      <a className="specialfont" href="/">
                        HOUSE OF PLUG
                      </a>
                    </h4>
                  </MobileView>
                  <div class="tt-dropdown-menu">
                    <div class="container">
                      <form>
                        <div class="tt-col">
                          <input
                            type="text"
                            class="tt-search-input"
                            placeholder="Search Products..."
                          />
                          <button class="tt-btn-search" type="submit"></button>
                        </div>
                        <div class="tt-col">
                          <button class="tt-btn-close icon-g-80"></button>
                        </div>
                        <div class="tt-info-text">
                          What are you Looking for?
                        </div>
                        <div class="search-results tt-is-include">
                          <ul>
                            <li>
                              <a href="product.html">
                                <div class="thumbnail">
                                  <img
                                    src="images/loader.svg"
                                    data-src="images/skin-clothes/product/product-03.jpg"
                                    alt=""
                                  />
                                </div>
                                <div class="tt-description">
                                  <div class="tt-title">Flared Shift Bag</div>
                                  <div class="tt-price">
                                    <span class="new-price">$14</span>
                                    <span class="old-price">$24</span>
                                  </div>
                                </div>
                              </a>
                            </li>
                            <li>
                              <a href="product.html">
                                <div class="thumbnail">
                                  <img
                                    src="images/loader.svg"
                                    data-src="images/skin-clothes/product/product-02.jpg"
                                    alt=""
                                  />
                                </div>
                                <div class="tt-description">
                                  <div class="tt-title">Flared Shift Bag</div>
                                  <div class="tt-price">$24</div>
                                </div>
                              </a>
                            </li>
                            <li>
                              <a href="product.html">
                                <div class="thumbnail">
                                  <img
                                    src="images/loader.svg"
                                    data-src="images/skin-clothes/product/product-01.jpg"
                                    alt=""
                                  />
                                </div>
                                <div class="tt-description">
                                  <div class="tt-title">Flared Shift Bag</div>
                                  <div class="tt-price">$14</div>
                                </div>
                              </a>
                            </li>
                            <li>
                              <a href="product.html">
                                <div class="thumbnail">
                                  <img
                                    src="images/loader.svg"
                                    data-src="images/skin-clothes/product/product-04.jpg"
                                    alt=""
                                  />
                                </div>
                                <div class="tt-description">
                                  <div class="tt-title">Flared Shift Bag</div>
                                  <div class="tt-price">$24</div>
                                </div>
                              </a>
                            </li>
                            <li>
                              <a href="product.html">
                                <div class="thumbnail">
                                  <img
                                    src="images/loader.svg"
                                    data-src="images/skin-clothes/product/product-05.jpg"
                                    alt=""
                                  />
                                </div>
                                <div class="tt-description">
                                  <div class="tt-title">Flared Shift Bag</div>
                                  <div class="tt-price">$17</div>
                                </div>
                              </a>
                            </li>
                          </ul>
                          <button type="button" class="tt-view-all">
                            View all products
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
              <div class="tt-mobile-parent-cart tt-parent-box">
                <div
                  class="tt-cart tt-dropdown-obj"
                  data-tooltip="Cart"
                  data-tposition="bottom"
                >
                  <button
                    onClick={() => (window.location.href = "/cart")}
                    class="tt-dropdown-toggle"
                  >
                    <i class="icon-f-39"></i>
                    <span class="tt-badge-cart">{totalItems}</span>
                  </button>
                </div>
              </div>
              <div class="tt-mobile-parent-account tt-parent-box">
                <div class="tt-account tt-dropdown-obj">
                  {/* <button onClick={() => window.location.href = "/wishlist"} class="tt-dropdown-toggle" data-tooltip="Wish List" data-tposition="bottom"><i class="icon-n-072"></i></button> */}
                  <button
                    class="tt-dropdown-toggle"
                    data-tooltip="My Account"
                    data-tposition="bottom"
                  >
                    <i class="icon-f-94"></i>
                  </button>
                  <div class="tt-dropdown-menu">
                    <div class="tt-mobile-add">
                      <button class="tt-close">Close</button>
                    </div>
                    <div class="tt-dropdown-inner">
                      <ul>
                        {isLoggedIn && (
                          <li>
                            <a href="/wishlist">
                              <i class="icon-n-072"></i>Wishlist
                            </a>
                          </li>
                        )}
                        {isLoggedIn && (
                          <li>
                            <a href="/cart">
                              <i class="icon-f-68"></i>Check Out
                            </a>
                          </li>
                        )}
                        {!isLoggedIn && (
                          <li>
                            <a href="/signin">
                              <i class="icon-f-76"></i>Sign In
                            </a>
                          </li>
                        )}
                        {isLoggedIn && (
                          <li>
                            <a href="/home" onClick={logOut}>
                              <i class="icon-f-77"></i>Sign Out
                            </a>
                          </li>
                        )}
                        {!isLoggedIn && (
                          <li>
                            <a href="/register">
                              <i class="icon-f-94"></i>Register
                            </a>
                          </li>
                        )}
                        <li>
                          <a href="/contact">
                            <i class="icon-f-68"></i> Contact
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div class="container-fluid tt-top-line">
                        <div class="row">
                            <div class="tt-logo-container">
                                <a class="tt-logo tt-logo-alignment" href="index.html">
                                    <img src="https://res.cloudinary.com/dx9dnqzaj/image/upload/v1618398256/ecommerse2/House_of_plug_titel_logo_-_png_format_with_transparent_backround_opz2xh.png" alt="" class="loading" data-was-processed="true" />
                                </a>
                            </div>
                        </div>
                    </div> */}
        </div>
        <div class="tt-desktop-header">
          <div class="container">
            <div class="tt-header-holder">
              <div class="tt-obj-logo obj-aligment-center">
                <a class="tt-logo tt-logo-alignment" href="/">
                  <img
                    src="https://res.cloudinary.com/dx9dnqzaj/image/upload/v1618398256/ecommerse2/House_of_plug_titel_logo_-_png_format_with_transparent_backround_opz2xh.png"
                    alt=""
                    class="loading"
                    data-was-processed="true"
                  />
                </a>
              </div>
              <div class="tt-obj-options obj-move-right tt-position-absolute">
                <div class="tt-desctop-parent-search tt-parent-box"></div>
                <div class="tt-desctop-parent-cart tt-parent-box"></div>
                <div class="tt-desctop-parent-account tt-parent-box"></div>
              </div>
            </div>
          </div>
          <div class="container">
            <div class="tt-header-holder">
              <div class="tt-obj-menu obj-aligment-center">
                <div class="tt-desctop-parent-menu tt-parent-box">
                  <div class="tt-desctop-menu tt-menu-small">
                    <nav>
                      <ul>
                        <li class="dropdown">
                          <a href="/newin?p=bags&t=all">NEW IN</a>
                        </li>
                        <li class="dropdown">
                          <a href="/designers">DESIGNERS</a>
                        </li>
                        <li class="dropdown">
                          <a className="dropbtn" href="/products?p=bags&t=all">
                            BAGS
                          </a>
                          <div class="dropdown-content">
                            <a href="/products?p=bags&t=BACKPACK">BACKPACK</a>
                            <a href="/products?p=bags&t=BEAUTY CASE">
                              BEAUTY CASE
                            </a>
                            <a href="/products?p=bags&t=BELT BAG">BELT BAG</a>
                            <a href="/products?p=bags&t=BRIEFCASE">BRIEFCASE</a>
                            <a href="/products?p=bags&t=CLUTCH">CLUTCH</a>
                            <a href="/products?p=bags&t=HANDBAG">HANDBAG</a>
                            <a href="/products?p=bags&t=MESSENGER BAG">
                              MESSENGER BAG
                            </a>
                            <a href="/products?p=bags&t=POUCH">POUCH</a>
                            <a href="/products?p=bags&t=SHOULDER BAG">
                              SHOULDER BAG
                            </a>
                            <a href="/products?p=bags&t=TOTE">TOTE</a>
                            <a href="/products?p=bags&t=TRAVEL BAG">
                              TRAVEL BAG
                            </a>
                            <a href="/products?p=bags&t=TROLLEY">TROLLEY</a>
                          </div>
                        </li>
                        <li class="dropdown">
                          <a
                            className="dropbtn"
                            href="/products?p=accessories&t=all"
                          >
                            ACCESSORIES
                          </a>
                          <div class="dropdown-content">
                            <div className="row">
                              <div className="col">
                                <a href="/products?p=accessories&t=EARRINGS">
                                  EARRINGS
                                </a>
                                <a href="/products?p=accessories&t=FOULARD">
                                  FOULARD
                                </a>
                                <a href="/products?p=accessories&t=FRAGRANCE">
                                  FRAGRANCE
                                </a>
                                <a href="/products?p=accessories&t=TIE">TIE</a>
                                <a href="/products?p=accessories&t=TIE CLIP">
                                  TIE CLIP
                                </a>
                                <a href="/products?p=accessories&t=UMBRELLA">
                                  UMBRELLA
                                </a>
                                <a href="/products?p=accessories&t=WALLET">
                                  WALLET
                                </a>
                                <a href="/products?p=accessories&t=WATCH">
                                  WATCH
                                </a>
                                <a href="/products?p=accessories&t=GLASSES">
                                  GLASSES
                                </a>
                                <a href="/products?p=accessories&t=GLOVES">
                                  GLOVES
                                </a>
                                <a href="/products?p=accessories&t=HAIR CLIP">
                                  HAIR CLIP
                                </a>
                                <a href="/products?p=accessories&t=DOCUMENT HOLDER">
                                  DOCUMENT HOLDER
                                </a>
                              </div>
                              <div className="col">
                                <a href="/products?p=accessories&t=HAT">HAT</a>
                                <a href="/products?p=accessories&t=HEADBAND">
                                  HEADBAND
                                </a>
                                <a href="/products?p=accessories&t=HIGH TECH">
                                  HIGH TECH
                                </a>
                                <a href="/products?p=accessories&t=KEY CHAIN">
                                  KEY CHAIN
                                </a>
                                <a href="/products?p=accessories&t=MASK">
                                  MASK
                                </a>
                                <a href="/products?p=accessories&t=NECKLACE">
                                  NECKLACE
                                </a>
                                <a href="/products?p=accessories&t=PIN">PIN</a>
                                <a href="/products?p=accessories&t=RING">
                                  RING
                                </a>
                                <a href="/products?p=accessories&t=SCARF">
                                  SCARF
                                </a>
                                <a href="/products?p=accessories&t=SHAWL">
                                  SHAWL
                                </a>
                                <a href="/products?p=accessories&t=SHOE LACE">
                                  SHOE LACE
                                </a>
                                <a href="/products?p=accessories&t=SHOULDER STRAP">
                                  SHOULDER STRAP
                                </a>
                              </div>
                              <div className="col">
                                <a href="/products?p=accessories&t=BELT">
                                  BELT
                                </a>
                                <a href="/products?p=accessories&t=BOW TIE">
                                  BOW TIE
                                </a>
                                <a href="/products?p=accessories&t=BRACELET">
                                  BRACELET
                                </a>
                                <a href="/products?p=accessories&t=BRACES">
                                  BRACES
                                </a>
                                <a href="/products?p=accessories&t=CANDLE">
                                  CANDLE
                                </a>
                                <a href="/products?p=accessories&t=CARD HOLDER">
                                  CARD HOLDER
                                </a>
                                <a href="/products?p=accessories&t=CASE">
                                  CASE
                                </a>
                                <a href="/products?p=accessories&t=COVER">
                                  COVER
                                </a>
                                <a href="/products?p=accessories&t=CUFF LINKS">
                                  CUFF LINKS
                                </a>
                                <a href="/products?p=accessories&t=SOCKS">
                                  SOCKS
                                </a>
                                <a href="/products?p=accessories&t=STICKER">
                                  STICKER
                                </a>
                                <a href="/products?p=accessories&t=SUNGLASSES">
                                  SUNGLASSES
                                </a>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li class="dropdown">
                          <a href="/sale?p=bags&t=all">SALE</a>
                        </li>
                        <li class="dropdown">
                          <a href="/about">ABOUT US</a>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="tt-stuck-nav" id="js-tt-stuck-nav">
          <div class="container">
            <div class="tt-header-row ">
              <div class="tt-stuck-parent-menu"></div>
              <div class="tt-stuck-parent-search tt-parent-box"></div>
              <div class="tt-stuck-parent-cart tt-parent-box"></div>
              <div class="tt-stuck-parent-account tt-parent-box"></div>
            </div>
          </div>
        </div>
        {videoset === 1 ? (
          <MobileView>
            <center>
              <video
                width="100%"
                height="auto"
                className="videoTag"
                autoPlay
                loop
                muted
              >
                <source
                  src="https://res.cloudinary.com/dx9dnqzaj/video/upload/v1632365989/ecommerse2/Website_variant_-_18_mb_pjbfbe.mp4"
                  type="video/mp4"
                />
              </video>
            </center>
          </MobileView>
        ) : null}
      </header>
    </>
  );
};

export default HeaderLangingPage;
