import React, { useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";

// Components
import HeaderLangingPage from "../components/HeaderLangingPage";
import FooterLandingPage from "../components/FooterLandingPage";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import iDeal from "../images/iDeal.png";
import { TextField, Button } from "@material-ui/core";

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
// Extras
import { API_SERVICE } from "../config/URI";
import { firestore } from "../Firebase/index";

const ProductList = ({
  p,
  settotalprice,
  changeQty,
  handleClickOpenProduct,
  product,
  deleteProduct,
}) => {
  var subTotal = Number(product.qty) * Number(product.price);
  settotalprice(p.toFixed(2));
  console.log(product);
  return (
    <tr>
      <td>
        <div onClick={() => handleClickOpenProduct(product)} class="tt-product-img">
          <img style={{ cursor: 'pointer' }} src={product.image} data-src={product.image} alt="" />
        </div>
      </td>
      <td>
        <h2 class="tt-title">
          <a onClick={() => handleClickOpenProduct(product)} href="#">{product.title}</a>
        </h2>
        {/* <ul class="tt-list-description">
                    <li>Size: 22</li>
                    <li>Color: Green</li>
                </ul> */}
        <ul class="tt-list-parameters">
          {/* <li>
            <div class="tt-price">€{product.price}</div>
          </li> */}
          {/* <li>
                        <div class="detach-quantity-mobile">
                            <select value={product.qty} onChange={(e) => changeQty(product._id, e.target.value)} name="cars" id="cars">
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                            </select>
                        </div>
                    </li> */}
          <li>
            <div class="tt-price subtotal">€{product.price}</div>
          </li>
        </ul>
      </td>
      {/* <td>
        <div class="tt-price">€{product.price}</div>
      </td> */}
      <td>
        {/* <div className="detach-quantity-desctope">
                    <select value={product.qty} onChange={(e) => changeQty(product._id, e.target.value)} name="cars" id="cars">
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                    </select>
                </div> */}
      </td>
      <td>
        <div class="tt-price subtotal" style={{ textAlign: "right" }}>
          €{subTotal}
        </div>
      </td>
      <td>
        <a
          onClick={() => deleteProduct(product._id)}
          href="#"
          class="tt-btn-close"
        ></a>
      </td>
    </tr>
  );
};

const Cart = () => {
  const [products, setproducts] = React.useState([]);
  const [userId, setuserId] = React.useState("");
  const [note, setnote] = React.useState("");
  const [loading, setloading] = React.useState(true);
  const [totalprice, settotalprice] = React.useState(0);

  const [openProduct, setOpenProduct] = React.useState(false);
  const [productdescbrand, setproductdescbrand] = React.useState("");
  const [productdescdescription, setproductdescdescription] = React.useState("");
  const [productdesc, setproductdesc] = React.useState({
    "_id": "60cde1a456edaf0015bff222",
    "title": "MICHAEL KORS WOMEN'S 32F7GGNM8L001 BLACK LEATHER SHOULDER BAG",
    "price": 273,
    "displayImage": "https://crystalpim-images.ams3.digitaloceanspaces.com/img/9d842da0-6910-11ea-98dd-ff5ef10bc308.jpg",
    "category": "BAGS",
    "madein": "Italy",
    "gender": "F",
    "description": "SHOULDER BAG MICHAEL KORS, LEATHER 100%, color BLACK, Measurements 21x15x5cm, Shoulder Strap 55cm, SS21, product code 32F7GGNM8L001",
    "product": {
      "Images": [
        "https://crystalpim-images.ams3.digitaloceanspaces.com/img/9e3ea400-6910-11ea-98dd-ff5ef10bc308.jpg",
        "https://crystalpim-images.ams3.digitaloceanspaces.com/img/9dbddb40-6910-11ea-98dd-ff5ef10bc308.jpg",
        "https://crystalpim-images.ams3.digitaloceanspaces.com/img/300166e0-fc21-11ea-b7b8-f154e7ecb4be.jpg",
        "https://crystalpim-images.ams3.digitaloceanspaces.com/img/9de51250-6910-11ea-98dd-ff5ef10bc308.jpg",
        "https://crystalpim-images.ams3.digitaloceanspaces.com/img/9d842da0-6910-11ea-98dd-ff5ef10bc308.jpg"
      ],
      "Variants": [
        {
          "Code": "32F7GGNM8L001 UNI",
          "Size": "UNI",
          "SizeType": "int",
          "Discount": 20,
          "RetailPrice": 181,
          "Quantity": 11,
          "LastUpdatedDatetimeUtc": "2021-04-28T23:41:23.211Z",
          "EAN": "8051168628799"
        }
      ],
      "_id": "6092a061c97ebadef431ded5",
      "Sku": "32F7GGNM8L001",
      "Brand": "MICHAEL KORS",
      "Category": "SHOULDER BAG",
      "Color": "BLACK",
      "Condition": "New",
      "CreatedDatetimeUtc": "2020-05-27T11:31:43.921Z",
      "Description": "SHOULDER BAG MICHAEL KORS, LEATHER 100%, color BLACK, Measurements 21x15x5cm, Shoulder Strap 55cm, SS21, product code 32F7GGNM8L001",
      "Gender": "F",
      "LastUpdatedDatetimeUtc": "2021-03-04T16:36:34.212Z",
      "ListPrice": 226,
      "MadeIn": "Italy",
      "ParentCategory": "BAGS",
      "Title": "MICHAEL KORS WOMEN'S 32F7GGNM8L001 BLACK LEATHER SHOULDER BAG"
    },
    "recommended": true,
    "date": "2021-06-19T12:23:00.483Z",
    "__v": 0,
    "title2": "title temp"
  });

  const handleClickOpenProduct = (p) => {
    setOpenProduct(true);
    setproductdesc(p);
    setproductdescbrand(p.product.product.Brand);
    setproductdescdescription(p.product.product.Description);
  };

  const handleCloseProduct = () => {
    setOpenProduct(false);
  };

  React.useEffect(() => {
    if (localStorage.getItem("userId") === null) {
      localStorage.setItem("userId", uuidv4() + Date.now());
    } else {
      var userId = localStorage.getItem("userId");
      setuserId(userId);
    }
    axios
      .get(`${API_SERVICE}/api/v1/main/getcartallitems/${userId}`)
      .then((response) => {
        setproducts(response.data);
        setloading(false);
      })
      .catch((err) => console.log(err));
  }, []);

  const refreshList = () => {
    axios
      .get(`${API_SERVICE}/api/v1/main/getcartallitems/${userId}`)
      .then((response) => {
        setproducts(response.data);
        setloading(false);
      })
      .catch((err) => console.log(err));
  };

  const deleteProduct = (id) => {
    var docRef = firestore.collection("cart").doc(userId);
    docRef
      .get()
      .then(function (doc) {
        var items = doc.data().items;
        axios
          .get(`${API_SERVICE}/api/v1/main/deleteitemsmaincart/${id}`)
          .then((response) => {
            setloading(true);
            settotalprice(0);
            refreshList();
            items = items - 1;
            docRef.set(
              {
                items,
              },
              { merge: true }
            );
          })
          .catch((err) => console.log(err));
      })
      .catch(function (error) {
        console.log("Error getting document:", error);
      });
  };

  const changeQty = (documentId, qty) => {
    axios
      .get(
        `${API_SERVICE}/api/v1/main/cartitemchangequantitycart/${documentId}/${qty}`
      )
      .then((response) => {
        if (response.status === 200) {
          refreshList();
          setloading(true);
        }
      })
      .catch((err) => console.log(err));
  };

  const showProductList = () => {
    var p = 0;
    return products.map((product) => {
      var subTotal = Number(product.qty) * Number(product.price);
      p = Number(p) + Number(subTotal);
      return (
        <ProductList
          settotalprice={settotalprice}
          p={p}
          handleClickOpenProduct={handleClickOpenProduct}
          changeQty={changeQty}
          deleteProduct={deleteProduct}
          product={product}
          key={product.Sku}
        />
      );
    });
  };

  const clearShoppingCartItem = () => {
    var docRef = firestore.collection("cart").doc(userId);
    docRef
      .get()
      .then(function (doc) {
        var items = doc.data().items;
        axios
          .get(`${API_SERVICE}/api/v1/main/clearallcartitems/${userId}`)
          .then((response) => {
            setloading(true);
            settotalprice(0);
            refreshList();
            items = 0;
            docRef.set(
              {
                items,
              },
              { merge: true }
            );
          })
          .catch((err) => console.log(err));
      })
      .catch(function (error) {
        console.log("Error getting document:", error);
      });
  };

  const proceedCheckout = () => {
    localStorage.setItem("note", note);
    window.location.href = "/checkout";
  };

  const proceedCheckout2 = () => {
    localStorage.setItem("note", note);
    window.location.href = "/checkoutideal";
  };

  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const [coupon, setCoupon] = React.useState("");
  const [discount, setDiscount] = React.useState(0);
  const [message, setMessage] = React.useState("");

  useEffect(() => {
    const userId = localStorage.getItem("userId");
    var docRef = firestore.collection("discount").doc(userId);
    docRef
      .get()
      .then(function (doc) {
        var discount = doc.data().discount;
        if (discount > 0) {
          setDiscount(discount);
        }
      })
      .catch(function (error) {
        console.log("Error getting document:", error);
      });
  }, []);

  const checkCoupon = async () => {
    await axios
      .get(`${API_SERVICE}/api/v1/main/getcouponitemscart/${coupon}`)
      .then((res) => {
        setDiscount(res.data);
        setCoupon("");
        setMessage("Disount Applied");
        handleClick();
        firestore
          .collection("discount")
          .doc(userId)
          .set({ discount: res.data });
      })
      .catch((err) => {
        console.log(err);
        setCoupon("");
        setMessage("No Code Found");
        handleClick();
      });
  };

  const removeDiscount = () => {
    setDiscount(0);
    firestore
      .collection("discount")
      .doc(userId)
      .set({ discount: 0 });
  }

  return (
    <>
      <HeaderLangingPage />
      <br />

      <Dialog
        open={openProduct}
        fullWidth
        maxWidth="md"
        onClose={handleCloseProduct}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <div className="container">
            <div className="row">
                <div className="col-md">
                  <img src={productdesc.image} style={{ width: '400px' }} data-src={productdesc.image} alt="" />
                </div>
                <div style={{ marginTop: '100px' }} className="col-md">
                  <h4>€ {productdesc.price} <span style={{ fontSize: '14px' }} >(VAT Inc.)</span>  </h4>
                  <h4>{productdescbrand}</h4>
                  <h6>
                    {productdescdescription}
                  </h6>
                </div>
            </div>
          </div>
          
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseProduct} color="primary" autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <div id="tt-pageContent">
        <div class="container-indent">
          <div class="container">
            <h1 class="tt-title-subpages noborder">SHOPPING CART</h1>
            {products && products.length ? (
              <>
                <div class="tt-shopcart-table-02">
                  <table>
                    <tbody>{loading ? <></> : <>{showProductList()}</>}</tbody>
                  </table>
                  <div class="tt-shopcart-btn">
                    <div class="col-left">
                      <a class="btn-link" href="/products?p=bags&t=all">
                        <i class="icon-e-19"></i>CONTINUE SHOPPING
                      </a>
                    </div>
                    <div class="col-right">
                      <a
                        onClick={clearShoppingCartItem}
                        class="btn-link"
                        href="#"
                      >
                        <i class="icon-h-02"></i>CLEAR SHOPPING CART
                      </a>
                    </div>
                  </div>
                </div>

                <div class="tt-shopcart-col">
                  <div class="row">
                    <div class="col-md-6 col-lg-4">
                      <div class="tt-shopcart-box">
                        <h4 class="tt-title">NOTE</h4>
                        <p>Add special instructions for your order...</p>
                        <form class="form-default">
                          <textarea
                            value={note}
                            onChange={(e) => setnote(e.target.value)}
                            class="form-control"
                            rows="3"
                            style={{ height: "276px" }}
                          ></textarea>
                        </form>
                      </div>
                    </div>
                    <div class="col-md-6 col-lg-4">
                      <div class="tt-shopcart-box tt-boredr-large">
                        <table class="tt-shopcart-table01">
                          <tbody>
                            <tr>
                              {
                                discount > 0 ? (
                                  <th style={{ paddingLeft: '70px' }}>
                                    <Button 
                                      variant="outlined"
                                      color="secondary"
                                      fullWidth
                                      onClick={removeDiscount}>
                                      Remove Discount
                                    </Button>
                                  </th>
                                ) : (
                                  <>
                                    <th style={{ padding: 0 }}>
                                      <TextField
                                        variant="outlined"
                                        label="Enter Coupon code"
                                        size="small"
                                        fullWidth
                                        disabled={discount > 0}
                                        value={coupon}
                                        onChange={(e) => setCoupon(e.target.value)}
                                      />
                                    </th>
                                    <td>
                                      <Button
                                        variant="outlined"
                                        style={{ marginLeft: "5px" }}
                                        onClick={checkCoupon}
                                        disabled={discount > 0}
                                      >
                                        Check
                                      </Button>
                                    </td>
                                  </>
                                )
                              }
                            </tr>
                            <tr>
                              <th style={{ fontSize: '14px' }}>SUBTOTAL</th>
                              <td style={{ fontSize: '14px' }}>€{totalprice}</td>
                            </tr>
                            {/* <tr style={{ paddingTop: '10px' }}>
                              <th style={{ fontSize: '14px' }}>VAT Included</th>
                              <td style={{ fontSize: '14px' }}>€{Math.round(0.21 * totalprice)}</td>
                            </tr> */}
                            
                            {discount !== 0 && (
                              <tr>
                                <th style={{ fontSize: '14px' }}>Discount</th>
                                <td style={{ fontSize: '14px' }}>€{discount}</td>
                              </tr>
                            )}
                            {/* <tr>
                              <th style={{ fontSize: '14px' }}>GRAND TOTAL</th>
                              <td style={{ fontSize: '14px' }}>
                                €{totalprice - discount}
                              </td>
                            </tr> */}
                            </tbody>
                        </table>

                        <br />
                        <center>
                          <strong className="text-dark">PROCEED WITH</strong>
                        </center>
                        <br />
                        <a
                          href="#!"
                          onClick={proceedCheckout}
                          style={{
                            backgroundColor: "#ffffff",
                            color: "#000000",
                            fontSize: '14px'
                          }}
                          class="btn btn-lg"
                        >
                          <span class="icon icon-check_circle"></span>
                          <img
                            className="mr-2"
                            src="https://img.icons8.com/ios/50/000000/bank-card-back-side.png"
                          />{" "}
                          PAY WITH CARD
                        </a>
                        <hr
                          style={{
                            margin: "5px 0",
                            borderTop: "1px solid #0e0e0e",
                          }}
                        />
                        <a
                          href="#!"
                          onClick={proceedCheckout2}
                          style={{
                            backgroundColor: "#ffffff",
                            color: "#000000",
                            fontSize: '14px'
                          }}
                          class="btn mt-2 btn-lg"
                        >
                          <span class="icon icon-check_circle"></span>
                          <img
                            className="mr-2"
                            style={{ width: "46px" }}
                            src={iDeal}
                          />{" "}
                          PAY WITH iDEAL
                        </a>
                      </div>
                    </div>
                  </div>
                  <Snackbar
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                    open={open}
                    autoHideDuration={6000}
                    onClose={handleClose}
                    message={message}
                    action={
                      <React.Fragment>
                        <IconButton
                          size="small"
                          aria-label="close"
                          color="inherit"
                          onClick={handleClose}
                        >
                          <CloseIcon fontSize="small" />
                        </IconButton>
                      </React.Fragment>
                    }
                  />
                </div>
              </>
            ) : (
              <>
                <center>
                  <a class="btn-link" href="/products?p=bags&t=all">
                    <i class="icon-e-19"></i>CONTINUE SHOPPING
                  </a>
                  <br />
                  <img
                    className="responsive"
                    src="https://www.no-fea.com/front/images/empty-cart.png"
                    alt="Empty Cart"
                  />
                </center>
              </>
            )}
          </div>
        </div>
      </div>
      <FooterLandingPage />
    </>
  );
};

export default Cart;
