import React from 'react';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import CircularProgress from '@material-ui/core/CircularProgress';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';


// Extras
import { API_SERVICE } from '../config/URI';
import { firestore } from "../Firebase/index";

// Components
import HeaderLangingPage from '../components/HeaderLangingPage';
import FooterLandingPage from '../components/FooterLandingPage';

const ProductList = ({ deleteProduct, addProduct, product }) => {
    return (
        <div class="tt-item">
            <div class="tt-col-description">
                <div class="tt-img">
                    <img src={product.product.displayImage} alt="Product Image" />
                </div>
                <div class="tt-description">
                    <h2 class="tt-title"><a href={`/view?id=${product.product._id}`}>{product.product.title}</a></h2>
                    <div class="tt-price">
                        € {product.product.price}
                    </div>
                </div>
            </div>
            <div class="tt-col-btn">
                <a href="#!" class="tt-btn-addtocart" onClick={() => addProduct(product.product)}><i class="icon-f-39"></i>ADD TO CART</a>
                <a class="btn-link" href={`/view?id=${product.product._id}`}><i class="icon-f-73"></i>SEE PRODUCT</a>
                <a class="btn-link js-removeitem" href="#!" onClick={() => deleteProduct(product._id)}><i class="icon-h-02"></i>REMOVE</a>
            </div>
        </div>
    )
}

const Wishlist = () => {
    const [products, setproducts] = React.useState([]);
    const [userId, setuserId] = React.useState('');
    const [loading, setloading] = React.useState(true);
    const [message, setmessage] = React.useState('');

    const [open, setOpen] = React.useState(false);
    const handleClick = () => {
        setOpen(true);
    };
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
        return;
        }
        setOpen(false);
    };
    
    React.useEffect(() => {
        if (localStorage.getItem("userId") === null) {
            localStorage.setItem("userId", uuidv4()+Date.now());
            setproducts([]);
        } else {
            var userId = localStorage.getItem("userId");
            setuserId(userId);
            axios.get(`${API_SERVICE}/api/v1/main/getproductsuserwishlist/${userId}`)
                .then(response => {
                    setproducts(response.data);
                    setloading(false);
                })
                .catch(err => console.log(err))
        }
    }, []);

    const addProduct = (product) => {
        var docRef = firestore.collection("cart").doc(userId);
        docRef.get().then(function(doc) {
            if (doc.exists) {
                var items = doc.data().items;
                if (items === 10000) {
                    console.log("Maximum Number of Items in Cart Exceed");
                } else {
                    var uploadData = {
                        userId,
                        title: product.title,
                        qty: 1,
                        image: product.displayImage,
                        price: Number(product.price),
                        product
                    }
                    axios.post(`${API_SERVICE}/api/v1/main/addproducttocart`, uploadData)
                        .then((response) => {
                            if (response.status === 200) {
                                items = items + 1;
                                docRef.set({
                                    items 
                                }, { merge: true });
                                setmessage("Item Added to Cart");
                                handleClick();
                            }
                        }).catch(err => console.log(err));
                }
            } else {
                docRef.set({
                    items: 1 
                }, { merge: true });
                var uploadData = {
                    userId,
                    title: product.title,
                    qty: 1,
                    image: product.displayImage,
                    price: Number(product.price),
                    product
                }
                axios.post(`${API_SERVICE}/api/v1/main/addproducttocart`, uploadData)
                    .then((response) => {
                        if (response.status === 200) {
                            setmessage("Item Added to Cart");
                            handleClick();
                        }
                    }).catch(err => console.log(err));
            }
        }).catch(function(error) {
            console.log("Error getting document:", error);
        });
    }


    const refreshList = () => {
        axios.get(`${API_SERVICE}/api/v1/main/getproductsuserwishlist/${userId}`)
            .then(response => {
                setproducts(response.data);
                setloading(false);
            })
            .catch(err => console.log(err))
    }

    const deleteProduct = (id) => {
        axios.get(`${API_SERVICE}/api/v1/main/deleteproductuserwish/${id}`)
            .then(response => {
                setloading(true);
                refreshList();
            })
            .catch(err => console.log(err))
    }

    const showProductList = () => {
        return products.map(product => {
            return <ProductList deleteProduct={deleteProduct} addProduct={addProduct} product={product} key={product._id} />
        })
    }

    return (
        <>
            <Snackbar
                anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
                }}
                open={open}
                autoHideDuration={4000}
                onClose={handleClose}
                message={message}
                action={
                <React.Fragment>
                    <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
                        <CloseIcon fontSize="small" />
                    </IconButton>
                </React.Fragment>
                }
            />
            <HeaderLangingPage />         
            <br />
            <div id="tt-pageContent">
                <div class="container-indent">
                    <div class="container">
                        <h1 class="tt-title-subpages noborder">WISHLIST</h1>
                        <div class="tt-wishlist-box" id="js-wishlist-removeitem">
                            {
                                products && products.length ? (
                                    <div class="tt-wishlist-list">
                                        {
                                            loading === true ? (
                                                <center style={{ marginTop: '10%'}}>
                                                    <CircularProgress />
                                                </center>
                                            ) : (
                                                <>
                                                    {showProductList()}
                                                </>
                                            )
                                        }
                                    </div>
                                ) : (
                                    <>
                                        <center>
                                            <a class="btn-link" href="/products?p=bags&t=all"><i class="icon-e-19"></i>CONTINUE SHOPPING</a>
                                            <br />
                                            <br />
                                            <br />
                                            <br />
                                            <img src="https://img.icons8.com/color/96/000000/hearts.png" alt="Wishlist" />
                                            <h1>No Items in Wishlist</h1>
                                            <br />
                                        </center>
                                    </>
                                )
                            }
                        </div>
                    </div>
                </div>
            </div>
            <br />
            <br />
            <br />
            <br />
            <FooterLandingPage />
        </>
    )
}

export default Wishlist
