import React from "react";
import { useState } from "react";
import FooterLandingPage from "../components/FooterLandingPage";
import HeaderLangingPage from "../components/HeaderLangingPage";
import { useAuth } from "../contexts/AuthContext";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const SignIn = () => {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const handleClose = () => {
    setOpen(false);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  const { login } = useAuth();
  const [formState, setFormState] = useState({
    email: "",
    password: "",
  });
  const handleChange = (event) => {
    setFormState((prev) => {
      return {
        ...prev,
        [event.target.name]: event.target.value,
      };
    });
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    login(formState.email, formState.password)
      .then((abs) => {
        window.location.href = "https://houseofplug.com/home";
      })
      .catch((err) => {
        setMessage("Invalid Credentials. Please Try Again!");
        handleClickOpen();
      });
  };

  return (
    <>
      <HeaderLangingPage />
      <br />
      <section className="pt-4">
        <div id="tt-pageContent">
          <div className="container-indent">
            <div className="container">
              <h1 className="tt-title-subpages noborder">
                ALREADY REGISTERED?
              </h1>
              <div className="tt-login-form">
                <div className="row">
                  <div className="col-xs-12 col-md-6">
                    <div className="tt-item">
                      <h2 className="tt-title">NEW CUSTOMER</h2>
                      <p>
                        By creating an account with our store, you will be able
                        to move through the checkout process faster, store
                        multiple shipping addresses, view and track your orders
                        in your account and more.
                      </p>
                      <div className="form-group">
                        <a href="/register" className="btn btn-top btn-border">
                          CREATE AN ACCOUNT
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="col-xs-12 col-md-6">
                    <div className="tt-item">
                      <h2 className="tt-title">LOGIN</h2>
                      If you have an account with us, please log in.
                      <div className="form-default form-top">
                        <form
                          id="customer_login"
                          onSubmit={handleSubmit}
                          novalidate="novalidate"
                        >
                          <div className="form-group">
                            <label for="loginInputName">E-MAIL *</label>
                            <div className="tt-required">* Required Fields</div>
                            <input
                              type="text"
                              value={formState.email}
                              onChange={handleChange}
                              name="email"
                              className="form-control"
                              id="loginInputName"
                              placeholder="Enter Username or E-mail"
                            />
                          </div>
                          <div className="form-group">
                            <label for="loginInputEmail">PASSWORD *</label>
                            <input
                              type="password"
                              value={formState.password}
                              onChange={handleChange}
                              name="password"
                              className="form-control"
                              id="loginInputEmail"
                              placeholder="Enter Password"
                            />
                          </div>
                          <div className="row">
                            <div className="col-auto mr-auto">
                              <div className="form-group">
                                <button
                                  className="btn btn-border"
                                  type="submit"
                                >
                                  LOGIN
                                </button>
                              </div>
                            </div>
                            <div className="col-auto align-self-end">
                              <div className="form-group">
                                <ul className="additional-links">
                                  <li>
                                    <a href="">Lost your password?</a>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          open={open}
          autoHideDuration={6000}
          onClose={handleClose}
          message={message}
          action={
            <React.Fragment>
              <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleClose}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            </React.Fragment>
          }
        />
      </section>
      <FooterLandingPage />
    </>
  );
};

export default SignIn;
