import React from "react";
import { useAuth } from "../contexts/AuthContext";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import Container from "@material-ui/core/Container";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import { mainListItems } from "./listitems";
import axios from "axios";
import CircularProgress from "@material-ui/core/CircularProgress";
import { API_SERVICE } from "../config/URI";
import Grid from "@material-ui/core/Grid";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import NotesIcon from "@material-ui/icons/Notes";
import DeleteIcon from "@material-ui/icons/Delete";
import VisibilityIcon from "@material-ui/icons/Visibility";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import Chip from "@material-ui/core/Chip";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import ButtonGroup from '@material-ui/core/ButtonGroup';
const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: "none",
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 240,
  },
  table: {
    minWidth: 650,
  },
  margin: {
    margin: theme.spacing(1),
  },
}));

// const OrderList = ({ order, i }) => {
//   return (
//     <List
//       component="nav"
//       onClick={() =>
//         (window.location.href = `/adminmainstoreordersdetails?id=${order.userId}`)
//       }
//       aria-label="contacts"
//     >
//       <ListItem button>
//         <ListItemText primary={`${i}. ${order.fullName}`} />
//       </ListItem>
//     </List>
//   );
// };

export default function MainStore() {
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);
  const [loading, setloading] = React.useState(true);
  const [orders, setorders] = React.useState([]);

  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  const [openDialog, setOpenDialog] = React.useState(false);
  const handleClickOpenDialog = () => {
    setOpenDialog(true);
  };
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  React.useEffect(() => {
    axios
      .get(`${API_SERVICE}/api/v1/main/getalltheordersmainstore`)
      .then((response) => {
        setorders(response.data);
        setloading(false);
      })
      .catch((err) => console.log(err));
  }, [loading]);

  const getallorder = () => {
    axios
      .get(`${API_SERVICE}/api/v1/main/getalltheordersmainstore`)
      .then((response) => {
        setorders(response.data);
        setloading(false);
      })
      .catch((err) => console.log(err));
  }

  const getallcomp = () => {
    axios
      .get(`${API_SERVICE}/api/v1/main/getalltheordersmainstorecomp`)
      .then((response) => {
        setorders(response.data);
        setloading(false);
      })
      .catch((err) => console.log(err));
  }

  const getallnotcomp = () => {
    axios
      .get(`${API_SERVICE}/api/v1/main/getalltheordersmainstorenotcomp`)
      .then((response) => {
        setorders(response.data);
        setloading(false);
      })
      .catch((err) => console.log(err));
  }

  const OrderList = ({ order, i }) => {
    const deleteOrder = () => {
      const result = window.confirm("Are you sure to delete it ?");
      if (result) {
        axios
          .get(`${API_SERVICE}/api/v1/main/deleteorder/${order._id}`)
          .then(() => {
            setloading(true);
            setloading(false);
          })
          .catch((err) => console.log(err));
      }
    };
    console.log(order);
    return (
      <TableRow key="1">
        <Dialog
          open={openDialog}
          onClose={handleCloseDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth={true}
          maxWidth="sm"
        >
          <DialogTitle id="alert-dialog-title">Note</DialogTitle>
          <DialogContent>
            <DialogContentText
              style={{ color: "#000000" }}
              id="alert-dialog-description"
            >
              {order.note}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDialog} color="primary" autoFocus>
              Okay
            </Button>
          </DialogActions>
        </Dialog>
        <TableCell component="th" scope="row">
          #{i} {order?.fullName} &nbsp;
          <Chip
            color={order?.completed ? "primary" : "secondary"}
            label={order?.completed ? "Complete" : "Incomplete"}
          />
        </TableCell>
        <TableCell align="right">{order?.totalItems} items</TableCell>
        <TableCell align="right">€ {order?.amount}</TableCell>
        <TableCell align="right">{order?.date.split("T")[0]}</TableCell>
        <TableCell align="center">
          <IconButton
            onClick={() => handleClickOpenDialog()}
            aria-label="delete"
            className={classes.margin}
          >
            <NotesIcon />
          </IconButton>
          <IconButton
            onClick={deleteOrder}
            aria-label="delete"
            className={classes.margin}
          >
            <DeleteIcon />
          </IconButton>
          <IconButton
            href={`/adminmainstoreordersdetails?id=${order.userId}&orderId=${order._id}`}
            aria-label="delete"
            className={classes.margin}
          >
            <VisibilityIcon />
          </IconButton>
          <IconButton
            onClick={() =>
              window.confirm("Are you sure to mark it as complete ?")
            }
            aria-label="delete"
            className={classes.margin}
          >
            <CheckCircleIcon />
          </IconButton>
        </TableCell>
      </TableRow>
    );
  };

  const OrderList2 = ({ order, i }) => {
    return (
      <tr>
        <th>{order?.fullName}</th>
        <th>{order?.totalItems} items</th>
        <th>€ {order?.amount}</th>
        <th>{order?.date.split("T")[0]}</th>
      </tr>
    );
  };

  const ShowOrderList = () => {
    
    var i = 0;
    if (!orders.length > 0) return null;
    return orders?.map((order) => {
      i = i + 1;
      return <OrderList i={i} order={order} key={order._id} />;
    });
  };

  const ShowOrderList2 = () => {
    var i = 0;
    if (!orders.length > 0) return null;
    return orders.map((order) => {
      i = i + 1;
      return <OrderList2 i={i} order={order} key={order._id} />;
    });
  };
  const {logout} = useAuth()
    const handleSignOut = () =>{
        logout()
    }
  return (
    <div className={classes.root}>
      <CssBaseline />

      <AppBar
        position="absolute"
        className={clsx(classes.appBar, open && classes.appBarShift)}
      >
        <Toolbar className={classes.toolbar}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            className={clsx(
              classes.menuButton,
              open && classes.menuButtonHidden
            )}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            component="h1"
            variant="h6"
            color="inherit"
            noWrap
            className={classes.title}
          >
            Dashboard
          </Typography>
          <Button variant="contained" color="secondary" onClick={handleSignOut}>
            Signout
          </Button>
          <IconButton color="inherit"></IconButton>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        classes={{
          paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
        }}
        open={open}
      >
        <div className={classes.toolbarIcon}>
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <Divider />
        <List>{mainListItems}</List>
      </Drawer>
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          <h2 className="font-weight-bold">All Orders</h2>

          <ButtonGroup color="primary" aria-label="outlined primary button group">
            <Button  onClick={getallcomp}>Complete</Button>
            <Button  onClick={getallnotcomp}>Incomplete</Button>
            <Button  onClick={getallorder}>All</Button>
          </ButtonGroup>

          <ReactHTMLTableToExcel
            id="test-table-xls-button"
            className="excelbutton"
            table="table-to-xls"
            filename="orders"
            sheet="orders"
            buttonText="Download as XLS"
          />

          <Grid container spacing={3}>
            <Grid item xs={12}>
              {loading ? (
                <center>
                  <CircularProgress />
                </center>
              ) : (
                <>
                  <TableContainer component={Paper}>
                    <Table className={classes.table} aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell>Order</TableCell>
                          <TableCell align="right">Purchased</TableCell>
                          <TableCell align="center">Total</TableCell>
                          <TableCell align="center">Date</TableCell>
                          <TableCell align="center">Actions</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody></TableBody>
                      <ShowOrderList />
                    </Table>
                  </TableContainer>

                  <table hidden id="table-to-xls">
                    <tr>
                      <th>Order</th>
                      <th>Purchased</th>
                      <th>Total</th>
                      <th>Date</th>
                    </tr>
                    <ShowOrderList2 />
                  </table>
                </>
              )}
            </Grid>
          </Grid>
        </Container>
      </main>
    </div>
  );
}
