import "./App.css";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import { AuthProvider } from "./contexts/AuthContext";
import { ToastProvider } from "react-toast-notifications";
// Pages
import Home from "./pages/Home";
import Products from "./pages/Products";
import View from "./pages/View";
import Cart from "./pages/Cart";
import Checkout from "./pages/Checkout";
import ThankYou from "./pages/ThankYou";
import Track from "./pages/Track";
import Wishlist from "./pages/Wishlist";
import ComingSoon from "./pages/ComingSoon";
import Checkoutideal from "./pages/Checkoutideal";
import About from "./pages/About";
import Designers from "./pages/Designers";
import Newin from "./pages/Newin";
import Sale from "./pages/Sale";
import FAQ from "./pages/FAQ";

// Admin
import AdminRoutes from "./adminRoutes";
// Document
import Return from "./Documents/Return";
import Delivery from "./Documents/Delivery";
import Terms from "./Documents/Terms";
import Privacy from "./Documents/Privacy";
import Disclaimer from "./Documents/Disclaimer";
import Cookie from "./Documents/Cookie";
import SignIn from "./pages/SignIn";
import Register from "./pages/Register";
import Contact from "./pages/Contact";
import PaymentDocs from "./Documents/Payment";
import Shipping from "./Documents/Shipping";

function App() {
  return (
    <ToastProvider>
      <Router>
        <Switch>
          {/* Website Routes */}
          <Route path="/" exact component={ComingSoon} />
          <Route path="/home" exact component={Home} />
          <Route path="/products" exact component={Products} />
          <Route path="/view" exact component={View} />
          <Route path="/cart" exact component={Cart} />
          <Route path="/checkout" exact component={Checkout} />
          <Route path="/checkoutideal" exact component={Checkoutideal} />
          <Route path="/track" exact component={Track} />
          <Route path="/wishlist" exact component={Wishlist} />
          <Route path="/thankyou" exact component={ThankYou} />
          <Route path="/about" exact component={About} />
          <Route path="/designers" exact component={Designers} />
          <Route path="/newin" exact component={Newin} />
          <Route path="/sale" exact component={Sale} />
          <Route path="/faq" exact component={FAQ} />
          <Route path="/contact" exact component={Contact} />

          {/* Documents Routes */}
          <Route path="/return" exact component={Return} />
          <Route path="/delivery" exact component={Delivery} />
          <Route path="/terms" exact component={Terms} />
          <Route path="/privacy" exact component={Privacy} />
          <Route path="/shipping" exact component={Shipping} />
          <Route path="/disclaimer" exact component={Disclaimer} />
          <Route path="/cookie" exact component={Cookie} />
          <Route path="/paymentdocs" exact component={PaymentDocs} />

          <AuthProvider>
            <Route path="/signin" exact component={SignIn} />
            <Route path="/register" exact component={Register} />
            <AdminRoutes />
          </AuthProvider>
        </Switch>
      </Router>
    </ToastProvider>
  );
}

export default App;
