import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import Container from "@material-ui/core/Container";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import { mainListItems } from "./listitems";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import { useAuth } from "../contexts/AuthContext";
import axios from 'axios';
const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: "none",
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  fixedHeight: {
    height: 240,
  },
}));

export default function MainStore() {
  const { logout } = useAuth();
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);

  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };
  const handleSignOut = () => {
    // console.log(auth.currentUser)
    logout();
  };

  const updateProducts = () => {
    axios.get('https://label-updater.houseofplug.com:8443/api/update-mclabels', {
      headers: {
        "X-Auth-Token": 'arm10KWKNTl8DDqPWkw6qVxmITQPIn'
      }
    })
    .then(function (response) {
      console.log(response.headers)
    }).catch(err => console.log(err))
  } 
  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="absolute"
        className={clsx(classes.appBar, open && classes.appBarShift)}
      >
        <Toolbar className={classes.toolbar}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            className={clsx(
              classes.menuButton,
              open && classes.menuButtonHidden
            )}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            component="h1"
            variant="h6"
            color="inherit"
            noWrap
            className={classes.title}
          >
            Dashboard
          </Typography>{" "}
          <Button variant="contained" color="secondary" onClick={handleSignOut}>
            Signout
          </Button>
          <IconButton color="inherit"></IconButton>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        classes={{
          paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
        }}
        open={open}
      >
        <div className={classes.toolbarIcon}>
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <Divider />
        <List>{mainListItems}</List>
      </Drawer>
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          <h2>CATEGORIES</h2>

          <Button
            onClick={updateProducts}
            size="large"
            style={{ color: "#ffffff", backgroundColor: "#0074D9", float: 'right', marginBottom: '4px' }}
            variant="contained"
          >
            UPDATE PRODUCTS
          </Button>


          <Grid container spacing={3}>
            <Grid item xs={6}>
              <Paper className={classes.paper}>
                <center>
                  <img src="https://img.icons8.com/color/96/000000/red-purse.png" />
                  <h2>BAGS</h2>
                </center>
                <Button
                  href="/adminallproducts?id=bags"
                  style={{ color: "#ffffff", backgroundColor: "#0074D9" }}
                  size="large"
                  fullWidth
                  variant="contained"
                >
                  VIEW BAGS
                </Button>
              </Paper>
            </Grid>
            <Grid item xs={6}>
              <Paper className={classes.paper}>
                <center>
                  <img src="https://img.icons8.com/color/96/000000/scarf.png" />
                  <h2>ACCESSORIES</h2>
                </center>
                <Button
                  href="/adminallproducts?id=accessories"
                  style={{ color: "#ffffff", backgroundColor: "#0074D9" }}
                  size="large"
                  fullWidth
                  variant="contained"
                >
                  VIEW ACCESSORIES
                </Button>
              </Paper>
            </Grid>

            <Grid item xs={6}>
              <Paper className={classes.paper}>
                <center>
                  <img src="https://img.icons8.com/color/96/000000/formal-outfit.png" />
                  <h2>CLOTHING</h2>
                </center>
                <Button
                  href="/adminallproducts?id=clothing"
                  style={{ color: "#ffffff", backgroundColor: "#0074D9" }}
                  size="large"
                  fullWidth
                  variant="contained"
                >
                  VIEW CLOTHING
                </Button>
              </Paper>
            </Grid>
            <Grid item xs={6}>
              <Paper className={classes.paper}>
                <center>
                  <img src="https://img.icons8.com/color/96/000000/trainers.png" />
                  <h2>SHOES</h2>
                </center>
                <Button
                  href="/adminallproducts?id=shoes"
                  style={{ color: "#ffffff", backgroundColor: "#0074D9" }}
                  size="large"
                  fullWidth
                  variant="contained"
                >
                  VIEW SHOES
                </Button>
              </Paper>
            </Grid>
          </Grid>
        </Container>
      </main>
    </div>
  );
}
