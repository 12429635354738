import React from 'react';

import HeaderLangingPage from '../components/HeaderLangingPage';
import FooterLandingPage from '../components/FooterLandingPage';

const Delivery = () => {
    return (
        <>
            <HeaderLangingPage />         
            <br />

            <div id="tt-pageContent">
                <div class="container-indent">
                    <div class="container">

                    <h2>
                    Delivery
                    </h2>
                    <small>
                    Last updated February 24, 2021.
                    </small>

                    <p>
                        House of plug ships worldwide using DHL Express Service. We offer FREE shipping for all orders within EUROPE. The shipping costs varies depending on the shipping country and the total amount of the order. You can see the shipping cost directly in the cart before confirming the order, just after filling shipping details.
                        <br />
                        Once the order is ready to dispatch, shipping times for DHL Express Service are the following:
                        <br />
                        • Europe and USA: 	3-5 	working days
                        <br />
                        • Rest of the world: 	3-7 	working days
                        <br />
                        <br />
                        <h2>
                        Shipping cost:
                        </h2>
                        • Within Europe: FREE delivery on all orders within Europe.
                        <br />
                        • Outside Europe: FREE shipping on all orders over € 400, - 
                        <br />
                        • Outside Europe: We will charge € 25, - for shipping, if the order is below € 400, - 
                        <br />
                        As soon as the order is placed, you will receive a confirmation email with a recap of the purchased product/s.<b> <u> Order placed using immediate payment methods will be ready to dispatch in 24/48h, while others will be processed after the payment has been received.</u></b>
                    </p>
                    </div>
                </div>
            </div>

            <FooterLandingPage />
        </>
    )
}

export default Delivery
