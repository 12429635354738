import React from 'react';

import HeaderLangingPage from '../components/HeaderLangingPage';
import FooterLandingPage from '../components/FooterLandingPage';

import './about.css'

const About = () => {
    return (
        <>
            <HeaderLangingPage />         
            <br />
            <div style={{ marginTop: '-34px', marginBottom: '-60px' }}>
            <div className="bg-img" id="tt-pageContent">
                <div class="container-indent">
                    <div class="container">
                        <h2 className="text-center mt-2">
                        ABOUT US
                        </h2>
                        <div className="about-text">
                            <p style={{fontSize: "20px"}}className="text-dark text-center">
                            House of Plug is a high-end online fashion platform based in the Dutch “flower capital” - Haarlem. Our mission is to provide the most unique pieces within a secure shopping platform, and we’ve just started our journey in 2021. The process starts with hand-picking our collection, combining a variety of young fashion talents with high-end boutique fashion brands for an ever interesting collection. Our relentless work ethic has established our reputation as a sophisticated and trustworthy retail fashion platform. House of Plug shares a burning desire for our collection of must-have items, rare-to-find pieces and unique products from emerging brands reaching customers all over Europe.                                <br />
                                <br />
                                Thank you for taking the time to browse through our collection. Feel free to reach out on our social media or through our contact information below.                            </p>
                        </div>
                        <center className="mt-5">
                        <img style={{ width: '110px' }} src="https://res.cloudinary.com/dx9dnqzaj/image/upload/v1622612723/ecommerse2/Signature_fixed_size_-_297_x_261_px_ox06fx.png" /> 
                        </center>
                        
                    </div>
                </div>
            </div>
            </div>

            <FooterLandingPage />
        </>
    )
}

export default About
