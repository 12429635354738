import React from "react";
import axios from "axios";
import CircularProgress from "@material-ui/core/CircularProgress";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import { v4 as uuidv4 } from "uuid";
import CloseIcon from "@material-ui/icons/Close";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import queryString from "query-string";
import Slider from "@material-ui/core/Slider";
import { makeStyles } from "@material-ui/core/styles";
import Chip from "@material-ui/core/Chip";

// Components
import HeaderLangingPage from "../components/HeaderLangingPage";
import FooterLandingPage from "../components/FooterLandingPage";

// Extras
import { API_SERVICE } from "../config/URI";
import { firestore } from "../Firebase/index";
import { colors, categoryBags, categoryAccessories } from "../config/Filters";

const useStyles = makeStyles((theme) => ({
  root: {
    width: 300,
  },
  chiproot: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    "& > *": {
      margin: theme.spacing(0.5),
    },
  },
}));

function valuetext(value) {
  return `${value}`;
}

const ProductList = ({
  handleClickOpenDialog,
  product,
  addWish,
  addProduct,
}) => {
  var images1 = product?.displayImage;
  var images2 = product?.displayImage;
  return (
    <>
      <div class="col-6 col-md-4 tt-col-item">
        <div class="tt-product thumbprod-center product-nohover">
          <div class="tt-image-box">
            <a
              href="#"
              class="tt-btn-quickview"
              onClick={() => handleClickOpenDialog(product)}
              data-tooltip="Quick View"
              data-tposition="left"
            ></a>
            <a
              href="#"
              class="tt-btn-wishlist"
              onClick={() => addWish(product)}
              data-tooltip="Add to Wishlist"
              data-tposition="left"
            ></a>
            <a href={`/view?id=${product._id}`}>
              <span class="tt-img">
                <img src={images1} alt="" />
              </span>
              <span class="tt-img-roll-over">
                <img src={images2} alt="" />
              </span>
            </a>
          </div>
          <div class="tt-description">
            <h2 class="tt-title">
              <a href={`/view?id=${product._id}`}>{product.product.Brand}</a>
            </h2>
            <a href={`/view?id=${product._id}`}>{product.product.Category}</a>
            <div>
              <p> {product.disc} €</p>
            </div>
            <div class="tt-price text-muted">
              <p style={{ textDecoration: "line-through", fontSize: '20px' }}>
                {" "}
                € {product.price}{" "}
              </p>
            </div>
            <div class="tt-price">€ {product.discPrice}</div>
            <small>VAT Included</small>
            <div class="tt-product-inside-hover">
              <div class="tt-row-btn">
                <a
                  href="#!"
                  onClick={() => addProduct(product)}
                  className="tt-btn-addtocart thumbprod-button-bg"
                >
                  ADD TO CART
                </a>
              </div>
              <div class="tt-row-btn">
                <a
                  href="#"
                  class="tt-btn-quickview"
                  data-toggle="modal"
                  data-target="#ModalquickView"
                ></a>
                <a
                  href="#!"
                  onClick={() => addWish(product)}
                  class="tt-btn-wishlist"
                ></a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const Sale = ({ location }) => {
  const productFor = "Sale";

  const [colorFilterName, setcolorFilterName] = React.useState("All Colors");
  const [categoryFilterName, setcategoryFilterName] =
    React.useState("All Categories");
  const [sizeFilterName, setsizeFilterName] = React.useState("All Sizes");

  const [open, setOpen] = React.useState(false);
  const [quickviewproduct, setquickviewproduct] = React.useState(null);
  const [qty, setqty] = React.useState(1);
  const [value, setValue] = React.useState([100, 5000]);
  const [valueDisc, setValueDisc] = React.useState([10, 100]);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleChangeDisc = (event, newValue) => {
    setValueDisc(newValue);
  };

  React.useEffect(() => {
    filterItems();
  }, [colorFilterName, categoryFilterName, sizeFilterName, value, valueDisc]);

  const handleClick = () => {
    setOpen(true);
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const [openDialog, setOpenDialog] = React.useState(false);

  const handleClickOpenDialog = (product) => {
    setquickviewproduct(product);
    setOpenDialog(true);
  };
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const [products, setproducts] = React.useState([]);
  const [loading, setloading] = React.useState(true);
  const [userId, setuserId] = React.useState("");
  const [message, setmessage] = React.useState("");

  const [productName, setproductName] = React.useState("");
  const [brandName, setbrandName] = React.useState("");
  const [productType, setproductType] = React.useState("");

  React.useEffect(() => {
    if (localStorage.getItem("userId") === null) {
      localStorage.setItem("userId", uuidv4() + Date.now());
    } else {
      var userId = localStorage.getItem("userId");
      setuserId(userId);
    }

    var { p, t, b } = queryString.parse(location.search);
    setproductName(p.toUpperCase());
    setproductType(t.toUpperCase());
    if (b) {
      setbrandName(b.toUpperCase());
    } else {
      b = "ALL";
      setbrandName("ALL");
    }

    // axios
    //   .get(
    //     `${API_SERVICE}/api/v1/main/getallproductsmainotherstore/${p.toUpperCase()}/${t.toUpperCase()}/${b.toUpperCase()}/${productFor}`
    //   )
    //   .then((response) => {
    //     setproducts(response.data);
    //     setquickviewproduct(response.data[0]);
    //     setloading(false);
    //   })
    //   .catch((err) => console.log(err));

    axios
      .get(`${API_SERVICE}/api/v1/main/getallsaleproduct/${productFor}`)
      .then((response) => {
        console.log(response.data);
        setproducts(response.data);
        setquickviewproduct(response.data[0]);
        setloading(false);
      })
      .catch((err) => console.log(err));
  }, []);

  const refreshList = () => {
    setloading(true);
    setcategoryFilterName("All Categories");
    setsizeFilterName("All Sizes");
    setcolorFilterName("All Colors");
    setValue([100, 5000]);
    setValueDisc([10, 100]);
    axios
      .get(
        `${API_SERVICE}/api/v1/main/getallproductsmainotherstore/${productName}/${productType}/${brandName}/${productFor}`
      )
      .then((response) => {
        setproducts(response.data);
        setquickviewproduct(response.data[0]);
        setloading(false);
      })
      .catch((err) => console.log(err));
  };

  const filterItems = () => {
    var priceFrom = value[0];
    var priceTo = value[1];
    var discFrom = valueDisc[0];
    var discTo = valueDisc[1];
    setloading(true);
    if (
      colorFilterName === "All Colors" &&
      brandName === "ALL" &&
      priceFrom == 100 &&
      priceTo == 5000 &&
      discFrom == 10 &&
      discTo == 100 &&
      categoryFilterName === "All Categories" &&
      sizeFilterName === "All Sizes"
    ) {
    } else {
      axios
        .get(
          `${API_SERVICE}/api/v1/main/getallproductsmainfilterother/${colorFilterName}/${categoryFilterName}/${sizeFilterName}/${productName}/${priceFrom}/${priceTo}/${discFrom}/${discTo}/${brandName}/${productFor}`
        )
        .then((response) => {
          setproducts(response.data);
          setquickviewproduct(response.data[0]);
          setloading(false);
        })
        .catch((err) => console.log(err));
    }
  };

  const priceSorting = (sort) => {
    setloading(true);
    axios
      .get(
        `${API_SERVICE}/api/v1/main/getallproductsmainfilterpriceother/${colorFilterName}/${categoryFilterName}/${sizeFilterName}/${productName}/${sort}/${brandName}/${productFor}`
      )
      .then((response) => {
        setproducts(response.data);
        setquickviewproduct(response.data[0]);
        setloading(false);
      })
      .catch((err) => console.log(err));
  };

  const addProduct = (product) => {
    handleCloseDialog();
    var docRef = firestore.collection("cart").doc(userId);
    docRef
      .get()
      .then(function (doc) {
        if (doc.exists) {
          var items = doc.data().items;
          if (items === 10000) {
            console.log("Maximum Number of Items in Cart Exceed");
          } else {
            var uploadData = {
              userId,
              title: product.title,
              qty,
              image: product.displayImage,
              price: Number(product.price),
              product,
            };
            axios
              .post(`${API_SERVICE}/api/v1/main/addproducttocart`, uploadData)
              .then((response) => {
                if (response.status === 200) {
                  items = items + 1;
                  docRef.set(
                    {
                      items,
                    },
                    { merge: true }
                  );
                  setmessage("Item Added to Cart");
                  handleClick();
                } else if (response.status === 201) {
                  setmessage("Item already in Cart");
                  handleClick();
                }
              })
              .catch((err) => console.log(err));
          }
        } else {
          docRef.set(
            {
              items: 1,
            },
            { merge: true }
          );
          var uploadData = {
            userId,
            title: product.title,
            qty,
            image: product.displayImage,
            price: Number(product.price),
            product,
          };
          axios
            .post(`${API_SERVICE}/api/v1/main/addproducttocart`, uploadData)
            .then((response) => {
              if (response.status === 200) {
                setmessage("Item Added to Cart");
                handleClick();
              }
            })
            .catch((err) => console.log(err));
        }
      })
      .catch(function (error) {
        console.log("Error getting document:", error);
      });
  };

  const addWish = (product) => {
    var uploadData = {
      userId,
      product,
    };
    axios
      .post(`${API_SERVICE}/api/v1/main/addproducttowish`, uploadData)
      .then((response) => {
        if (response.status === 200) {
          setmessage("Item Added to Wishlist");
          handleClick();
        }
      })
      .catch((err) => console.log(err));
  };

  const showProductList = () => {
    return products.map((product) => {
      return (
        <ProductList
          handleClickOpenDialog={handleClickOpenDialog}
          addWish={addWish}
          addProduct={addProduct}
          product={product}
          key={product.Sku}
        />
      );
    });
  };

  const changeSize = (s) => {
    setsizeFilterName(s);
    console.log(s);
  };

  return (
    <>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        open={open}
        autoHideDuration={4000}
        onClose={handleClose}
        message={message}
        action={
          <React.Fragment>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={handleClose}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />
      {loading === false ? (
        products && products.length ? (
          <Dialog
            open={openDialog}
            onClose={handleCloseDialog}
            fullWidth={true}
            maxWidth="md"
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title"></DialogTitle>

            <DialogContent>
              <div class="modal-body">
                <div class="tt-modal-quickview desctope">
                  <div class="row">
                    <div className="col-12 col-md-5 col-lg-6">
                      <div className="tt-mobile-product-slider arrow-location-center slick-initialized slick-slider slick-dotted">
                        <img
                          src={quickviewproduct.displayImage}
                          alt=""
                          class="loading"
                          data-was-processed="true"
                        />
                      </div>
                    </div>
                    <div class="col-12 col-md-7 col-lg-6">
                      <div class="tt-product-single-info">
                        {/* <div class="tt-add-info">
                                                    <ul>
                                                        <li><span>SKU:</span> {quickviewproduct.product.Sku}</li>
                                                        <li><span>Made In:</span> {quickviewproduct.product.MadeIn}</li>
                                                        <li><span>Size:</span> {quickviewproduct.product.Variants[0].Size}</li>
                                                    </ul>
                                                </div> */}
                        <h4>{quickviewproduct.product.Brand}</h4>
                        <h4>€ {quickviewproduct.price} (VAT Inc.)</h4>
                        <div class="tt-wrapper">
                          {quickviewproduct.description}
                        </div>
                        <div class="tt-swatches-container">
                          {/* <div class="tt-wrapper">
                                                        <div class="tt-title-options">QTY</div>
                                                        <form class="form-default">
                                                            <div class="form-group">
                                                                <select value={qty} onChange={(e) => setqty(e.target.value)} class="form-control">
                                                                    <option value="1">1</option>
                                                                    <option value="2">2</option>
                                                                    <option value="3">3</option>
                                                                </select>
                                                            </div>
                                                        </form>
                                                    </div> */}
                          {/* <div class="tt-wrapper">
                                                        <div class="tt-title-options">COLOR</div>
                                                        <form class="form-default">
                                                            <div class="form-group">
                                                                <select class="form-control">
                                                                    <option>Red</option>
                                                                    <option>Green</option>
                                                                    <option>Brown</option>
                                                                </select>
                                                            </div>
                                                        </form>
                                                    </div> */}
                        </div>
                        <div class="tt-wrapper">
                          <div class="tt-row-custom-01">
                            <div class="col-item">
                              <a
                                href={`/view?id=${quickviewproduct._id}`}
                                style={{
                                  backgroundColor: "#f7f8fa",
                                  color: "#000000",
                                }}
                                class="btn btn-lg"
                              >
                                View Item
                              </a>
                            </div>
                            <div class="col-item">
                              <a
                                href="#!"
                                onClick={() => addProduct(quickviewproduct)}
                                class="btn btn-lg"
                              >
                                <i class="icon-f-39"></i>ADD TO CART
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseDialog} color="primary">
                Close
              </Button>
            </DialogActions>
          </Dialog>
        ) : null
      ) : null}

      <HeaderLangingPage />
      <br />
      <div id="tt-pageContent">
        <div class="container-indent">
          <div class="container">
            <div class="row">
              <div
                class="col-md-4 col-lg-3 col-xl-3 leftColumn aside"
                id="js-leftColumn-aside"
              >
                <div class="tt-btn-col-close">
                  <a href="#">Close</a>
                </div>
                <div class="tt-collapse open tt-filter-detach-option">
                  <div class="tt-collapse-content">
                    <div class="filters-mobile">
                      <div class="filters-row-select"></div>
                    </div>
                  </div>
                </div>
                <div class="tt-collapse open">
                  <h3 class="tt-collapse-title">FILTER BY PRICE</h3>
                  <div class="tt-collapse-content">
                    <span className="priceslider">
                      {`€ ${value[0]}`} - {`€ ${value[1]}`}
                    </span>
                    <Slider
                      value={value}
                      min={100}
                      max={5000}
                      onChange={handleChange}
                      valueLabelDisplay="auto"
                      aria-labelledby="range-slider"
                      getAriaValueText={valuetext}
                      style={{ color: "#000000" }}
                    />
                    <center>OR</center>
                    <br />
                    <ul class="tt-list-row">
                      <li class="active">
                        <a onClick={() => priceSorting(-1)} href="#">
                          Price High to Low
                        </a>
                      </li>
                      <li class="active">
                        <a onClick={() => priceSorting(1)} href="#">
                          Price Low to High
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>

                <div class="tt-collapse open">
                  <h3 class="tt-collapse-title">FILTER BY DISCOUNT</h3>
                  <div class="tt-collapse-content">
                    <span className="priceslider">
                      {`${valueDisc[0]} %`} - {`${valueDisc[1]} %`}
                    </span>
                    <Slider
                      value={valueDisc}
                      min={10}
                      max={100}
                      onChange={handleChangeDisc}
                      valueLabelDisplay="auto"
                      aria-labelledby="range-slider"
                      getAriaValueText={valuetext}
                      style={{ color: "#000000" }}
                    />
                  </div>
                </div>

                <div class="tt-collapse open">
                  <h3 class="tt-collapse-title">CATEGORIES</h3>
                  <div class="tt-collapse-content">
                    <ul class="tt-list-row">
                      <li class="active">
                        <a href="/sale?p=bags&t=all#!">BAGS</a>
                      </li>
                      <li class="active">
                        <a href="/sale?p=accessories&t=all#!">ACCESSORIES</a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="tt-collapse open">
                  <h3 class="tt-collapse-title">PRODUCT CATEGORIES</h3>
                  <div class="tt-collapse-content">
                    <div class="ex3">
                      {productName === "BAGS" ? (
                        <ul class="tt-list-row">
                          {categoryBags.map((c) => (
                            <li class="active">
                              <a
                                onClick={() => setcategoryFilterName(c)}
                                href="#!"
                              >
                                {c}
                              </a>
                            </li>
                          ))}
                        </ul>
                      ) : productName === "ACCESSORIES" ? (
                        <ul class="tt-list-row">
                          {categoryAccessories.map((c) => (
                            <li class="active">
                              <a
                                onClick={() => setcategoryFilterName(c)}
                                href="#"
                              >
                                {c}
                              </a>
                            </li>
                          ))}
                        </ul>
                      ) : null}
                    </div>
                  </div>
                </div>
                <div class="tt-collapse open">
                  <h3 class="tt-collapse-title">COLORS</h3>
                  <div class="tt-collapse-content">
                    <div class="ex3">
                      {
                        <ul class="tt-list-row">
                          {colors.map((c) => (
                            <li class="active">
                              <a
                                onClick={() => setcolorFilterName(c)}
                                href="#!"
                              >
                                {c}
                              </a>
                            </li>
                          ))}
                        </ul>
                      }
                    </div>
                  </div>
                </div>

                <div class="tt-collapse open">
                  <h3 class="tt-collapse-title">FILTER BY SIZE</h3>
                  <div class="tt-collapse-content">
                    {productName === "BAGS" ? (
                      <ul class="tt-list-row">
                        <li class="active">
                          <a href="#!" onClick={() => changeSize("UNI")}>
                            UNI
                          </a>
                        </li>
                      </ul>
                    ) : productName === "ACCESSORIES" ? (
                      <ul class="tt-list-row">
                        <li class="active">
                          <a onClick={() => setsizeFilterName("UNI")} href="#!">
                            UNI
                          </a>
                        </li>
                        <li class="active">
                          <a onClick={() => setsizeFilterName("40")} href="#!">
                            40 cm
                          </a>
                        </li>
                        <li class="active">
                          <a onClick={() => setsizeFilterName("38")} href="#">
                            38 cm
                          </a>
                        </li>
                        <li class="active">
                          <a onClick={() => setsizeFilterName("85")} href="#">
                            85 cm
                          </a>
                        </li>
                        <li class="active">
                          <a onClick={() => setsizeFilterName("95")} href="#">
                            95 cm
                          </a>
                        </li>
                        <li class="active">
                          <a onClick={() => setsizeFilterName("100")} href="#">
                            100 cm
                          </a>
                        </li>
                        <li class="active">
                          <a onClick={() => setsizeFilterName("S")} href="#">
                            S
                          </a>
                        </li>
                        <li class="active">
                          <a onClick={() => setsizeFilterName("L")} href="#">
                            L
                          </a>
                        </li>
                        <li class="active">
                          <a onClick={() => setsizeFilterName("M")} href="#">
                            M
                          </a>
                        </li>
                      </ul>
                    ) : null}
                  </div>
                </div>
              </div>
              <div class="col-md-12 col-lg-9 col-xl-9">
                <div class="content-indent container-fluid-custom-mobile-padding-02">
                  <div class="tt-filters-options" id="js-tt-filters-options">
                    <h1 class="tt-title">
                      Sale{" "}
                      <span class="tt-title-total">{`(${productType})`}</span>
                    </h1>
                    <div class="tt-btn-toggle">
                      <a href="#">FILTER</a>
                    </div>
                    <div class="tt-sort">
                      <select onChange={(e) => priceSorting(e.target.value)}>
                        <option selected disabled>
                          Show All
                        </option>
                        <option value="-1">Price High to Low</option>
                        <option value="1">Price Low to High</option>
                      </select>
                    </div>
                    <div class="tt-quantity">
                      <a
                        href="#"
                        class="tt-col-one"
                        data-value="tt-col-one"
                      ></a>
                      <a
                        href="#"
                        class="tt-col-two"
                        data-value="tt-col-two"
                      ></a>
                      <a
                        href="#"
                        class="tt-col-three"
                        data-value="tt-col-three"
                      ></a>
                    </div>
                  </div>

                  <Chip style={{ marginTop: "10px" }} label={colorFilterName} />
                  <Chip
                    style={{ marginTop: "10px", marginLeft: "4px" }}
                    label={categoryFilterName}
                  />
                  <Chip
                    style={{ marginTop: "10px", marginLeft: "4px" }}
                    label={sizeFilterName}
                  />
                  <Button
                    onClick={refreshList}
                    style={{
                      marginTop: "10px",
                      marginLeft: "4px",
                      color: "#000000",
                    }}
                    color="primary"
                  >
                    Reset Filters
                  </Button>

                  <div class="tt-product-listing row">
                    {loading === true ? (
                      <center style={{ marginTop: "10%", marginLeft: "50%" }}>
                        <CircularProgress />
                      </center>
                    ) : (
                      <>{showProductList()}</>
                    )}
                  </div>
                  {/* <div class="text-center tt_product_showmore">
                                        <a href="#" class="btn btn-border">LOAD MORE</a>
                                        <div class="tt_item_all_js">
                                            <a href="#" class="btn btn-border01">NO MORE ITEM TO SHOW</a>
                                        </div>
                                    </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <FooterLandingPage />
    </>
  );
};

export default Sale;
