import react from "react";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";

import SignIn from "../Dashboard/Signin";
import DashboardHome from "../Dashboard/Home";
import MainStore from "../Dashboard/MainStore";
import EditProduct from "../Dashboard/EditProduct";
import Edit from "../Dashboard/Edit";
import EditProductNew from "../Dashboard/EditProductNew";
import EditProductSale from "../Dashboard/EditProductSale";
import EditProductWeek from "../Dashboard/EditProductWeek";
import EditProductRecommend from "../Dashboard/EditProductRecommend";
import Orders from "../Dashboard/Orders";
import OrderDetails from "../Dashboard/OrderDetails";
import FeaturedProduct from "../Dashboard/FeaturedProduct";
import HomeProducts from "../Dashboard/HomeProducts";
import NewInDashboard from "../Dashboard/NewIn";
import SaleDashboard from "../Dashboard/Sale";
import WeekProductsDashboard from "../Dashboard/WeekProducts";
import RecommendedDashboard from "../Dashboard/Recommended";
import Coupon from "../Dashboard/Coupon";
import CustomProducts from "../Dashboard/CustomProducts";

import { useAuth } from "../contexts/AuthContext";

const AdminRoutes = () => {
  const { currentUser } = useAuth();
  return (
    <>
      {currentUser ? (
        <>
          <Route path="/admin" exact component={DashboardHome} />
          <Route path="/admindashboard" exact component={DashboardHome} />
          <Route path="/adminmainstore" exact component={MainStore} />
          <Route path="/adminmainstoreedit" exact component={EditProduct} />
          <Route path="/adminmainstoreorders" exact component={Orders} />
          <Route
            path="/adminmainstoreordersdetails"
            exact
            component={OrderDetails}
          />
          <Route
            path="/adminmainfeaturedproduct"
            exact
            component={FeaturedProduct}
          />
          <Route path="/adminallproducts" exact component={HomeProducts} />
          <Route path="/adminallnewin" exact component={NewInDashboard} />
          <Route path="/adminallsale" exact component={SaleDashboard} />
          <Route path="/adminallweek" exact component={WeekProductsDashboard} />
          <Route
            path="/adminrecomended"
            exact
            component={RecommendedDashboard}
          />
          <Route path="/adminedit" exact component={Edit} />
          <Route path="/admineditnew" exact component={EditProductNew} />
          <Route path="/admineditsale" exact component={EditProductSale} />
          <Route path="/admineditweek" exact component={EditProductWeek} />
          <Route
            path="/admineditrecommend"
            exact
            component={EditProductRecommend}
          />
          <Route path="/admincoupon" exact component={Coupon} />
          <Route path="/admincustomproducts" exact component={CustomProducts} />
        </>
      ) : (
        <>
          <Route path="/admin" exact component={SignIn} />
          <Redirect to="/admin" path="/admindashboard" exact />
          <Redirect to="/admin" path="/adminmainstore" exact />
          <Redirect to="/admin" path="/adminmainstoreedit" exact />
          <Redirect to="/admin" path="/adminmainstoreorders" exact />
          <Redirect to="/admin" path="/adminmainstoreordersdetails" exact />
          <Redirect to="/admin" path="/adminmainfeaturedproduct" exact />
          <Redirect to="/admin" path="/adminallproducts" exact />
          <Redirect to="/admin" path="/adminallnewin" exact />
          <Redirect to="/admin" path="/adminallsale" exact />
          <Redirect to="/admin" path="/adminallweek" exact />
          <Redirect to="/admin" path="/adminrecomended" />
          <Redirect to="/admin" path="/adminedit" exact />
          <Redirect to="/admin" path="/admineditnew" exact />
          <Redirect to="/admin" path="/admineditsale" exact />
          <Redirect to="/admin" path="/admineditweek" exact />
          <Redirect to="/admin" path="/admineditrecommend" exact />
          <Redirect to="/admin" path="admincoupon" exact />
        </>
      )}
    </>
  );
};
export default AdminRoutes;
