import React from "react";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import Accessories from "../images/ACCESSORIES.jpg"
import NewIn from "../images/NEW IN.jpg"
import AllBags from "../images/ALL BAGS.jpg"
import Designer from "../images/DESIGNERS.jpg"
// Components
import HeaderLangingPage from "../components/HeaderLangingPage";
import FooterLandingPage from "../components/FooterLandingPage";

// Extras
import { API_SERVICE } from "../config/URI";

import { BrowserView, MobileView, isMobile } from "react-device-detect";

//Image

const ProductList = ({ product }) => {
  return (
    <div class="col-6 col-md-3">
      <div class="tt-promo02 text-center">
        <a href={`/view?id=${product.productId}`} class="image-box">
          <img
            src={product.displayImage}
            data-src={product.displayImage}
            alt=""
          />
        </a>
        <div class="tt-description">
          <a href="#" class="tt-title">
            <div class="tt-title-small">
              <a className="text-dark" href={`/view?id=${product.productId}`} style={{
                fontSize:"20px"
              }}>
                {product.title}
              </a>
            </div>
            <div class="tt-product-inside-hover mt-2">
              <div class="tt-row-btn">
                <a
                  href={`/view?id=${product.productId}`}
                  class="tt-btn-addtocart thumbprod-button-bg"
                >
                  VIEW NOW
                </a>
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>
  );
};

const ProductListWeek = ({ product }) => {
  return (
    <div class="col-6 col-md-3">
      <div class="tt-promo02 text-center">
        <a href={`/view?id=${product.productId}`} class="image-box">
          <img
            src={product.displayImage}
            data-src={product.displayImage}
            alt=""
          />
        </a>
        <div class="tt-description">
          <a href="#" class="tt-title">
            <div class="tt-title-small">
              <a className="text-dark" href={`/view?id=${product.productId}`}>
                {product.title}
              </a>
            </div>
            <div class="tt-product-inside-hover mt-2">
              <div class="tt-row-btn">
                <a
                  href={`/view?id=${product.productId}`}
                  class="tt-btn-addtocart thumbprod-button-bg"
                >
                  VIEW NOW
                </a>
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>
  );
};

const Home = () => {
  const [products, setproducts] = React.useState([]);
  const [productsweek, setproductsweek] = React.useState([]);
  const [loading, setloading] = React.useState(true);
  const [userId, setuserId] = React.useState("");

  React.useEffect(() => {
    if (localStorage.getItem("userId") === null) {
      localStorage.setItem("userId", uuidv4() + Date.now());
    } else {
      var userId = localStorage.getItem("userId");
      console.log(userId);
    }
    axios
      .get(`${API_SERVICE}/api/v1/main/gethomepagefeaturedproductlist`)
      .then((response) => {
        setproducts(response.data);
        axios
          .get(`${API_SERVICE}/api/v1/main/gethomepageweekproductlist`)
          .then((response) => {
            setproductsweek(response.data);
            setloading(false);
          })
          .catch((err) => console.log(err));
      })
      .catch((err) => console.log(err));

    fetch("https://extreme-ip-lookup.com/json/")
      .then((res) => res.json())
      .then((response) => {
        console.log("Country: ", response.country);
      })
      .catch((data, status) => {
        console.log("Request failed");
      });

    if (isMobile) {
      // window.scroll(0, 80);
    }
  }, []);

  const showProductList = () => {
    return products.map((product) => {
      return <ProductList product={product} key={product._id} />;
    });
  };

  const showProductListWeek = () => {
    return productsweek.map((product) => {
      return <ProductListWeek product={product} key={product._id} />;
    });
  };

  return (
    <>
      <HeaderLangingPage video={1} />
      <div id="tt-pageContent">
        {/* NO USE */}
        <div hidden class="container-indent minus20marg">
          <div class="container">
            <a href="listing-left-column.html" class="tt-promo-box">
              <img
                src="images/loader.svg"
                data-src="images/skin-clothes/promo/promo-img-01.jpg"
                alt=""
              />
              <div class="tt-description">
                <div class="tt-description-wrapper">
                  <div class="tt-background"></div>
                  <div class="tt-title-small">Spring Layers to Wear Now</div>
                  <div class="tt-title-large">Minimal Collection</div>
                </div>
              </div>
            </a>
          </div>
        </div>
        {/* NO USE */}
        {/* NO USE */}

        <div hidden class="container-indent">
          <div class="container container-fluid-custom-mobile-padding">
            <div class="tt-block-title">
              <h2 class="tt-title specialfont">Shop by Category</h2>
            </div>
            <div class="row tt-collection-listing">
              <div class="col-sm-6 col-md-3 col-6">
                <a href="#" class="tt-collection-item hover-type-02">
                  <div class="tt-image-box">
                    <img
                      src="images/loader.svg"
                      data-src="images/skin-clothes/img-01.jpg"
                      alt=""
                    />
                  </div>
                  <div class="tt-description">
                    <h2 class="tt-title">New In</h2>
                  </div>
                </a>
              </div>
              <div class="col-sm-6 col-md-3 col-6">
                <a href="#" class="tt-collection-item hover-type-02">
                  <div class="tt-image-box">
                    <img
                      src="images/loader.svg"
                      data-src="images/skin-clothes/img-02.jpg"
                      alt=""
                    />
                  </div>
                  <div class="tt-description">
                    <h2 class="tt-title">Women</h2>
                  </div>
                </a>
              </div>
              <div class="col-sm-6 col-md-3 col-6">
                <a href="#" class="tt-collection-item hover-type-02">
                  <div class="tt-image-box">
                    <img
                      src="images/loader.svg"
                      data-src="images/skin-clothes/img-03.jpg"
                      alt=""
                    />
                  </div>
                  <div class="tt-description">
                    <h2 class="tt-title">Men</h2>
                  </div>
                </a>
              </div>
              <div class="col-sm-6 col-md-3 col-6">
                <a href="#" class="tt-collection-item hover-type-02">
                  <div class="tt-image-box">
                    <img
                      src="images/loader.svg"
                      data-src="images/skin-clothes/img-04.jpg"
                      alt=""
                    />
                  </div>
                  <div class="tt-description">
                    <h2 class="tt-title">Accessories</h2>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
        {/* NO USE */}

        <div class="container-indent">
          <BrowserView>
            <center>
              <video width="1180px" className="videoTag" autoPlay loop muted>
                <source
                  src="https://res.cloudinary.com/dx9dnqzaj/video/upload/v1632365989/ecommerse2/Website_variant_-_18_mb_pjbfbe.mp4"
                  type="video/mp4"
                />
              </video>
            </center>
          </BrowserView>
        </div>

        <div hidden class="container-indent">
          <div class="container">
            <div
              class="slider-revolution revolution-default"
              data-fullscreen="false"
              data-width="1180"
              data-height="670"
            >
              <div class="tp-banner-container">
                <div class="tp-banner revolution">
                  <ul>
                    <li
                      data-thumb="https://res.cloudinary.com/dx9dnqzaj/image/upload/v1618396940/ecommerse2/Banner_1_mc3l7x.png"
                      data-transition="fade"
                      data-slotamount="1"
                      data-masterspeed="1000"
                      data-saveperformance="off"
                      data-title="Slide"
                    >
                      <img
                        src="https://res.cloudinary.com/dx9dnqzaj/image/upload/v1618396940/ecommerse2/Banner_1_mc3l7x.png"
                        alt="slide1"
                        data-bgposition="center center"
                        data-bgfit="cover"
                        data-bgrepeat="no-repeat"
                      />
                      <div
                        class="tp-caption tp-clothes-tools lfl stl"
                        data-x="left"
                        data-y="center"
                        data-hoffset="162"
                        data-voffset="0"
                        data-speed="600"
                        data-start="900"
                        data-easing="Power4.easeOut"
                        data-endeasing="Power4.easeIn"
                      >
                        <div
                          style={{ color: "#ffffff" }}
                          class="tp-clothes-wd01 text-left"
                        >
                          Spring Layers to Wear Now
                        </div>
                        <div
                          style={{ color: "#ffffff" }}
                          class="tp-clothes-wd02 text-left"
                        >
                          Crepe Shirtdress
                        </div>
                        <div class="tp-clothes-wd03 text-left">
                          <a href="#" class="btn">
                            DISCOVER NOW!
                          </a>
                        </div>
                      </div>
                    </li>
                    <li
                      data-thumb="https://res.cloudinary.com/dx9dnqzaj/image/upload/v1618396930/ecommerse2/Banner_3_dy6tri.png"
                      data-transition="fade"
                      data-slotamount="1"
                      data-masterspeed="1000"
                      data-saveperformance="off"
                      data-title="Slide"
                    >
                      <img
                        src="https://res.cloudinary.com/dx9dnqzaj/image/upload/v1618396930/ecommerse2/Banner_3_dy6tri.png"
                        alt="slide1"
                        data-bgposition="center center"
                        data-bgfit="cover"
                        data-bgrepeat="no-repeat"
                      />
                      <div
                        class="tp-caption tp-clothes-tools lfl stl"
                        data-x="left"
                        data-y="center"
                        data-hoffset="162"
                        data-voffset="0"
                        data-speed="600"
                        data-start="900"
                        data-easing="Power4.easeOut"
                        data-endeasing="Power4.easeIn"
                      >
                        <div
                          style={{ color: "#ffffff" }}
                          class="tp-clothes-wd01 text-left"
                        >
                          Spring Layers to Wear Now
                        </div>
                        <div
                          style={{ color: "#ffffff" }}
                          class="tp-clothes-wd02 text-left"
                        >
                          Windbreaker Jacket
                        </div>
                        <div class="tp-clothes-wd03 text-left">
                          <a href="#" class="btn">
                            DISCOVER NOW!
                          </a>
                        </div>
                      </div>
                    </li>
                    <li
                      data-thumb="https://res.cloudinary.com/dx9dnqzaj/image/upload/v1618396921/ecommerse2/Banner_2_nnkr6c.png"
                      data-transition="fade"
                      data-slotamount="1"
                      data-masterspeed="1000"
                      data-saveperformance="off"
                      data-title="Slide"
                    >
                      <img
                        src="https://res.cloudinary.com/dx9dnqzaj/image/upload/v1618396921/ecommerse2/Banner_2_nnkr6c.png"
                        alt="slide1"
                        data-bgposition="center center"
                        data-bgfit="cover"
                        data-bgrepeat="no-repeat"
                      />
                      <div
                        class="tp-caption tp-clothes-tools lfl stl"
                        data-x="left"
                        data-y="center"
                        data-hoffset="162"
                        data-voffset="0"
                        data-speed="600"
                        data-start="900"
                        data-easing="Power4.easeOut"
                        data-endeasing="Power4.easeIn"
                      >
                        <div
                          style={{ color: "#ffffff" }}
                          class="tp-clothes-wd01 text-left"
                        >
                          Spring Layers to Wear Now
                        </div>
                        <div
                          style={{ color: "#ffffff" }}
                          class="tp-clothes-wd02 text-left"
                        >
                          Crochet-Stripe Dress
                        </div>
                        <div class="tp-clothes-wd03 text-left">
                          <a href="#" class="btn">
                            DISCOVER NOW!
                          </a>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div hidden class="container-indent">
          <div class="container container-fluid-custom-mobile-padding">
            <div class="tt-block-title">
              <h2 class="tt-title">Shop by Category</h2>
            </div>
            <div class="row tt-collection-listing">
              <div class="col-sm-6 col-md-3 col-6">
                <a href="#" class="tt-collection-item hover-type-02">
                  <div class="tt-image-box">
                    <img
                      src="images/loader.svg"
                      data-src="images/skin-clothes/img-01.jpg"
                      alt=""
                    />
                  </div>
                  <div class="tt-description">
                    <h2 class="tt-title">CLUTCHES</h2>
                  </div>
                </a>
              </div>
              <div class="col-sm-6 col-md-3 col-6">
                <a href="#" class="tt-collection-item hover-type-02">
                  <div class="tt-image-box">
                    <img
                      src="images/loader.svg"
                      data-src="images/skin-clothes/img-02.jpg"
                      alt=""
                    />
                  </div>
                  <div class="tt-description">
                    <h2 class="tt-title">HANDBAGS</h2>
                  </div>
                </a>
              </div>
              <div class="col-sm-6 col-md-3 col-6">
                <a href="#" class="tt-collection-item hover-type-02">
                  <div class="tt-image-box">
                    <img
                      src="images/loader.svg"
                      data-src="images/skin-clothes/img-03.jpg"
                      alt=""
                    />
                  </div>
                  <div class="tt-description">
                    <h2 class="tt-title">SHOULDER</h2>
                  </div>
                </a>
              </div>
              <div class="col-sm-6 col-md-3 col-6">
                <a href="#" class="tt-collection-item hover-type-02">
                  <div class="tt-image-box">
                    <img
                      src="images/loader.svg"
                      data-src="images/skin-clothes/img-04.jpg"
                      alt=""
                    />
                  </div>
                  <div class="tt-description">
                    <h2 class="tt-title">Accessories</h2>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>

        <div  class="container-indent mb-5">
          <div class="container container-fluid-custom-mobile-padding">
            <div class="tt-block-title">
              <h4   className={isMobile? "heading-mobile specialfont":"heading-desktop specialfont" }
              >
                SHOP BY CATEGORIES
              </h4>
            </div>
            <div class="row tt-layout-promo02">
              <div class="col-6 col-md-3">
                <div class="tt-promo02 text-center">
                  <a href="/products?p=bags&t=all" class="image-box">
                    <img
                      className={isMobile ? "imgmobileheight" : "imgdesktopheight"}
                      data-src="https://res.cloudinary.com/dx9dnqzaj/image/upload/v1631160939/ecommerse2/ALL_BAGS_n897il.jpg"
                      alt=""
                    />
                  </a>
                  <div class="tt-description">
                    <a href="/products?p=bags&t=all" class="tt-title">
                      <div style={{ fontSize: '18px' }} class="tt-title-small">ALL BAGS</div>
                    </a>
                  </div>
                </div>
              </div>
              <div class="col-6 col-md-3">
                <div class="tt-promo02 text-center">
                  <a href="/newin?p=bags&t=all" class="image-box">
                    <img
                      className={isMobile ? "imgmobileheight" : "imgdesktopheight"}
                      data-src={NewIn}
                      alt=""
                    />
                  </a>
                  <div class="tt-description">
                    <a href="/newin?p=bags&t=all" class="tt-title">
                      <div style={{ fontSize: '18px' }} class="tt-title-small">NEW IN</div>
                    </a>
                  </div>
                </div>
              </div>
              <div class="col-6 col-md-3">
                <div class="tt-promo02 text-center">
                  <a href="/designers" class="image-box">
                    <img
                      className={isMobile ? "imgmobileheight" : "imgdesktopheight"}
                      data-src="https://res.cloudinary.com/dx9dnqzaj/image/upload/v1631160939/ecommerse2/DESIGNERS_wpsya4.jpg"
                      alt=""
                    />
                  </a>
                  <div class="tt-description">
                    <a href="/designers" class="tt-title">
                      <div style={{ fontSize: '18px' }} class="tt-title-small">DESIGNERS</div>
                    </a>
                  </div>
                </div>
              </div>
              <div class="col-6 col-md-3">
                <div class="tt-promo02 text-center">
                  <a href="/products?p=accessories&t=all" class="image-box">
                    <img
                      className={isMobile ? "imgmobileheight" : "imgdesktopheight"}
                      data-src="https://res.cloudinary.com/dx9dnqzaj/image/upload/v1631160939/ecommerse2/ACCESSORIES_kbinwa.jpg"
                      alt=""
                    />
                  </a>
                  <div class="tt-description">
                    <a href="/products?p=accessories&t=all" class="tt-title">
                      <div style={{ fontSize: '18px' }} class="tt-title-small">ACCESSORIES</div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div style={{ marginTop: "3vh" }} class="container-indent mb-5">
          <div class="container container-fluid-custom-mobile-padding">
            <div class="tt-block-title">
              <h4 style={{ fontSize: "24px" }} class="specialfont">
                FEATURED PRODUCTS
              </h4>
            </div>
            <div class="row tt-layout-promo02">
              {loading ? <></> : <>{showProductList()}</>}
            </div>
          </div>
        </div>
        {/* <div class="container" style={{position:"relative",height:"460px"}}>
          <img src="https://res.cloudinary.com/dx9dnqzaj/image/upload/v1628832293/ecommerse2/HOP_BANNER_NEW_wdbodw.jpg"
            style={{
              width:'100%',
              height:"100%"
            }}
          />
          <div style={{
            position:'absolute',
            top:'0',
            right:'0',
            maxWidth:"700px",
            padding:"20px 10px 20px 50px"
          }}>
            <div class="">
                <h4 className="font-weight-bold text-center">
                  House of plug offers a unique and secure shopping experience!
                </h4>

                <p
                  style={{ fontSize: "12px",marginTop:"-10px" }}
                  className="text-center text-secondary"
                >
                  Featuring young talents side-byside with some of the most
                  established fashion industries and upcoming retailers.
                </p>
              </div>
            
          </div>
        </div> */}

        <div style={{ marginTop: "0" }} class="container-indent">
          <div class="container container-fluid-custom-mobile-padding">
            <div class="row tt-layout-box01">
              <div class="col-img col-md-5">
                <BrowserView>
                  <img
                    src="https://res.cloudinary.com/dx9dnqzaj/image/upload/v1628948519/ecommerse2/wfvek0sew37awgc_ji07bb.jpg"
                    alt=""
                  />
                </BrowserView>
                <MobileView>
                  <img
                    src="https://res.cloudinary.com/dx9dnqzaj/image/upload/v1628948519/ecommerse2/wfvek0sew37awgc_ji07bb.jpg"
                    alt=""
                  />
                </MobileView>
              </div>
              <div class="col-description col-md-7">
                <h4 className="font-weight-bold text-center">
                  House of plug offers a unique and secure shopping experience!
                </h4>

                <p
                  style={{ fontSize: "18px" }}
                  className="text-center text-secondary"
                >
                  Featuring young talents side-by-side with some of the most
                  established fashion industries and upcoming retailers.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div style={{ marginTop: "5vh" }} class="container-indent mb-5">
          <div class="container container-fluid-custom-mobile-padding">
            <div style={{ paddingBottom: "0" }} class="tt-block-title">
              <h4
                style={{ fontSize: "24px", paddingBottom: "0" }}
                class="specialfont"
              >
                PRODUCTS OF THE WEEK
              </h4>
            </div>
            <div class="row tt-layout-promo02">
              {loading ? <></> : <>{showProductListWeek()}</>}
            </div>
          </div>
        </div>

        <div hidden style={{ marginTop: "10vh" }} class="container-indent mb-5">
          <div class="container container-fluid-custom-mobile-padding">
            <div class="tt-block-title">
              <h4 style={{ fontSize: "24px" }} class="specialfont">
                Shop by Categorys
              </h4>
            </div>
            <div class="row tt-layout-promo02">
              <div class="col-6 col-md-3">
                <div class="tt-promo02 text-center">
                  <a href="#" class="image-box">
                    <img
                      src="images/loader.svg"
                      data-src="https://res.cloudinary.com/dx9dnqzaj/image/upload/v1618397147/ecommerse2/CLUTCHES_c8upri.webp"
                      alt=""
                    />
                  </a>
                  <div class="tt-description">
                    <a href="#" class="tt-title">
                      <div class="tt-title-small">CLUTCHES</div>
                      <a href="/products?p=bags&t=CLUTCH" class="btn-border">
                        SHOP NOW!
                      </a>
                    </a>
                  </div>
                </div>
              </div>
              <div class="col-6 col-md-3">
                <div class="tt-promo02 text-center">
                  <a href="#" class="image-box">
                    <img
                      src="images/loader.svg"
                      data-src="https://res.cloudinary.com/dx9dnqzaj/image/upload/v1618397154/ecommerse2/HANDBAGS_itffum.webp"
                      alt=""
                    />
                  </a>
                  <div class="tt-description">
                    <a href="#" class="tt-title">
                      <div class="tt-title-small">HANDBAGS</div>
                      <a href="/products?p=bags&t=HANDBAG" class="btn-border">
                        SHOP NOW!
                      </a>
                    </a>
                  </div>
                </div>
              </div>
              <div class="col-6 col-md-3">
                <div class="tt-promo02 text-center">
                  <a href="#" class="image-box">
                    <img
                      src="images/loader.svg"
                      data-src="https://res.cloudinary.com/dx9dnqzaj/image/upload/v1618397156/ecommerse2/SHOULDER_BAGS_g1tc2f.webp"
                      alt=""
                    />
                  </a>
                  <div class="tt-description">
                    <a href="#" class="tt-title">
                      <div class="tt-title-small">SHOULDER BAGS</div>
                      <a
                        href="/products?p=bags&t=SHOULDER BAG"
                        class="btn-border"
                      >
                        SHOP NOW!
                      </a>
                    </a>
                  </div>
                </div>
              </div>
              <div class="col-6 col-md-3">
                <div class="tt-promo02 text-center">
                  <a href="#" class="image-box">
                    <img
                      src="images/loader.svg"
                      data-src="https://res.cloudinary.com/dx9dnqzaj/image/upload/v1618397154/ecommerse2/WALLET_a6iluz.webp"
                      alt=""
                    />
                  </a>
                  <div class="tt-description">
                    <a href="#" class="tt-title">
                      <div class="tt-title-small">POUCH</div>
                      <a href="/products?p=bags&t=POUCH" class="btn-border">
                        SHOP NOW!
                      </a>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div style={{ marginTop: "120px" }} class="container-indent">
        <div class="container">
          <div class="row tt-services-listing">
            <div class="col-xs-12 col-md-6 col-lg-3">
              <a href="https://www.houseofplug.com/contact" class="tt-services-block">
                <div class="tt-col-icon">
                  <img style={{ widht: '40px' }} src="https://res.cloudinary.com/dx9dnqzaj/image/upload/v1623238212/ecommerse2/icons8-contact-us-50_wyp5pv.png" />
                </div>
                <div class="tt-col-description">
                  <h4 class="tt-title">CONTACT US</h4>
                  <p>
                    You can contact us 7 Days a week from 10 am to 6 pm (CET).{" "}
                    <br /> We will try to reply as soon as possible!
                  </p>
                </div>
              </a>
            </div>
            <div class="col-xs-12 col-md-6 col-lg-3">
              <a href="https://www.houseofplug.com/return" class="tt-services-block">
                <div class="tt-col-icon">
                  <img style={{ widht: '40px' }} src="https://res.cloudinary.com/dx9dnqzaj/image/upload/v1623238212/ecommerse2/icons8-return-purchase-50_aownid.png" />
                </div>
                <div class="tt-col-description">
                  <h4 class="tt-title">14 DAYS RETURN</h4>
                  <p>
                    Simply fill out our return form and return the product
                    within 14 days. <br /> Please read our return policy by{" "}
                    <a style={{ color: "#4d82fe" }} href="/return">
                      clicking here....
                    </a>
                  </p>
                </div>
              </a>
            </div>
            <div class="col-xs-12 col-md-6 col-lg-3">
              <a href="https://www.houseofplug.com/shipping" class="tt-services-block">
                <div class="tt-col-icon">
                  <img style={{ widht: '40px' }} src="https://res.cloudinary.com/dx9dnqzaj/image/upload/v1623238212/ecommerse2/icons8-free-shipping-50_tg1o9f.png" />
                </div>
                <div class="tt-col-description">
                  <h4 class="tt-title">FREE SHIPPING</h4>
                  <p>
                    We offer FREE shipping for all our clients within Europe!{" "}
                    <br /> Please read more about shipping policy by{" "}
                    <a style={{ color: "#4d82fe" }} href="/privacy">
                      clicking here....
                    </a>
                  </p>
                </div>
              </a>
            </div>
            <div class="col-xs-12 col-md-6 col-lg-3">
              <a href="#! " class="tt-services-block">
                <div class="tt-col-icon">
                  <img style={{ widht: '40px' }} src="https://res.cloudinary.com/dx9dnqzaj/image/upload/v1623238212/ecommerse2/icons8-consumable-50_hngddp.png" />
                </div>
                <div class="tt-col-description">
                  <h4 class="tt-title">UPDATED INVENTORY</h4>
                  <p>
                    Please understand that a lot of products will get out of
                    stock quickly. <br /> We at house of plug try out best to
                    renew our stock and keep products updated.
                  </p>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
      <FooterLandingPage />
    </>
  );
};

export default Home;
