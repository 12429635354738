import React, { useEffect } from 'react'
import clsx from 'clsx';
import { useAuth } from '../contexts/AuthContext';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import MenuIcon from '@material-ui/icons/Menu';
import Button from '@material-ui/core/Button';
import Drawer from '@material-ui/core/Drawer';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { mainListItems } from './listitems';
import { API_SERVICE } from '../config/URI';
import axios from 'axios';
import CircularProgress from "@material-ui/core/CircularProgress";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    toolbar: {
        paddingRight: 24, // keep right padding when drawer closed
    },
    toolbarIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    menuButton: {
        marginRight: 36,
    },
    menuButtonHidden: {
        display: 'none',
    },
    title: {
        flexGrow: 1,
    },
    drawerPaper: {
        position: 'relative',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerPaperClose: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9),
        },
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    content: {
        display: 'flex',
    },
    appBarSpacer: theme.mixins.toolbar,
}))


const CouponList = ({ couponsdetail, deletecoupon }) => {
    return (
        <TableRow key={couponsdetail.coupon}>
            <TableCell component="th" scope="row">
            {couponsdetail.coupon}
            </TableCell>
            <TableCell align="right">
            {couponsdetail.discount}    
            </TableCell>
            <TableCell align="right">
                <Button onClick={() => deletecoupon(couponsdetail._id)} color="secondary" variant="outlined">
                    Delete
                </Button>
            </TableCell>
        </TableRow>
    )
}



function Coupon() {
    const classes = useStyles();
    const { logout } = useAuth()
    const [open, setOpen] = React.useState(true);
    const [coupon, setCoupon] = React.useState();
    const [discount, setDiscount] = React.useState();
    const [loading, setloading] = React.useState(true);

    const [couponsdetails, setcouponsdetails] = React.useState([]);

    // const [loading, setLoading] = React.useState(true);

    const handleDrawerOpen = () => {
        setOpen(true);
    };
    const handleDrawerClose = () => {
        setOpen(false);
    };
    const handleSignOut = () => {
        logout();
    }
    // const [products, setproducts] = React.useState([]);
    const couponList = () => {
        var couponData = {
            coupon,
            discount
        }
        axios.post(`${API_SERVICE}/api/v1/main/couponitems`, couponData)
            .then(response => {
                window.location.href = "/admincoupon";
            })
            .catch(err => console.log(err))
    }

    React.useEffect(() => {
        axios.get(`${API_SERVICE}/api/v1/main/getcouponitems`)
            .then(response => {
                setcouponsdetails(response.data);
                setloading(false);
            })
            .catch(err => console.log(err))
    }, []);

    const deletecoupon = (id) => {
        axios.get(`${API_SERVICE}/api/v1/main/deletecouponitems/${id}`)
            .then(response => {
                window.location.href = "/admincoupon";
            })
            .catch(err => console.log(err))
    }


    const showCouponsList = () => {
        return couponsdetails.map(couponsdetail => {
            return <CouponList couponsdetail={couponsdetail} deletecoupon={deletecoupon} key={couponsdetail._id} />
        })
    }



    return (
        <div>
            <div className={classes.root}>
                <CssBaseline />
                <AppBar position="absolute" className={clsx(classes.appBar, open && classes.appBarShift)}>
                    <Toolbar className={classes.toolbar}>
                        <IconButton
                            edge="start"
                            color="inherit"
                            aria-label="open drawer"
                            onClick={handleDrawerOpen}
                            className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
                        >
                            <MenuIcon />
                        </IconButton>
                        <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
                            Dashboard
                        </Typography> <Button variant="contained" color="secondary" onClick={handleSignOut}>
                            Signout
                        </Button>
                        <IconButton color="inherit">

                        </IconButton>
                    </Toolbar>
                </AppBar>
                <Drawer
                    variant="permanent"
                    classes={{
                        paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
                    }}
                    open={open}
                >
                    <div className={classes.toolbarIcon}>
                        <IconButton onClick={handleDrawerClose}>
                            <ChevronLeftIcon />
                        </IconButton>
                    </div>
                    <Divider />
                    <List>{mainListItems}</List>
                </Drawer>
                <Container maxWidth="lg" className={classes.container}>
                    <Grid className="mt-2" container spacing={3}>
                        <Grid item xs={12}>
                            <br />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField value={coupon} fullWidth onChange={(e) => setCoupon(e.target.value)} id="outlined-basic" label="Coupon" variant="outlined" />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField value={discount} fullWidth onChange={(e) => setDiscount(e.target.value)} id="outlined-basic" label="Discount" variant="outlined" />
                        </Grid>
                    </Grid>
                    <Button onClick={couponList} className="mt-2" fullWidth size="large" variant="contained" color="primary">
                        Submit
                    </Button>
                    {/* <Button onClick={deleteProductMainStore} className="mt-2" fullWidth size="large" variant="outlined" color="secondary">
                Delete Product
            </Button> */}
                    <main className={classes.content}>
                        <div className={classes.appBarSpacer} />
                        <Container maxWidth="lg" className={classes.container}>
                            <TableContainer >
                                <Table aria-label="simple table">
                                    <TableHead>
                                    <TableRow>
                                        <TableCell>Name</TableCell>
                                        <TableCell align="right">Discount</TableCell>
                                        <TableCell align="right"></TableCell>
                                    </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            loading === true ? (
                                                <center style={{ marginTop: '10%', marginLeft: '50%' }}>
                                                    <CircularProgress />
                                                </center>
                                            ) : (
                                                <>
                                                    {showCouponsList()}
                                                </>
                                            )
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Container>
                    </main>
                </Container>
            </div>
        </div>
    )
}

export default Coupon
