import React from 'react';

import queryString from 'query-string';


// Components
import HeaderLangingPage from '../components/HeaderLangingPage';
import FooterLandingPage from '../components/FooterLandingPage';

const ThankYou = ({ location }) => {

    const [status, setstatus] = React.useState('');
    const [type, settype] = React.useState('');

    React.useEffect(() => {
        const { redirect_status, source_type } = queryString.parse(location.search);

        if (redirect_status) {
            setstatus(redirect_status);
        }

        if (source_type) {
            settype(source_type);
        }
    }, []);


    return (
        <>
            <HeaderLangingPage />         
            <br />

            {
                type === "ideal" && status === "failed" ? (
                    <div id="tt-pageContent">
                        <div className="container-indent">
                            <div className="container">
                                <h1 className="tt-title-subpages noborder">Ideal payment failed please try again</h1>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div id="tt-pageContent">
                        <div className="container-indent">
                            <div className="container">
                                <h1 className="tt-title-subpages noborder">THANK YOU</h1>
                                <h1 className="tt-title-subpages noborder">YOUR PAYMENT IS SUCCESSFULLY COMPLETED</h1>
                                <h1 className="tt-title-subpages noborder">YOUR PACKAGE WILL BE DELIVERED WITHIN 24 HOURS</h1>
                                <center>
                                    <a className="btn-link" href="/track">
                                        <h4>
                                        Click to Track Order
                                        </h4>
                                    </a>
                                </center>
                            </div>
                        </div>
                    </div>
                )
            }

            
        </>
    )
}

export default ThankYou
