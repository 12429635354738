import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import Badge from "@material-ui/core/Badge";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import NotificationsIcon from "@material-ui/icons/Notifications";
import { mainListItems } from "./listitems";
import queryString from "query-string";
import axios from "axios";
import { API_SERVICE } from "../config/URI";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Snackbar from "@material-ui/core/Snackbar";
import CloseIcon from "@material-ui/icons/Close";
import CheckIcon from "@material-ui/icons/Check";
import { ToggleButton } from "@material-ui/lab";

const drawerWidth = 240;
var productImages = [];

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: "none",
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  container: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 240,
  },
}));

export default function MainStore({ location }) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);

  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  // const [products, setproducts] = React.useState([]);
  // const [title, settitle] = React.useState('');
  // const [price, setprice] = React.useState('');
  // const [category, setcategory] = React.useState('');
  // const [gender, setgender] = React.useState('');
  // const [madein, setmadein] = React.useState('');
  // const [description, setdescription] = React.useState('');
  // const [productId, setproductId] = React.useState('');
  // const [actualprice, setactualprice] = React.useState('');
  // const [brandname, setbrandname] = React.useState('');
  // const [title2, settitle2] = React.useState('');
  const [loading, setloading] = React.useState(true);

  const [title, setTitle] = React.useState("");
  const [price, setPrice] = React.useState("");
  const [disc, setDisc] = React.useState("");
  const [discPrice, setDiscPrice] = React.useState("");

  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const handleClickSnackbar = () => {
    setOpenSnackbar(true);
  };
  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  React.useEffect(() => {
    const { id } = queryString.parse(location.search);
    axios
      .get(`${API_SERVICE}/api/v1/main/getfeaturedproductother/${id}`)
      .then((response) => {
        setSelected(response.data.hidden);
        setTitle(response.data.title);
        setPrice(response.data.price);
        setDisc(response.data.disc);
        setDiscPrice(response.data.discPrice);
        setloading(false);
      })
      .catch((err) => console.log(err));
  }, []);

  const refreshProducts = () => {
    const { id } = queryString.parse(location.search);
    axios
      .get(`${API_SERVICE}/api/v1/main/getfeaturedproductother/${id}`)
      .then((response) => {
        setSelected(response.data.hidden);

        setTitle(response.data.title);
        setPrice(response.data.price);
        setDisc(response.data.disc);
        setDiscPrice(response.data.discPrice);
        setloading(false);
        window.location.reload();
      })
      .catch((err) => console.log(err));
  };

  const updateProduct = () => {
    const { id } = queryString.parse(location.search);
    var uploadData = {
      title,
      price,
      disc,
      discPrice,
      id,
    };
    console.log("ok");
    axios
      .post(`${API_SERVICE}/api/v1/main/updatefeaturedother`, uploadData)
      .then((response) => {
        if (response.status === 200) {
          refreshProducts();
          handleClickSnackbar();
        }
      })
      .catch((err) => console.log(err));
  };

  const hideProduct = () => {
    const { id } = queryString.parse(location.search);
    axios
      .patch(`${API_SERVICE}/api/v1/main/hidenew/${id}`)
      .then((response) => {
        console.log(response);
        setSelected(true);
      })
      .catch((err) => console.log(err));
  };
  const unhideProduct = () => {
    const { id } = queryString.parse(location.search);
    axios
      .patch(`${API_SERVICE}/api/v1/main/unhidenew/${id}`)
      .then((response) => {
        console.log(response);
        setSelected(false);
      })
      .catch((err) => console.log(err));
  };
  const [selected, setSelected] = React.useState(undefined);

  function handlePrice(e) {
    setDiscPrice(e.target.value - ((disc * e.target.value) / 100).toFixed(0));
    setPrice(e.target.value);
  }

  function handleDiscPrice(e) {
    // setDiscPrice(price-((*price)/100).toFixed(0));
    setDiscPrice(price - e.target.value);
    setDisc(e.target.value);
  }

  return (
    <div className={classes.root}>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        open={openSnackbar}
        autoHideDuration={4000}
        onClose={handleCloseSnackbar}
        message="Item Successfully Updated in Main Store"
        action={
          <React.Fragment>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={handleCloseSnackbar}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />
      <CssBaseline />
      <AppBar
        position="absolute"
        className={clsx(classes.appBar, open && classes.appBarShift)}
      >
        <Toolbar className={classes.toolbar}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            className={clsx(
              classes.menuButton,
              open && classes.menuButtonHidden
            )}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            component="h1"
            variant="h6"
            color="inherit"
            noWrap
            className={classes.title}
          >
            Dashboard
          </Typography>
          <IconButton color="inherit"></IconButton>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        classes={{
          paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
        }}
        open={open}
      >
        <div className={classes.toolbarIcon}>
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <Divider />
        <List>{mainListItems}</List>
      </Drawer>
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          <Grid className="mt-2" container spacing={3}>
            <Grid
              item
              xs={12}
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <Typography
                variant="subtitle2"
                style={{
                  marginRight: "10px",
                  paddingBottom: 0,
                  alignItems: "center",
                  display: "flex",
                  color: "rgba(0, 0, 0, 0.38)",
                }}
              >
                {selected === true
                  ? "Product is hidden from the customer"
                  : "Product is visible to the customer"}
              </Typography>
              <ToggleButton
                value="check"
                selected={selected}
                onChange={() => {
                  selected ? unhideProduct() : hideProduct();
                }}
              >
                {selected ? (
                  <>
                    <Typography variant="body1" style={{ marginRight: "10px" }}>
                      Set as Visible
                    </Typography>
                    <CheckIcon />
                  </>
                ) : (
                  <>
                    <Typography variant="body1" style={{ marginRight: "10px" }}>
                      Set as Hidden
                    </Typography>
                    <CloseIcon />
                  </>
                )}
              </ToggleButton>
            </Grid>
            <Grid item xs={12}>
              <TextField
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                id="outlined-basic"
                fullWidth
                label="title"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                value={price}
                onChange={handlePrice}
                id="outlined-basic"
                fullWidth
                label="price"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                value={disc}
                onChange={handleDiscPrice}
                id="outlined-basic"
                fullWidth
                label="discount off"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                value={discPrice}
                id="outlined-basic"
                fullWidth
                label="Price after discount"
                variant="outlined"
              />
            </Grid>
          </Grid>

          <Button
            onClick={updateProduct}
            className="mt-2"
            fullWidth
            size="large"
            variant="contained"
            color="primary"
          >
            Update
          </Button>
          {/* <Button onClick={deleteProductMainStore} className="mt-2" fullWidth size="large" variant="outlined" color="secondary">
                Delete Product
            </Button> */}
        </Container>
      </main>
    </div>
  );
}
