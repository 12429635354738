import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import DashboardIcon from '@material-ui/icons/Dashboard';
import StoreMallDirectoryIcon from '@material-ui/icons/StoreMallDirectory';
import LocalMallIcon from '@material-ui/icons/LocalMall';
import EuroIcon from '@material-ui/icons/Euro';
import StarIcon from '@material-ui/icons/Star';

export const mainListItems = (
  <div>
    <ListItem onClick={() => window.location.href = "/admindashboard"} button>
      <ListItemIcon>
        <DashboardIcon />
      </ListItemIcon>
      <ListItemText primary="API Items" />
    </ListItem>
    <ListItem onClick={() => window.location.href = "/adminmainstore"} button>
      <ListItemIcon>
        <StoreMallDirectoryIcon />
      </ListItemIcon>
      <ListItemText primary="My Store Items" />
    </ListItem>
    <ListItem onClick={() => window.location.href = "/adminmainfeaturedproduct"} button>
      <ListItemIcon>
        <StarIcon />
      </ListItemIcon>
      <ListItemText primary="Featured Products" />
    </ListItem>
    <ListItem onClick={() => window.location.href = "/adminallnewin"} button>
      <ListItemIcon>
        <StarIcon />
      </ListItemIcon>
      <ListItemText primary="New In Products" />
    </ListItem>
    <ListItem onClick={() => window.location.href = "/adminallsale"} button>
      <ListItemIcon>
        <StarIcon />
      </ListItemIcon>
      <ListItemText primary="Sale" />
    </ListItem>
    <ListItem onClick={() => window.location.href = "/adminallweek"} button>
      <ListItemIcon>
        <StarIcon />
      </ListItemIcon>
      <ListItemText primary="Products of Week" />
    </ListItem>
    <ListItem onClick={() => window.location.href = "/adminrecomended"} button>
      <ListItemIcon>
        <StarIcon />
      </ListItemIcon>
      <ListItemText primary="Recommended" />
    </ListItem>
    <ListItem onClick={() => window.location.href = "/admincoupon"} button>
      <ListItemIcon>
        <StarIcon />
      </ListItemIcon>
      <ListItemText primary="Coupons" />
    </ListItem>
    <ListItem onClick={() => window.location.href = "/admincustomproducts"} button>
      <ListItemIcon>
        <StarIcon />
      </ListItemIcon>
      <ListItemText primary="Custom Products" />
    </ListItem>
    <ListItem onClick={() => window.location.href = "/adminmainstoreorders"} button>
      <ListItemIcon>
        <LocalMallIcon />
      </ListItemIcon>
      <ListItemText primary="Orders" />
    </ListItem>
  </div>
);

