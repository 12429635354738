import React from "react";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";

// Components
import HeaderLangingPage from "../components/HeaderLangingPage";
import FooterLandingPage from "../components/FooterLandingPage";

// Stripe
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import HomePageIdeal from "./HomePageIdeal";

import { firestore } from "../Firebase/index";
// Extras
import { API_SERVICE } from "../config/URI";

const stripePromise = loadStripe(
  "pk_live_51IdwfeH8KzFo5uc9isSmGZV6LJSln3028VOElNGYQVgRaoUlQvOOpoYxoP99bkXPCz4ZViWhcZ8jECuoFQBO6g1K006iDohSVZ"
);

const ProductList = ({
  p,
  settotalprice,
  changeQty,
  product,
  deleteProduct,
}) => {
  settotalprice(p.toFixed(2));
  return <></>;
};

const Checkoutideal = () => {
  const [products, setproducts] = React.useState([]);
  const [totalprice, settotalprice] = React.useState(0);
  const [loading, setloading] = React.useState(true);
  const [userId, setuserId] = React.useState("");

  React.useEffect(() => {
    if (localStorage.getItem("userId") === null) {
      localStorage.setItem("userId", uuidv4() + Date.now());
    } else {
      var userId = localStorage.getItem("userId");
      setuserId(userId);
    }
    axios
      .get(`${API_SERVICE}/api/v1/main/getcartallitems/${userId}`)
      .then((response) => {
        setproducts(response.data);
        setloading(false);
      })
      .catch((err) => console.log(err));
  }, []);

  const showProductList = () => {
    var p = 0;
    return products.map((product) => {
      p = Number(product.price) + Number(p);
      return (
        <ProductList
          settotalprice={settotalprice}
          p={p}
          product={product}
          key={product.Sku}
        />
      );
    });
  };
  const refreshList = () => {
    axios
      .get(`${API_SERVICE}/api/v1/main/getcartallitems/${userId}`)
      .then((response) => {
        setproducts(response.data);
        setloading(false);
      })
      .catch((err) => console.log(err));
  };

  const clearShoppingCartItem = () => {
    var docRef = firestore.collection("cart").doc(userId);
    docRef
      .get()
      .then(function (doc) {
        var items = doc.data().items;
        axios
          .get(`${API_SERVICE}/api/v1/main/clearallcartitems/${userId}`)
          .then((response) => {
            setloading(true);
            settotalprice(0);
            refreshList();
            items = 0;
            docRef.set(
              {
                items,
              },
              { merge: true }
            );
          })
          .catch((err) => console.log(err));
      })
      .catch(function (error) {
        console.log("Error getting document:", error);
      });
  };
  return (
    <>
      <HeaderLangingPage />
      <br />
      <div id="tt-pageContent">
        <div className="container-indent">
          <div className="container">
            <h1 className="tt-title-subpages noborder">CHECKOUT</h1>
            {loading === true ? (
              <></>
            ) : products && products.length ? (
              <>
                <div class="tt-shopcart-table-02">
                  <div
                    class="tt-shopcart-btn"
                    style={{ marginTop: "0", marginBottom: "32px" }}
                  >
                    <div class="col-left">
                      <a class="btn-link" href="/products?p=bags&t=all">
                        <i class="icon-e-19"></i>CONTINUE SHOPPING
                      </a>
                    </div>
                    <div class="col-right">
                      <a
                        onClick={clearShoppingCartItem}
                        class="btn-link"
                        href="#"
                      >
                        <i class="icon-h-02"></i>CLEAR SHOPPING CART
                      </a>
                    </div>
                  </div>
                </div>
                <table>
                  <tbody>{loading ? <></> : <>{showProductList()}</>}</tbody>
                </table>
                <Elements stripe={stripePromise}>
                  <HomePageIdeal
                    type={"vg"}
                    amount={0}
                    products={products}
                    totalprice={totalprice}
                  />
                </Elements>
              </>
            ) : (
              <>
                <center style={{ marginTop: "5%" }}>
                  <a class="btn-link" href="/products?p=bags&t=all">
                    <i class="icon-e-19"></i>CONTINUE SHOPPING
                  </a>
                  <br />
                  <img
                    src="https://www.no-fea.com/front/images/empty-cart.png"
                    alt="Empty Cart"
                  />
                </center>
              </>
            )}
          </div>
        </div>
      </div>

      <FooterLandingPage />
    </>
  );
};

export default Checkoutideal;
