import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import Badge from "@material-ui/core/Badge";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import NotificationsIcon from "@material-ui/icons/Notifications";
import { mainListItems } from "./listitems";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import axios from "axios";
import CircularProgress from "@material-ui/core/CircularProgress";
import { API_SERVICE } from "../config/URI";
import Paper from "@material-ui/core/Paper";
import InputBase from "@material-ui/core/InputBase";
import SearchIcon from "@material-ui/icons/Search";
import { useAuth } from "../contexts/AuthContext";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: "none",
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  inputroot: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    width: 600,
    marginTop: "10px",
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 240,
  },
}));

const ProductList = ({ product, removeProduct }) => {
  return (
    <Grid item xs={3}>
      <Card>
        <CardActionArea>
          <CardContent>
            <center>
              <img
                style={{ width: "100%", height: "100%" }}
                src={product.displayImage}
              />
              <p>{product.sku}</p>
              <h5 style={{ fontSize: '14px' }}>{product.title}</h5>
              <h6>Price € {product.price}</h6>
            </center>
          </CardContent>
        </CardActionArea>
        <div style={{ padding: "10px" }}>
          <Button
            variant="outlined"
            onClick={() => removeProduct(product._id)}
            color="secondary"
            fullWidth
          >
            Remove Product
          </Button>
          <Button
            style={{ marginTop: "20px" }}
            href={`/adminedit?id=${product._id}`}
            variant="outlined"
            color="secondary"
            fullWidth
          >
            Edit Product
          </Button>
        </div>
      </Card>
    </Grid>
  );
};

export default function FeaturedProduct({ location }) {
  const { logout } = useAuth();
  const handleSignOut = () => {
    logout();
  };
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);
  const [search, setsearch] = React.useState("ACCESSORIES");

  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  const [products, setproducts] = React.useState([]);
  const [loading, setloading] = React.useState(true);
  React.useEffect(() => {
    axios
      .get(`${API_SERVICE}/api/v1/main/getallfeaturedproductmainstore`)
      .then((response) => {
        setproducts(response.data);
        setloading(false);
      })
      .catch((err) => console.log(err));
  }, []);

  const removeProduct = (id) => {
    axios
      .get(`${API_SERVICE}/api/v1/main/removefeaturedproduct/${id}`)
      .then((response) => {
        window.location.href = "/adminmainfeaturedproduct";
      })
      .catch((err) => console.log(err));
  };

  const showProductList = () => {
    return products.map((product) => {
      return (
        <ProductList
          removeProduct={removeProduct}
          product={product}
          key={product._id}
        />
      );
    });
  };

  const searchMainStore = () => {
    setloading(true);
    axios
      .get(`${API_SERVICE}/api/v1/main/searchfeaturedproductstore/${search}`)
      .then((response) => {
        setproducts(response.data);
        setloading(false);
      })
      .catch((err) => console.log(err));
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="absolute"
        className={clsx(classes.appBar, open && classes.appBarShift)}
      >
        <Toolbar className={classes.toolbar}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            className={clsx(
              classes.menuButton,
              open && classes.menuButtonHidden
            )}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            component="h1"
            variant="h6"
            color="inherit"
            noWrap
            className={classes.title}
          >
            Dashboard
          </Typography>{" "}
          <Button variant="contained" color="secondary" onClick={handleSignOut}>
            Signout
          </Button>
          <IconButton color="inherit"></IconButton>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        classes={{
          paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
        }}
        open={open}
      >
        <div className={classes.toolbarIcon}>
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <Divider />
        <List>{mainListItems}</List>
      </Drawer>
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          <Button
            onClick={() => (window.location.href = "/adminmainfeaturedproduct")}
            color="primary"
          >
            SHOW All
          </Button>
          {loading === false ? (
            <center>
              <Paper component="form" className={classes.inputroot}>
                <InputBase
                  className={classes.input}
                  onChange={(e) => setsearch(e.target.value)}
                  placeholder="Search by Title, SKU, Price"
                  inputProps={{ "aria-label": "search products" }}
                />
                <IconButton
                  onClick={searchMainStore}
                  type="button"
                  className={classes.iconButton}
                  aria-label="search"
                >
                  <SearchIcon />
                </IconButton>
              </Paper>
              <br />
            </center>
          ) : null}
          <Grid container spacing={3}>
            {loading === true ? (
              <center style={{ marginTop: "10%", marginLeft: "50%" }}>
                <CircularProgress />
              </center>
            ) : (
              <>{showProductList()}</>
            )}
          </Grid>
        </Container>
      </main>
    </div>
  );
}
