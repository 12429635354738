import React from "react";

import HeaderLangingPage from "../components/HeaderLangingPage";
import FooterLandingPage from "../components/FooterLandingPage";

import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Container } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: "bold",
  },
}));

const Contact = () => {
  const classes = useStyles();

  return (
    <>
      <HeaderLangingPage />
      <br />
      <div style={{ textAlign: "center" }}>
        <h3>CONTACT US</h3>
      </div>
      <Container>
        <div className={classes.root}>
  <div className="container-indent">
    <div className="container container-fluid-custom-mobile-padding">
      <div className="tt-contact02-col-list">
        <div className="row" style={{justifyContent:"center"}}>
          <div className="col-sm-6 col-md-4">
            <div className="tt-contact-info">
              <i className="tt-icon icon-f-92" />
              <h6 className="tt-title">WORK TIME</h6>
              <address>
                7 Days a week<br />
                from 10 AM to 6 PM (CET)
              </address>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div className="container-indent">
    <div className="container container-fluid-custom-mobile-padding">
      <form id="contactform" className="contact-form form-default" method="post" noValidate="novalidate" action="#">
        <div className="row">
          <div className="col-md-6">
            <div className="form-group">
              <input type="text" name="name" className="form-control" id="inputName" placeholder="Your Name (required)" />
            </div>
            <div className="form-group">
              <input type="text" name="email" className="form-control" id="inputEmail" placeholder="Your Email (required)" />
            </div>
            <div className="form-group">
              <input type="text" name="subject" className="form-control" id="inputSubject" placeholder="Subject" />
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <textarea name="message" className="form-control" rows={7} placeholder="Your Message" id="textareaMessage" defaultValue={""} />
            </div>
          </div>
        </div>
        <div className="text-center">
          <button type="submit" className="btn">SEND MESSAGE</button>
        </div>
      </form>
    </div>
  </div>
        </div>
      </Container>
      <FooterLandingPage />
    </>
  );
};

export default Contact;
