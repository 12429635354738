import React from "react";
import { useState } from "react";
import FooterLandingPage from "../components/FooterLandingPage";
import HeaderLangingPage from "../components/HeaderLangingPage";
import { useAuth } from "../contexts/AuthContext";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Register = () => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const [message, setMessage] = useState("");

  const handleClose = () => {
    setOpen(false);
  };
  const [formState, setFormState] = useState({
    fName: "",
    lName: "",
    email: "",
    password: "",
  });
  const handleChange = (event) => {
    setFormState((prev) => {
      return {
        ...prev,
        [event.target.name]: event.target.value,
      };
    });
  };
  const { signup, updateDisplayName } = useAuth();
  const handleCreate = (event) => {
    signup(formState.email, formState.password)
      .then((user) => {
        updateDisplayName(`${formState.fName} ${formState.lName}`)
          .then((abs) => {
            window.location.href = "https://houseofplug.com/home";
          })
          .catch((err) => {
            setMessage(err.message);
            handleClickOpen();
          });
      })
      .catch((err) => {
        setMessage(err.message);
        handleClickOpen();
      });
  };

  return (
    <>
      <HeaderLangingPage />
      <br />
      <section className="pt-4">
        <div id="tt-pageContent">
          <div className="container-indent">
            <div className="container">
              <h1 className="tt-title-subpages noborder">CREATE AN ACCOUNT</h1>
              <div className="tt-login-form">
                <div className="row justify-content-center">
                  <div className="col-md-8 col-lg-6">
                    <div className="tt-item">
                      <h2 className="tt-title">PERSONAL INFORMATION</h2>
                      <div className="form-default">
                        <form
                          id="contactform"
                          onSubmit={(e) => {
                            e.preventDefault();
                          }}
                          novalidate="novalidate"
                        >
                          <div className="form-group">
                            <label for="loginInputName">FIRST NAME *</label>
                            <div className="tt-required">* Required Fields</div>
                            <input
                              type="text"
                              value={formState.fName}
                              onChange={handleChange}
                              name="fName"
                              className="form-control"
                              id="loginInputName"
                              placeholder="Enter First Name"
                              required
                            />
                          </div>
                          <div className="form-group">
                            <label for="loginLastName">LAST NAME *</label>
                            <input
                              type="text"
                              value={formState.lName}
                              onChange={handleChange}
                              name="lName"
                              className="form-control"
                              id="loginLastName"
                              placeholder="Enter Last Name"
                              required
                            />
                          </div>
                          <div className="form-group">
                            <label for="loginInputEmail">E-MAIL *</label>
                            <input
                              type="text"
                              value={formState.email}
                              onChange={handleChange}
                              name="email"
                              className="form-control"
                              id="loginInputEmail"
                              placeholder="Enter E-mail"
                              required
                            />
                          </div>
                          <div className="form-group">
                            <label for="loginInputPassword">PASSWORD *</label>
                            <input
                              type="password"
                              value={formState.password}
                              onChange={handleChange}
                              name="password"
                              className="form-control"
                              id="loginInputPassword"
                              placeholder="Enter Password"
                              required
                            />
                          </div>
                          <div className="row">
                            <div className="col-auto">
                              <div className="form-group">
                                <button
                                  className="btn btn-border"
                                  type="submit"
                                  onClick={handleCreate}
                                >
                                  CREATE
                                </button>
                              </div>
                            </div>
                            <div className="col-auto align-self-center">
                              <div className="form-group">
                                <ul className="additional-links">
                                  <li>
                                    or <a href="#">Return to Store</a>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          open={open}
          autoHideDuration={6000}
          onClose={handleClose}
          message={message}
          action={
            <React.Fragment>
              <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleClose}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            </React.Fragment>
          }
        />
      </section>
      <FooterLandingPage />
    </>
  );
};

export default Register;
