import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import Container from "@material-ui/core/Container";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import { mainListItems } from "./listitems";
import Button from "@material-ui/core/Button";
import axios from "axios";
import { API_SERVICE } from "../config/URI";
import Grid from "@material-ui/core/Grid";
import queryString from "query-string";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import Paper from "@material-ui/core/Paper";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import { useHistory } from "react-router-dom";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: "none",
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 240,
  },
}));

const OrderList = ({ p, settotalprice, order, i }) => {
  var subTotal = Number(order.qty) * Number(order.price);

  p = Number(p) + Number(subTotal);
  settotalprice(p.toFixed(2));

  return (
    <Card style={{ marginTop: "4px" }}>
      <CardContent>
        <center>
          <img style={{ width: "25%" }} src={order.image} />
        </center>
        <center>
          <h5>{order.title}</h5>
          <h5>
            Price Per Product: {order.price} EUR <br />
            Sub Total: {Number(order.qty) * Number(order.price)} EUR
          </h5>
          <p>{order.product.description}</p>
        </center>
      </CardContent>
    </Card>
  );
};

export default function OrderDetails({ location }) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);
  const [loading, setloading] = React.useState(true);
  const [orders, setorders] = React.useState([]);
  // const [ordersSet, setordersSet] = React.useState([]);
  // const [productSet, setProductSet] = React.useState([]);
  const [userId, setuserId] = React.useState("");
  const [userData, setuserData] = React.useState({});
  const [totalprice, settotalprice] = React.useState(0);
  const [orderstatus, setorderstatus] = React.useState("");
  const history = useHistory();

  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  const [openDialog, setOpenDialog] = React.useState(false);
  const handleClickOpenDialog = () => {
    setOpenDialog(true);
  };
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  React.useEffect(() => {
    const { id, orderId } = queryString.parse(location.search);
    setuserId(id);
    axios
      .get(`${API_SERVICE}/api/v1/main/getuserdetails/${id}`)
      .then((response) => {
        setuserData(response.data[response.data.length - 1]);
      })
      .catch((err) => console.log(err));
  }, []);

  // const convertToSet = () => {
  //   // console.log(orders);
  //   orders.forEach((ord) => {
  //     if (productSet.indexOf(ord.product._id) === -1) {
  //       setProductSet([...productSet, ord.product._id]);
  //       setordersSet([...ordersSet, [ord, 1]]);
  //     } else {
  //       if (
  //         ordersSet.filter((o) => o[0].product._id != ord.product._id).length >
  //         0
  //       ) {
  //         ordersSet.find((o) => o[0].product._id === ord.product._id)[1] += 1;
  //       }
  //     }
  //   });
  //   console.log(ordersSet);
  // };
  // convertToSet();

  React.useEffect(() => {
    const { id, orderId } = queryString.parse(location.search);
    setuserId(id);
    axios
      .get(`${API_SERVICE}/api/v1/main/getorderdetails/${id}/${orderId}`)
      .then((response) => {
        setorders(response.data);
        axios
          .get(`${API_SERVICE}/api/v1/main/getorderstatusmainstore/${id}`)
          .then((response) => {
            setorderstatus(response.data[0].status);
            setloading(false);
          })
          .catch((err) => console.log(err));
      })
      .catch((err) => console.log(err));
  }, []);

  const showOrderList = () => {
    var i = 0;
    var p = 0;
    return orders.map((order) => {
      i = i + 1;
      p = Number(order.price) + Number(p);
      return (
        <OrderList
          settotalprice={settotalprice}
          p={p}
          i={i}
          order={order}
          key={order._id}
        />
      );
    });
  };
  const downloadOrderList = () => {
    return (
      <Table
        className={classes.table}
        id="download-order-detail"
        aria-label="simple table"
        hidden
      >
        <TableHead>
          <TableRow>
            <TableCell align="right">Client</TableCell>
            <TableCell align="right">Email</TableCell>
            <TableCell align="right">Phone</TableCell>
            <TableCell align="right">Address</TableCell>
            <TableCell align="right">Company Address</TableCell>
            <TableCell align="right">Title</TableCell>
            <TableCell align="right">Description</TableCell>
            <TableCell align="right">Price Per Product</TableCell>
            <TableCell align="right">Sub Total</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {orders.map((order) => (
            <TableRow key={order._id}>
              <TableCell align="right">{userData.fullName}</TableCell>
              <TableCell align="right">{userData.email}</TableCell>
              <TableCell align="right">{userData.phoneno}</TableCell>
              <TableCell align="right">{userData.address}</TableCell>
              <TableCell align="right">{userData.companyAddress}</TableCell>
              <TableCell align="right">{order.product.product.Title}</TableCell>
              <TableCell align="right">
                {order.product.product.Description}
              </TableCell>
              <TableCell align="right">{order.price} EUR</TableCell>
              <TableCell align="right">
                {Number(order.qty) * Number(order.price)} EUR
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    );
  };

  const refreshList = () => {
    axios
      .get(`${API_SERVICE}/api/v1/main/getorderdetails/${userId}`)
      .then((response) => {
        setorders(response.data);
        axios
          .get(`${API_SERVICE}/api/v1/main/getorderstatusmainstore/${userId}`)
          .then((response) => {
            setorderstatus(response.data[0].status);
            setloading(false);
          })
          .catch((err) => console.log(err));
      })
      .catch((err) => console.log(err));
  };

  const updateStatus = () => {
    var uploadData = {
      userId,
      orderstatus,
    };
    axios
      .post(`${API_SERVICE}/api/v1/main/updateorderstatusmainstore`, uploadData)
      .then((response) => {
        if (response.status === 200) {
          handleCloseDialog();
          refreshList();
        }
      })
      .catch((err) => console.log(err));
  };

  const deleteOrder = () => {
    const { orderId } = queryString.parse(location.search);
    const result = window.confirm("Are you sure to delete it ?");
    if (result) {
      axios
        .get(`${API_SERVICE}/api/v1/main/deleteorder/${orderId}`)
        .then(() => {
          history.push("/adminmainstoreorders");
        })
        .catch((err) => console.log(err));
    }
  };

  return (
    <div className={classes.root}>
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
        maxWidth="sm"
      >
        <DialogTitle id="alert-dialog-title">Update Status</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <center>
              <select
                value={orderstatus}
                onChange={(e) => setorderstatus(e.target.value)}
                name="cars"
                id="cars"
              >
                <option selected disabled>
                  -- SELECT OPTION --
                </option>
                <option value="Order Received">Order Received</option>
                <option value="Out for Delivery">Out for Delivery</option>
                <option value="Order Completed">Order Completed</option>
              </select>
            </center>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Close
          </Button>
          <Button onClick={updateStatus} color="primary" autoFocus>
            Submit
          </Button>
        </DialogActions>
      </Dialog>

      <CssBaseline />
      <AppBar
        position="absolute"
        className={clsx(classes.appBar, open && classes.appBarShift)}
      >
        <Toolbar className={classes.toolbar}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            className={clsx(
              classes.menuButton,
              open && classes.menuButtonHidden
            )}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            component="h1"
            variant="h6"
            color="inherit"
            noWrap
            className={classes.title}
          >
            Dashboard
          </Typography>
          <IconButton color="inherit"></IconButton>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        classes={{
          paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
        }}
        open={open}
      >
        <div className={classes.toolbarIcon}>
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <Divider />
        <List>{mainListItems}</List>
      </Drawer>
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          <h2 className="font-weight-bold">Order Details</h2>
          {loading ? <></> : <h3>Status: {orderstatus}</h3>}
          <h5>Tax: 21%</h5>
          <h5>Grand Total: {userData.amount} EUR</h5>
          <Button
            onClick={handleClickOpenDialog}
            variant="contained"
            color="primary"
          >
            Update the Status
          </Button>
          <Button
            style={{ marginLeft: "4px" }}
            href="https://dashboard.stripe.com/test/payments/pi_1Iqs5rH8KzFo5uc952NeC2Cv"
            target="_blank"
            variant="contained"
            color="secondary"
          >
            Issue a Refund
          </Button>
          <Button
            style={{ marginLeft: "4px" }}
            variant="contained"
            onClick={deleteOrder}
          >
            Delete Order
          </Button>

          <ReactHTMLTableToExcel
            id="test-table-xls-button"
            className="excelbutton"
            table="download-order-detail"
            filename="orders"
            sheet="orders"
            buttonText="Download as XLS"
          />

          {downloadOrderList()}

          <br />
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="right">Client</TableCell>
                  <TableCell align="right">Email</TableCell>
                  <TableCell align="right">Phone</TableCell>
                  <TableCell align="right">Address</TableCell>
                  <TableCell align="right">Company Address</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow key="1">
                  <TableCell align="right">{userData.fullName}</TableCell>
                  <TableCell align="right">{userData.email}</TableCell>
                  <TableCell align="right">{userData.phoneno}</TableCell>
                  <TableCell align="right">{userData.address}</TableCell>
                  <TableCell align="right">
                    {userData?.companyAddress || "None"}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>

          <br />
          <Grid container spacing={3}>
            <Grid item xs={12}>
              {loading ? (
                <center>
                  <CircularProgress />
                </center>
              ) : (
                <>{showOrderList()}</>
              )}
            </Grid>
          </Grid>
        </Container>
      </main>
    </div>
  );
}
