import React from "react";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";

// Components
import HeaderLangingPage from "../components/HeaderLangingPage";
import FooterLandingPage from "../components/FooterLandingPage";

// Extras
import { API_SERVICE } from "../config/URI";
import { firestore } from "../Firebase/index";

const ProductList = ({
  p,
  settotalprice,
  changeQty,
  product,
  deleteProduct,
}) => {
  var subTotal = Number(product.qty) * Number(product.price);

  p = Number(p) + Number(subTotal);
  settotalprice(p.toFixed(2));

  return (
    <tr>
      <td>
        <div className="tt-product-img">
          <img src={product.image} data-src={product.image} alt="" />
        </div>
      </td>
      <td>
        <h2 className="tt-title">
          <a href="#">{product.title}</a>
        </h2>
        <ul className="tt-list-parameters">
          <li>
            <div className="tt-price">€{product.price}</div>
          </li>
          <li>
            <div className="detach-quantity-mobile">{product.qty}</div>
          </li>
          <li>
            <div className="tt-price subtotal">€{product.price}</div>
          </li>
        </ul>
      </td>
      <td>
        <div className="tt-price">€{product.price}</div>
      </td>
      <td>
        <div className="tt-price">{product.qty}</div>
      </td>
      <td>
        <div className="tt-price subtotal">€{subTotal}</div>
      </td>
    </tr>
  );
};

const Track = () => {
  const [products, setproducts] = React.useState([]);
  const [userId, setuserId] = React.useState("");
  const [loading, setloading] = React.useState(true);
  const [totalprice, settotalprice] = React.useState(0);
  const [orderstatus, setorderstatus] = React.useState("");
  const [paymentstatus, setpaymentstatus] = React.useState("");

  React.useEffect(() => {
    if (localStorage.getItem("userId") === null) {
      localStorage.setItem("userId", uuidv4() + Date.now());
    } else {
      var userId = localStorage.getItem("userId");
      setuserId(userId);
    }
    axios
      .get(`${API_SERVICE}/api/v1/main/gettrackallitems/${userId}`)
      .then((response) => {
        setproducts(response.data);
        axios
          .get(`${API_SERVICE}/api/v1/main/getorderstatusmainstore/${userId}`)
          .then((response) => {
            setorderstatus(response.data[0].status);
            setpaymentstatus(response.data[0].payment);
            setloading(false);
          })
          .catch((err) => console.log(err));
      })
      .catch((err) => console.log(err));
  }, []);

  const refreshList = () => {
    axios
      .get(`${API_SERVICE}/api/v1/main/gettrackallitems/${userId}`)
      .then((response) => {
        setproducts(response.data);
        setloading(false);
      })
      .catch((err) => console.log(err));
  };

  const deleteProduct = (id) => {
    var docRef = firestore.collection("cart").doc(userId);
    docRef
      .get()
      .then(function (doc) {
        var items = doc.data().items;
        axios
          .get(`${API_SERVICE}/api/v1/main/deleteitemsmaincart/${id}`)
          .then((response) => {
            setloading(true);
            refreshList();
            items = items - 1;
            docRef.set(
              {
                items,
              },
              { merge: true }
            );
          })
          .catch((err) => console.log(err));
      })
      .catch(function (error) {
        console.log("Error getting document:", error);
      });
  };

  const changeQty = (documentId, qty) => {
    axios
      .get(
        `${API_SERVICE}/api/v1/main/cartitemchangequantitycart/${documentId}/${qty}`
      )
      .then((response) => {
        if (response.status === 200) {
          refreshList();
          setloading(true);
        }
      })
      .catch((err) => console.log(err));
  };

  const showProductList = () => {
    var p = 0;
    return products.map((product) => {
      p = Number(product.price) + Number(p);
      return (
        <ProductList
          settotalprice={settotalprice}
          p={p}
          changeQty={changeQty}
          deleteProduct={deleteProduct}
          product={product}
          key={product.Sku}
        />
      );
    });
  };

  return (
    <>
      <HeaderLangingPage />
      <br />

      <div id="tt-pageContent">
        <div className="container-indent">
          <div className="container">
            <h1 className="tt-title-subpages noborder">SHOPPING CART</h1>
            <div className="row">
              <div className="col-sm-12 col-xl-8">
                <div className="tt-shopcart-table">
                  <table>
                    <tbody>{loading ? <></> : <>{showProductList()}</>}</tbody>
                  </table>
                  <div className="tt-shopcart-btn">
                    <div className="col-left">
                      <a className="btn-link" href="/products?p=bags&t=all">
                        <i className="icon-e-19"></i>CONTINUE SHOPPING
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-12 col-xl-4">
                <div className="tt-shopcart-wrapper">
                  <div className="tt-shopcart-box">
                    <h4 className="tt-title">
                      PAYMENT <br /> <br />
                      {paymentstatus}
                    </h4>
                  </div>
                  <hr />
                  <div className="tt-shopcart-box tt-boredr-large">
                    <h4>STATUS</h4>
                    <h5>{orderstatus}</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <FooterLandingPage />
    </>
  );
};

export default Track;
