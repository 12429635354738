import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import Container from "@material-ui/core/Container";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import { mainListItems } from "./listitems";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import queryString from "query-string";
import Pagination from "./Pagination";
import { API_SERVICE } from "../config/URI";
import axios from "axios";
import CircularProgress from "@material-ui/core/CircularProgress";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import Snackbar from "@material-ui/core/Snackbar";
import CloseIcon from "@material-ui/icons/Close";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import Paper from "@material-ui/core/Paper";
import InputBase from "@material-ui/core/InputBase";
import SearchIcon from "@material-ui/icons/Search";
import FilterListIcon from "@material-ui/icons/FilterList";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import LinearProgress from "@material-ui/core/LinearProgress";
import {useHistory} from 'react-router-dom'
const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: "none",
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  fixedHeight: {
    height: 240,
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  inputroot: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    width: 600,
    marginTop: "10px",
  },
  divider: {
    height: 28,
    margin: 4,
  },
  progressroot: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
}));

const addOtherStore = (product, productfor) => {
  var pa = product.ListPrice;
  var perc = (21 / 100) * Number(pa);
  var finalP = (Number(pa) + Number(perc)).toFixed(0);
  var uploadData = {
    title: product.Title,
    price: finalP,
    displayImage: product.Images[0],
    category: product.Category,
    madein: product.MadeIn,
    gender: product.Gender,
    description: product.Description,
    product: product,
    productfor,
  };

  axios
    .post(`${API_SERVICE}/api/v1/main/addproductotherstore`, uploadData)
    .then((response) => {
      if (productfor === "Sale") {
        window.location.href = `/admineditsale?id=${response.data}`;
      } else {
        alert(`Product Added to ${productfor}`);
      }
      // if (response.status === 200) {
      //   console.log(productfor, response);
      // }
    })
    .catch((err) => console.log(err));
};

const addRecommended = (product) => {
  let prod={...product};
  var pa = prod.ListPrice;
  var perc = (21 / 100) * Number(pa);
  var finalP = (Number(pa) + Number(perc)).toFixed(0);
  prod.ListPrice=finalP;

  axios
    .post(
      `${API_SERVICE}/api/v1/main/addrecommendedproductmainstore`,
      {product:prod}
    )
    .then((response) => {
  
         
          alert("Product added to Recommended");
      
    
    })
    .catch((err) => console.log(err));
};
const addFeatured = (product) => {
  var pa = product.ListPrice;
  var perc = (21 / 100) * Number(pa);
  var finalP = (Number(pa) + Number(perc)).toFixed(0);

  var uploadData = {
    sku: product.Sku,
    productId: product._id,
    title: product.Title,
    price: finalP,
    displayImage: product.Images[0],
  };
  axios
    .post(`${API_SERVICE}/api/v1/main/addfeaturedproductmainstore`, uploadData)
    .then((response) => {
      if (response.status === 200) {
        alert("Product Added to Featued Store");
      }
    })
    .catch((err) => console.log(err));
};

const addWeek = (product) => {
  var pa = product.ListPrice;
  var perc = (21 / 100) * Number(pa);
  var finalP = (Number(pa) + Number(perc)).toFixed(0);

  var uploadData = {
    sku: product.Sku,
    productId: product._id,
    title: product.Title,
    price: finalP,
    displayImage: product.Images[0],
  };
  axios
    .post(`${API_SERVICE}/api/v1/main/addweekproductmainstore`, uploadData)
    .then((response) => {
      if (response.status === 200) {
        alert("Product Added to Product of Week");
      }
    })
    .catch((err) => console.log(err));
};

const ProductList = ({
  handleClickOpenDialog2,
  product,
  addProductMainStore,
  history
}) => {
  return (
    <Grid item xs={3}>
      <Card>
        <CardActionArea>
          <CardContent onClick={() => handleClickOpenDialog2(product)}>
            <center>
              <img
                style={{ width: "28vh", height: "34vh" }}
                src={product.Images[0]}
              />
              <h5>{product.Title}</h5>
              <h6>
                {product.Gender} | Price € {product.ListPrice}
              </h6>
              <h4>{product.CreatedDatetimeUtc}</h4>
            </center>
          </CardContent>
        </CardActionArea>
        <div style={{ padding: "10px" }}>
          <Button
            variant="outlined"
            onClick={() => addProductMainStore(product)}
            style={{ color: "#ffffff", backgroundColor: "#000000" }}
            fullWidth
          >
            Add this Item
          </Button>
          <Button
            onClick={() => addFeatured(product)}
            style={{ marginTop: "4px" }}
            variant="outlined"
            fullWidth
          >
            Add to Featured Product
          </Button>
          <Button
            onClick={() => addWeek(product)}
            style={{ marginTop: "4px" }}
            variant="outlined"
            fullWidth
          >
            Add to Product of Week
          </Button>

          {product.recommended ? (
            <Button
              onClick={() => addRecommended(product)}
              style={{ marginTop: "4px" }}
              variant="outlined"
              fullWidth
            >
              Remove from Recommended
            </Button>
          ) : (
            <Button
              onClick={() => addRecommended(product)}
              style={{ marginTop: "4px" }}
              variant="outlined"
              fullWidth
            >
              Add to Recommended
            </Button>
          )}

          <Button
            onClick={() => addOtherStore(product, "New In")}
            style={{ marginTop: "4px" }}
            variant="outlined"
            fullWidth
          >
            Add to New In Product
          </Button>
          <Button
            onClick={() => addOtherStore(product, "Sale")}
            style={{ marginTop: "4px" }}
            variant="outlined"
            fullWidth
          >
            Add to Sale Product
          </Button>
        </div>
      </Card>
    </Grid>
  );
};

export default function HomeProducts({ location }) {
  const classes = useStyles();
  const [search, setsearch] = React.useState("ACCESSORIES");
  const [category, setcategory] = React.useState("ACCESSORIES");
  const [subcategory, setsubcategory] = React.useState("SHOW ALL");
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [quickviewproduct, setquickviewproduct] = React.useState(null);

  const [openDialog2, setOpenDialog2] = React.useState(false);
  const handleClickOpenDialog2 = (product) => {
    setquickviewproduct(product);
    setOpenDialog2(true);
  };
  const handleCloseDialog2 = () => {
    setOpenDialog2(false);
  };

  const handleClickSnackbar = () => {
    setOpenSnackbar(true);
  };
  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  const [openDialog, setOpenDialog] = React.useState(false);

  const handleClickOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const [open, setOpen] = React.useState(true);
  const [products, setproducts] = React.useState([]);
  const [brands, setbrands] = React.useState([]);
  const [loadingbrands, setloadingbrands] = React.useState(true);
  const [gender, setgender] = React.useState("SHOW ALL");
  const [brandname, setbrandname] = React.useState(true);
  const [loading, setloading] = React.useState(true);

  var [limitpage, setlimitpage] = React.useState(0);

  const [currentPage, setCurrentPage] = React.useState(1);
  const [postsPerPage] = React.useState(100);

  // Get current posts
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = products.slice(indexOfFirstPost, indexOfLastPost);
  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  const addProductMainStore = (product) => {
    var pa = product.ListPrice;
    var perc = (21 / 100) * Number(pa);
    var finalP = (Number(pa) + Number(perc)).toFixed(0);

    var uploadData = {
      title: product.Title,
      price: finalP,
      displayImage: product.Images[0],
      category: product.ParentCategory,
      madein: product.MadeIn,
      gender: product.Gender,
      description: product.Description,
      product,
      recommended: false,
    };
    axios
      .post(`${API_SERVICE}/api/v1/main/addproduct`, uploadData)
      .then((response) => {
        if (response.status === 200) {
          handleClickSnackbar();
        }
      })
      .catch((err) => console.log(err));
  };

  const getBrands = (allData) => {
    var t = [];
    allData.map((d) => {
      if (t.indexOf(d.Brand) === -1) {
        t.push(d.Brand);
      }
    });
    return t.sort();
  };

  React.useEffect(() => {
    const { id } = queryString.parse(location.search);
    setcategory(id.toUpperCase());
    var tempArr = [];
    axios
      .get(`${API_SERVICE}/api/v1/main/getproductsmainstoreagain/${id}`)
      .then((response) => {
        setproducts(response.data);
        setquickviewproduct(response.data[0]);
        setloading(false);
        axios
          .get(
            `${API_SERVICE}/api/v1/main/getproductsmainstoreagainbrands/${id}`
          )
          .then((response) => {
            tempArr = getBrands(response.data);
            setbrands(tempArr);
            setloadingbrands(false);
          })
          .catch((err) => console.log(err));
      })
      .catch((err) => console.log(err));
  }, []);

  const nextPage = () => {
    const { id } = queryString.parse(location.search);
    setloading(true);
    var temp = limitpage + 100;
    axios
      .get(
        `${API_SERVICE}/api/v1/main/getproductsmainstoreagainskip/${id}/${temp}`
      )
      .then((response) => {
        setlimitpage(temp);
        setproducts(response.data);
        setquickviewproduct(response.data[0]);
        setloading(false);
      })
      .catch((err) => console.log(err));
  };

  const previousPage = () => {
    if (limitpage >= 100) {
      const { id } = queryString.parse(location.search);
      setloading(true);
      var temp = limitpage - 100;
      axios
        .get(
          `${API_SERVICE}/api/v1/main/getproductsmainstoreagainskip/${id}/${temp}`
        )
        .then((response) => {
          setlimitpage(temp);
          setproducts(response.data);
          setquickviewproduct(response.data[0]);
          setloading(false);
        })
        .catch((err) => console.log(err));
    }
  };
 const history=useHistory
  const showProductList = () => {
    return currentPosts.map((product) => {
      return (
        <ProductList
        history={history}
          handleClickOpenDialog2={handleClickOpenDialog2}
          addProductMainStore={addProductMainStore}
          product={product}
          key={product.Sku}
        />
      );
    });
  };

  const searchMainStore = () => {
    const { id } = queryString.parse(location.search);
    setproducts([]);
    setloading(true);
    axios
      .get(`${API_SERVICE}/api/v1/main/findproducts/${search}/${id}`)
      .then((response) => {
        setproducts(response.data);
        setquickviewproduct(response.data[0]);
        setloading(false);
        setTimeout(function () {
          if (products.length == 0) {
            setloading(false);
            setproducts([]);
          }
        }, 6000);
      })
      .catch((err) => console.log(err));
  };

  const applyFilters = () => {
    setloading(true);
    handleCloseDialog();
    const { id } = queryString.parse(location.search);
    axios
      .get(
        `${API_SERVICE}/api/v1/main/getproductsmainstoreagainapplyfilters/${id}/${brandname}/${subcategory}/${gender}`
      )
      .then((response) => {
        setproducts(response.data);
        setloading(false);
      })
      .catch((err) => console.log(err));
  };

  const priceSorting = (sort) => {
    setloading(true);
    axios
      .get(
        `${API_SERVICE}/api/v1/main/pricefilteradmindashboard/${category}/${sort}`
      )
      .then((response) => {
        setproducts(response.data);
        setquickviewproduct(response.data[0]);
        setloading(false);
      })
      .catch((err) => console.log(err));
  };

  return (
    <div className={classes.root}>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        open={openSnackbar}
        autoHideDuration={4000}
        onClose={handleCloseSnackbar}
        message="Item Successfully Added to Main Store"
        action={
          <React.Fragment>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={handleCloseSnackbar}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />
      {loading === false ? (
        products && products.length ? (
          <Dialog
            open={openDialog2}
            onClose={handleCloseDialog2}
            fullWidth={true}
            maxWidth="md"
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title"></DialogTitle>

            <DialogContent>
              <div class="modal-body">
                <div class="tt-modal-quickview desctope">
                  <div class="row">
                    <div className="col-12 col-md-5 col-lg-6">
                      <div className="tt-mobile-product-slider arrow-location-center slick-initialized slick-slider slick-dotted">
                        <img
                          src={quickviewproduct.Images[0]}
                          alt=""
                          class="loading"
                          data-was-processed="true"
                        />
                      </div>
                    </div>
                    <div class="col-12 col-md-7 col-lg-6">
                      <div class="tt-product-single-info">
                        <div class="tt-add-info">
                          <ul>
                            <li>
                              <span>SKU:</span> {quickviewproduct.Sku}
                            </li>
                            <li>
                              <span>Made In:</span> {quickviewproduct.MadeIn}
                            </li>
                            <li>
                              <span>Size:</span>{" "}
                              {quickviewproduct.Variants[0].Size}
                            </li>
                            <li>
                              <span>Gender:</span> {quickviewproduct.Gender}
                            </li>
                            <li>
                              <span>Color:</span> {quickviewproduct.Color}
                            </li>
                          </ul>
                        </div>
                        <h2 class="tt-title">{quickviewproduct.Title}</h2>
                        <div class="tt-price">
                          <span class="new-price">
                            € {quickviewproduct.ListPrice}
                          </span>
                          <span class="old-price"></span>
                        </div>
                        <div class="tt-wrapper">
                          {quickviewproduct.Description}
                        </div>
                        <div class="tt-swatches-container">
                          {/* <div class="tt-wrapper">
                                                        <div class="tt-title-options">COLOR</div>
                                                        <form class="form-default">
                                                            <div class="form-group">
                                                                <select class="form-control">
                                                                    <option>Red</option>
                                                                    <option>Green</option>
                                                                    <option>Brown</option>
                                                                </select>
                                                            </div>
                                                        </form>
                                                    </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseDialog2} color="primary">
                Close
              </Button>
            </DialogActions>
          </Dialog>
        ) : null
      ) : null}
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        fullWidth={true}
        maxWidth="md"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Filter</DialogTitle>
        <DialogContent>
          {loadingbrands ? (
            <div className={classes.progressroot}>
              <center>
                <h4>Fetching Filters...</h4>
              </center>
              <LinearProgress />
            </div>
          ) : (
            <>
              <select
                onChange={(e) => setbrandname(e.target.value)}
                className="myselect"
              >
                <option selected>Select Your Brand</option>
                {brands.map((b) => (
                  <option value={b}>{b}</option>
                ))}
              </select>
              <hr />
              {category === "ACCESSORIES" ? (
                <select
                  onChange={(e) => setsubcategory(e.target.value)}
                  className="myselect"
                >
                  <option selected disabled>
                    -- ACCESSORIES SUB CATEGORY --
                  </option>
                  <option value="SHOW ALL">SHOW ALL</option>
                  <option value="SUNGLASSES">SUNGLASSES</option>
                  <option value="SCARF">SCARF</option>
                  <option value="WALLET">WALLET</option>
                  <option value="BELT">BELT</option>
                  <option value="HAT">HAT</option>
                  <option value="CARD HOLDER">CARD HOLDER</option>
                  <option value="BRACELET">BRACELET</option>
                  <option value="COVER">COVER</option>
                  <option value="FOULARD">FOULARD</option>
                  <option value="NECKLACE">NECKLACE</option>
                  <option value="SHOULDER STRAP">SHOULDER STRAP</option>
                  <option value="GLASSES">GLASSES</option>
                  <option value="FRAGRANCE">FRAGRANCE</option>
                  <option value="SOCKS">SOCKS</option>
                  <option value="EARRINGS">EARRINGS</option>
                  <option value="GLOVES">GLOVES</option>
                  <option value="RING">RING</option>
                  <option value="KEY CHAIN">KEY CHAIN</option>
                  <option value="HEADBAND">HEADBAND</option>
                  <option value="HAIR CLIP">HAIR CLIP</option>
                  <option value="CASE">CASE</option>
                  <option value="SHOE LACE">SHOE LACE</option>
                  <option value="SHAWL">SHAWL</option>
                  <option value="MASK">MASK</option>
                  <option value="BRACES">BRACES</option>
                </select>
              ) : category === "BAGS" ? (
                <select
                  onChange={(e) => setsubcategory(e.target.value)}
                  className="myselect"
                >
                  <option selected disabled>
                    -- BAGS SUB CATEGORY --
                  </option>
                  <option value="SHOW ALL">SHOW ALL</option>
                  <option value="TOTE">TOTE</option>
                  <option value="HANDBAG">HANDBAG</option>
                  <option value="SHOULDER BAG">SHOULDER BAG</option>
                  <option value="POUCH">POUCH</option>
                  <option value="BACKPACK">BACKPACK</option>
                  <option value="TRAVEL BAG">TRAVEL BAG</option>
                  <option value="BELT BAG">BELT BAG</option>
                  <option value="BEAUTY CASE">BEAUTY CASE</option>
                  <option value="CLUTCH">CLUTCH</option>
                  <option value="TROLLEY">TROLLEY</option>
                  <option value="BRIEFCASE">BRIEFCASE</option>
                </select>
              ) : category === "CLOTHING" ? (
                <select
                  onChange={(e) => setsubcategory(e.target.value)}
                  className="myselect"
                >
                  <option selected disabled>
                    -- CLOTHING SUB CATEGORY --
                  </option>
                  <option value="SHOW ALL">SHOW ALL</option>
                  <option value="JEANS">JEANS</option>
                  <option value="DRESS">DRESS</option>
                  <option value="BLOUSE">BLOUSE</option>
                  <option value="PANTS">PANTS</option>
                  <option value="SWEATER">SWEATER</option>
                  <option value="CARDIGAN">CARDIGAN</option>
                  <option value="JUMPSUIT">JUMPSUIT</option>
                  <option value="JACKET">JACKET</option>
                  <option value="SHORTS">SHORTS</option>
                  <option value="SKIRT">SKIRT</option>
                  <option value="COAT">COAT</option>
                  <option value="T-SHIRT">T-SHIRT</option>
                  <option value="DOWN JACKET">DOWN JACKET</option>
                  <option value="SHIRT">SHIRT</option>
                  <option value="POLO SHIRT">POLO SHIRT</option>
                  <option value="OUTERWEAR JACKET">OUTERWEAR JACKET</option>
                  <option value="BLAZER">BLAZER</option>
                  <option value="BIKINI">BIKINI</option>
                  <option value="SWEATSHIRT">SWEATSHIRT</option>
                  <option value="TANK TOP">TANK TOP</option>
                  <option value="JUMPER">JUMPER</option>
                  <option value="BODYSUIT">BODYSUIT</option>
                  <option value="VEST">VEST</option>
                  <option value="TOP">TOP</option>
                  <option value="BRIEF">BRIEF</option>
                  <option value="ONE-PIECE SUIT">ONE-PIECE SUIT</option>
                  <option value="TRENCH COAT">TRENCH COAT</option>
                  <option value="Lingerie & Swimwear">
                    Lingerie & Swimwear
                  </option>
                  <option value="JOGGERS">JOGGERS</option>
                  <option value="PONCHO">PONCHO</option>
                  <option value="LEGGINGS">LEGGINGS</option>
                  <option value="LINGERIE & SWIMWEAR">
                    LINGERIE & SWIMWEAR
                  </option>
                  <option value="BRA">BRA</option>
                  <option value="SUIT">SUIT</option>
                </select>
              ) : category === "SHOES" ? (
                <select
                  onChange={(e) => setsubcategory(e.target.value)}
                  className="myselect"
                >
                  <option selected disabled>
                    -- SHOES SUB CATEGORY --
                  </option>
                  <option value="SHOW ALL">SHOW ALL</option>
                  <option value="SNEAKERS">SNEAKERS</option>
                  <option value="ANKLE BOOTS">ANKLE BOOTS</option>
                  <option value="SLIP ON SNEAKERS">SLIP ON SNEAKERS</option>
                  <option value="PUMPS">PUMPS</option>
                  <option value="HI TOP SNEAKERS">HI TOP SNEAKERS</option>
                  <option value="SANDALS">SANDALS</option>
                  <option value="BOOTS">BOOTS</option>
                  <option value="MONK STRAP SHOES">MONK STRAP SHOES</option>
                  <option value="HEELS">HEELS</option>
                  <option value="LOAFERS">LOAFERS</option>
                  <option value="FLATS">FLATS</option>
                  <option value="LACE-UP SHOES">LACE-UP SHOES</option>
                  <option value="WEDGES">WEDGES</option>
                  <option value="ESPADRILLES">ESPADRILLES</option>
                </select>
              ) : null}
              <hr />
              <select
                onChange={(e) => setgender(e.target.value)}
                className="myselect"
              >
                <option selected disabled>
                  -- GENDER --
                </option>
                <option value="SHOW ALL">SHOW ALL</option>
                <option value="M">Male</option>
                <option value="F">Female</option>
              </select>

              <br />
              <br />
              <br />
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Close
          </Button>
          <Button onClick={applyFilters} color="primary" autoFocus>
            Apply Filter
          </Button>
        </DialogActions>
      </Dialog>

      <CssBaseline />
      <AppBar
        position="absolute"
        className={clsx(classes.appBar, open && classes.appBarShift)}
      >
        <Toolbar className={classes.toolbar}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            className={clsx(
              classes.menuButton,
              open && classes.menuButtonHidden
            )}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            component="h1"
            variant="h6"
            color="inherit"
            noWrap
            className={classes.title}
          >
            Dashboard
          </Typography>
          <IconButton color="inherit"></IconButton>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        classes={{
          paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
        }}
        open={open}
      >
        <div className={classes.toolbarIcon}>
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <Divider />
        <List>{mainListItems}</List>
      </Drawer>
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          <a
            href="/adminallproducts?id=bags"
            style={{ marginRight: "10px", color: "blue" }}
          >
            BAGS
          </a>
          <a
            href="/adminallproducts?id=accessories"
            style={{ marginRight: "10px", color: "blue" }}
          >
            ACCESSORIES
          </a>
          <a
            href="/adminallproducts?id=clothing"
            style={{ marginRight: "10px", color: "blue" }}
          >
            CLOTHING
          </a>
          <a
            href="/adminallproducts?id=shoes"
            style={{ marginRight: "10px", color: "blue" }}
          >
            SHOES
          </a>
          {loading === false ? (
            <center>
              <Paper component="form" className={classes.inputroot}>
                <InputBase
                  className={classes.input}
                  onChange={(e) => setsearch(e.target.value)}
                  placeholder="Search by Brands, Category, Price, Title, SKU"
                  inputProps={{ "aria-label": "search products" }}
                />
                <IconButton
                  onClick={searchMainStore}
                  type="button"
                  className={classes.iconButton}
                  aria-label="search"
                >
                  <SearchIcon />
                </IconButton>
                <Divider className={classes.divider} orientation="vertical" />
                <IconButton
                  onClick={handleClickOpenDialog}
                  color="primary"
                  className={classes.iconButton}
                  aria-label="directions"
                >
                  <FilterListIcon />
                </IconButton>
              </Paper>
              <br />
              <br />
              <select onChange={(e) => priceSorting(e.target.value)}>
                <option selected disabled>
                  Show All
                </option>
                <option value="-1">Price High to Low</option>
                <option value="1">Price Low to High</option>
              </select>
              <br />
              <br />
              <a onClick={() => window.location.reload()} href="#!">
                Show All
              </a>
              <br />
              <ButtonGroup
                color="primary"
                aria-label="outlined primary button group"
              >
                <IconButton
                  onClick={previousPage}
                  color="primary"
                  component="span"
                >
                  <NavigateBeforeIcon />
                </IconButton>
                <IconButton onClick={nextPage} color="primary" component="span">
                  <NavigateNextIcon />
                </IconButton>
              </ButtonGroup>
            </center>
          ) : null}
          <Grid container spacing={3}>
            {loading === true ? (
              <center style={{ marginTop: "10%", marginLeft: "50%" }}>
                <CircularProgress />
              </center>
            ) : (
              <>{showProductList()}</>
            )}
          </Grid>
          {loading === false ? (
            <center>
              <br />
              <ButtonGroup
                color="primary"
                aria-label="outlined primary button group"
              >
                <IconButton
                  onClick={previousPage}
                  color="primary"
                  component="span"
                >
                  <NavigateBeforeIcon />
                </IconButton>
                <IconButton onClick={nextPage} color="primary" component="span">
                  <NavigateNextIcon />
                </IconButton>
              </ButtonGroup>
            </center>
          ) : null}

          {loading === false ? (
            <center>
              <Pagination
                postsPerPage={postsPerPage}
                totalPosts={products.length}
                paginate={paginate}
              />
            </center>
          ) : null}
        </Container>
      </main>
    </div>
  );
}
