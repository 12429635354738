import React from 'react'

const ComingSoon = () => {
    return (
        <>
            <center className="mt-5">
                <img style={{ width: '50%' }} src="https://res.cloudinary.com/dx9dnqzaj/image/upload/v1618398256/ecommerse2/House_of_plug_titel_logo_-_png_format_with_transparent_backround_opz2xh.png" />
            </center>
            <div style={{ marginTop: '4vh' }} id="tt-pageContent">
                <div className="container-indent">
                    <div className="container">
                        <h1 className="tt-title-subpages noborder">
                            Will be opening soon! <br /> Thank you for your patience!
                        </h1>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ComingSoon
