import React from "react";

import HeaderLangingPage from "../components/HeaderLangingPage";
import FooterLandingPage from "../components/FooterLandingPage";

import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Container } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: "bold",
  },
}));

const FAQ = () => {
  const classes = useStyles();

  return (
    <>
      <HeaderLangingPage />
      <br />
      <div style={{ textAlign: "center" }}>
        <h3>FAQs</h3>
      </div>
      <Container>
        <div className={classes.root}>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={classes.heading}>
                Is it safe to shop from your site due to COVID-19? (coronavirus)
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <p>
                  The health and safety of colleagues and the wider community
                  has always been our first priority at House of plug. We have
                  enhanced safety measures in place to safeguard teams, going
                  above and beyond government guidelines to maximize protection.
                </p>
                <div style={{ textAlign: "center", fontWeight: "bold" }}>
                  <p>What are you doing to ensure colleagues' safety?</p>
                </div>
                <div>
                  <p>
                    We have introduced a number of additional health-and-safety
                    measures that go above and beyond the government guidelines.
                    These include:
                  </p>
                  <ul>
                    <li>
                      <p>
                        6ft or above social distancing respected throughout site
                      </p>
                    </li>
                    <li>
                      <p>
                        Temperature-control checks on entry, using advanced
                        thermal screening technology
                      </p>
                    </li>
                    <li>
                      <p>
                        Staggered breaks, staggered entry and exit to site and a
                        one-way system implemented to site
                      </p>
                    </li>
                    <li>
                      <p>
                        Increased cleaning and disinfecting of equipment PPE,
                        including masks, gloves and sanitizer
                      </p>
                    </li>
                  </ul>
                </div>
                <div style={{ textAlign: "center", fontWeight: "bold" }}>
                  <p>What about the delivery of my purchase?</p>
                </div>
                <p>
                  For your safety, we’re providing contactless delivery during
                  this time. Our driver will ring or knock on your door, place
                  your order on the ground and step back 6ft.Instead of asking
                  for a signature, we’ll make a note of your full name to ensure
                  your order has been left in safe hands. Please note that
                  NET-A-PORTER will not be held responsible for any loss or
                  damage that may occur once your order has been delivered in
                  this way.
                </p>
                <p>
                  In line with government guidelines, all our drivers will be
                  wearing face masks andgloves and are regularly washing their
                  hands and carrying hand sanitizer. We’re also continuing to
                  monitor their health and well-being so they can continue
                  completing deliveries to the highest possible standard. Our
                  fleet of vans are disinfected daily and deep cleaned once a
                  week to maintain utmost cleanliness.
                </p>
                <div style={{ textAlign: "center", fontWeight: "bold" }}>
                  <p>Our shipping partners:</p>
                </div>
                <p>
                  We are working with our shipping partners, DHL and UPS, to
                  ensure all appropriate health and hygiene standards are
                  adhered to. They are both currently operating a contactless
                  delivery system. Where a signature would usually be required
                  or this has been requested by you, they will endeavour to take
                  the recipient’s name as proof of delivery and follow socially
                  distanced protocol. When a signature is waived by you or not
                  possible, they will leave your parcel where you specified.{" "}
                </p>
                <p>
                  Please note that House of plug or our shipping partners will
                  not be held responsible for any loss or damage that may occur
                  once your order has been delivered as set out above.
                </p>
                <div style={{ textAlign: "center", fontWeight: "bold" }}>
                  <p>Why is my return taking longer to process?</p>
                </div>
                <p>
                  Your return may take slightly longer to process as we have
                  enhanced health and safety measures in place to protect our
                  teams and customers.
                </p>
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel3a-content"
              id="panel3a-header"
            >
              <Typography className={classes.heading}>
                Do I need an account to place an order?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                You can shop without creating an account. We will register you
                as a guest and only contact you through email –
                info@houseofplug.com.
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel4a-content"
              id="panel4a-header"
            >
              <Typography className={classes.heading}>
                Do you have gift cards?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                We do not have gift cards currently setup, our goal is to add
                this option in the near future.
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel5a-content"
              id="panel5a-header"
            >
              <Typography className={classes.heading}>
                Can I amend or cancel an item from my order?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                On the status of your order, it may be possible to cancel an
                item or change it to adifferent size. Please note, we’re unable
                to combine or add an item to an existingorder.
                <br />
                <br />
                If you need to make any amendments to your purchase, please
                start a livechat or email us at info@houseofplug.com as soon as
                possible and we’ll see what we can do.
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel6a-content"
              id="panel6a-header"
            >
              <Typography className={classes.heading}>
                What do I do if I think an item that I purchased from your site
                is defect or damaged?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Simply email info@houseofplug.com and we’ll look into this for
                you. When you get in touch with us, please provide your order
                number and photographs of your item. Please be aware that items
                which have been damaged as a result of normal wear and tear, by
                accident, or your own misuse, will not be considered faulty.
                This does not affect your statutory rights.
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel6a-content"
              id="panel6a-header"
            >
              <Typography className={classes.heading}>
                How do I get in touch with House of plug?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                If you need help placing an order, tracking your delivery or
                arranging an exchange, we’re here to help.
                <br />
                Email us at info@houseofplug.com and one of our sales agents
                will contact you as soon as possible.
                <br />
                You can also contact us via LifeChat. Find us in the bottom
                right-hand corner of the screen when we're available to chat.
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel6a-content"
              id="panel6a-header"
            >
              <Typography className={classes.heading}>
                Can I get in touch with a Fashion Consultant?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Yes, it is possible to get in touch with one of our Fashion
                Consultant. Please fill in the form through our contact page by
                clicking here that for a request.
                <br />
                <strong>Sizes</strong>
                <br />
                To help you find the right size, take a look at our size chart
                on the product description page. It provides information on
                various sizes and their conversions.
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel6a-content"
              id="panel6a-header"
            >
              <Typography className={classes.heading}>
                Duties & Taxes - Customs duties and taxes for non-EU countries
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                In case of shipments that are made to non-European countries, no
                VAT is charged. As soon as the articles arrive at the
                destination, sales tax on imports aswell as customs duties and
                handling costs will be determined by the respective customs
                office. The recipient will then be invoiced by our logistics
                partner DHL. By submitting your order and paying and/or
                delivering the required information, you concede to the import
                regulations of the respective articles. Please be aware that
                import regulations vary from country to country.
                <br />
                Please be aware that sales taxes, customs duties, and additional
                DHL handling costs will be charged by DHL on an individual basis
                directly upon shipment as faras non-European Union (EU)
                countries are concerned. Sales taxes, customs duties and
                handling costs will not be refunded by House of plug if the
                items are returned.
              </Typography>
            </AccordionDetails>
          </Accordion>
        </div>
      </Container>
      <FooterLandingPage />
    </>
  );
};

export default FAQ;
